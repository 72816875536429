import React, { useState, useEffect, useRef } from 'react';
import PageWrap from '../../components/layout/PageWrap';
import { PDFViewer } from "@react-pdf/renderer";
import PdfDocument from './pdf-document/PdfDocument';
import { useParams } from 'react-router-dom';
import { getSavedScenario } from '../../client/AppClient';
import { useDispatch } from 'react-redux';
import displayError from '../../utils/handling-errors/displayError';
import LoadingSpinner from '../../components/specific/LoadingSpinner';
import Chart from '../scenario-page/components/Chart';
import html2canvas from "html2canvas";
import PdfDownload from './components/PdfDownload';
import { isMobile as detectedMobile } from "react-device-detect";

const mobileBreakpoint = 768;
function ScenarioPdf() {
    const { scenarioId } = useParams();
    const [scenarioData, setScenarioData] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [chartBase, setChartBase] = useState<any>();
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [isMobile, setIsMobile] = useState(
      window.innerWidth <= mobileBreakpoint
    );

    const chartRef = useRef<any>()
    const dispatch = useDispatch();

    const fetchScenarioData = async () => {
      if (!scenarioId) return;
      const response = await getSavedScenario(scenarioId);
      if (response.status !== 200) return [setLoading(false), displayError(dispatch, response)];
      setScenarioData(response.data);
      setLoading(false);
    };

    const getChartImage = async () => {
      const chart = document.getElementById("chart");
      if (chart) {
        // const response = await domtoimage.toPng(chart, { quality: 1 });
        // setChartBase(response);
        html2canvas(chart).then(canvas => {
        const imageUrl = canvas.toDataURL();
        setChartBase(imageUrl);
        });
      }
    };

    const handleWindowSizeChange = () => {
      if (window.innerWidth <= mobileBreakpoint) {
        setIsMobile(true);
      } else if (window.innerWidth > mobileBreakpoint) {
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener("resize", handleWindowSizeChange);
      fetchScenarioData();

      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!loading && chartRef.current) {
          setTimeout(() => {
            getChartImage();
          }, 2000)
      }
    }, [loading, chartRef])

    if (loading) return <LoadingSpinner />;

    return (
      <>
        {!pdfLoaded && <LoadingSpinner />}
        <PageWrap>
          {chartBase && (
            <>
              {!isMobile && !detectedMobile ? (
                <PDFViewer height={800} className="z-50">
                  <PdfDocument
                    data={scenarioData}
                    chartBase={chartBase}
                    setPdfLoaded={setPdfLoaded}
                  />
                </PDFViewer>
              ) : (
                <div
                  className="w-full flex justify-center pt-32"
                  style={!pdfLoaded ? { opacity: 0 } : { opacity: 100 }}
                >
                  <PdfDownload
                    data={scenarioData}
                    chartBase={chartBase}
                    setPdfLoaded={setPdfLoaded}
                  />
                </div>
              )}
            </>
          )}

          <div
            className="absolute opacity-0 overflow-hidden pointer-events-none"
            ref={chartRef}
          >
            <Chart data={scenarioData.divisionOfAssets} pdf={true} />
          </div>
        </PageWrap>
      </>
    );
}

export default ScenarioPdf;