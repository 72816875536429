import React from 'react';

import {ReactComponent as InfoIcon} from "../../../assets/icons/Info.svg";
import { Link } from 'react-router-dom';

function ClientsTable({ data }: any) {
    return (
      <div className='mb-10'>
        <table className='table-auto border-collapse w-full'>
          <tbody>
            {data.map((client: any, i: number) => {
              return (
                <tr key={i} className="border-b border-lightBlue ">
                  <td className="flex flex-col md:table-cell p-4 md:pl-8 text-darkBlue">
                    <Link to={`/person/${client.id}`}>{client.fullName}</Link>
                  </td>
                  <td className="flex flex-col md:table-cell p-4 text-darkGrey">
                    {" "}
                    <div className="flex flex-row items-center gap-3">
                      {" "}
                      <InfoIcon width={20} /> {client.additionalData}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

export default ClientsTable;