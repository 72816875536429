import React, { useState } from 'react';
import PageWrap from '../../components/layout/PageWrap';
import ResetForm from './components/ResetForm';
import ResetMessage from './components/ResetMessage';

function PassForget() {
    const [email, setEmail] = useState("");
    const [isSent, setIsSent] = useState(false);

    return (
      <PageWrap>
        <div className="w-full max-w-sm px-4 mx-auto pt-20 text-center">
          <h1 className="text-3xl text-darkBlue font-medium">Zresetuj hasło</h1>
          {isSent === false && (
            <ResetForm email={email} setEmail={setEmail} setIsSent={setIsSent}/>
          )}
          {isSent === true && <ResetMessage email={email}/>}
        </div>
      </PageWrap>
    );
}

export default PassForget;