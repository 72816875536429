import React from "react";
import { Link } from "react-router-dom";
import PageWrap from "../../components/layout/PageWrap";
import { ReactComponent as NotFoundSvg } from "../../assets/images/404.svg";
import Button from "../../components/common/button/Button";



function PageNotFound() {

  return (
    <PageWrap>
      <div className="w-full max-w-7xl mx-auto flex flex-col-reverse md:flex-row justify-around items-center md:gap-10 md:pt-40">
        <div className="md:w-1/3 text-center md:text-left">
          <h5 className="text-sherpaBlue">błąd 404</h5>
          <h1 className="text-darkBlue text-4xl font-semibold">
            Ta strona nie istnieje...
          </h1>
          <p className="text-darkGrey my-6">
            Niestety strona o podanym adresie nie istnieje.
            <br />
            Zalecamy powrót do strony głównej
          </p>
          <div className="flex flex-col gap-5 max-w-xs mx-auto md:mx-0">
            <Link to="/home">
              <Button size="full">Strona główna</Button>
            </Link>
          </div>
        </div>
        <NotFoundSvg className="md:w-1/2 max-w-md h-auto" />
      </div>
    </PageWrap>
  );
}

export default PageNotFound;
