import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addClient } from "../../client/AppClient";
import Form from "../common/forms/Form";
import InputBordered from "../common/forms/InputBordered";
import TextAreaBordered from "../common/forms/TextAreaBordered";
import displayError from "../../utils/handling-errors/displayError";
import { inputOnlyLetters } from "../../validations/inputValidations";

function AddClientForm({ handleSuccessSubmit, submitBtn }: any) {
  const [fullname, setFullname] = useState("");
  const [additionalData, setAdditionalData] = useState("");
  const [note, setNote] = useState("");
  const [errors, setErrors] = useState<any>(null);

  const dispatch = useDispatch();

   const clearForm = () => {
     setFullname("");
     setAdditionalData("");
     setNote("");
   };

  const handleSubmit = async () => {
    setErrors(null);
    const description = note === "" ? null : note;
    const data = {
      fullName: fullname,
      additionalData: additionalData,
      description: description,
    };
    const response: any = await addClient(data);
    if (response.status !== 200) {
      const errorResponse = displayError(dispatch, response);
      if (errorResponse !== undefined) setErrors(errorResponse);
      return;
    }

    handleSuccessSubmit(response.data);
    clearForm();
  };

  return (
    <div>
      <Form
        id="newClient"
        submitBtn={submitBtn}
        onSubmit={handleSubmit}
        buttonSize="md"
      >
        <InputBordered
          label="Nazwa"
          name="name"
          type="text"
          placeholder="Oznaczenie członka rodziny lub dane Klienta"
          divClassName="my-4"
          value={fullname}
          onChange={(e: any) => setFullname(e.target.value)}
          onKeyPress={inputOnlyLetters}
          error={errors?.fullName}
          required
        />
        <InputBordered
          label="Dane dodatkowe"
          name="additionalInfo"
          type="text"
          placeholder="Np. rozwinięcie nazwy scenariusza lub datę utworzenia"
          divClassName="my-4"
          value={additionalData}
          onChange={(e: any) => setAdditionalData(e.target.value)}
          error={errors?.additionalData}
        />
        <TextAreaBordered
          label="Notatka"
          name="pesel"
          placeholder="Wpisz notatkę..."
          divClassName="my-4"
          value={note}
          onChange={(e: any) => setNote(e.target.value)}
        />
      </Form>
    </div>
  );
}

export default AddClientForm;
