import getRequest from "../../requests/getRequest";

export const agoraRefreshTokenService = async (channelId: string) => {
  const result = await getRequest({
    url: "/videoCall/refreshToken",
    params: {
      channelId: channelId,
    },
  });
  return result;
};
