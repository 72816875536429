import React from "react";
import { ReactComponent as FailureImg } from "../../../../../assets/images/failure.svg";

import Header from "../../../../../components/common/text/Header";
import Button from "../../../../../components/common/button/Button";

function ResultFailure({ handleNavigate }: any) {
  return (
    <div className="w-full my-16">
      <div className="flex flex-col text-center items-center">
        <FailureImg className="w-full h-auto max-w-lg" />
        <div className="mt-14">
          <Header>Nie udało się</Header>
          <p className="text-darkGrey text-lg my-5">
            <span>
              Analiza linii sukcesji nie powiodła się. <br />
              Prawdopodobnie wprowadzono wykluczające się informacje. <br />
              Sprawdź proszę i spróbuj jeszcze raz.
            </span>
          </p>
        </div>
        <Button onClick={handleNavigate}>Spróbuj ponownie</Button>
      </div>
    </div>
  );
}

export default ResultFailure;
