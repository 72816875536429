import React, { useState, useEffect, useMemo } from "react";
import { ReactComponent as Arrow } from "../../assets/icons/Arrow.svg";

interface PaginationInterface {
  total: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: Function;
}

interface AnchorBoxInterface {
  direction: "left" | "right";
  onClick: any;
}

function Pagination({
  total,
  itemsPerPage,
  currentPage,
  onPageChange,
}: PaginationInterface) {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (total > 0 && itemsPerPage > 0)
      setTotalPages(Math.ceil(total / itemsPerPage));
  }, [total, itemsPerPage]);

  const AnchorBox = ({ direction, onClick }: AnchorBoxInterface) => (
    <div
      className={`w-8 sm:w-12 h-8 sm:h-12 rounded-lg bg-darkBlue flex items-center justify-center cursor-pointer ${
        direction === "left" ? "rotate-180" : "rotate-0"
      }`}
      onClick={onClick}
    >
      <Arrow />
    </div>
  );

  const Dots = () => {
    return (
      <li className="w-8 sm:w-12 h-8 sm:h-12 flex items-center justify-center text-sm">
        ...
      </li>
    );
  };

  const FirstPage = ({pages} : any) => {
    let lastPage = pages.slice(-1);
    let firstPages = pages.slice(0, 3);
    return (
      <>
        {firstPages}
        <Dots />
        {lastPage}
      </>
    );
  }

  const PageInMiddle = ({pages}: any) => {
    let firstPage = pages[0];
        let lastPage = pages.slice(-1);
        let firstPages = [
          pages[currentPage - 2],
          pages[currentPage - 1],
          pages[currentPage],
        ];
        return (
          <>
            {currentPage !== 2 && firstPage}
            {firstPages}
            {currentPage !== totalPages - 1 && lastPage}
          </>
        )
  }

  const LastPage = ({pages}: any) => {
    let firstPage = pages[0];
    let firstPages = pages.slice(-3);
    return (
      <>
        {firstPage}
        <Dots />
        {firstPages}
      </>
    );
  }

  const paginationItems = useMemo(() => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li
          className={`w-8 sm:w-12 h-8 sm:h-12 rounded-lg flex items-center justify-center text-sm active-data cursor-pointer
          ${i === currentPage ? "text-darkBlue" : "text-darkGrey"}
          ${i === currentPage ? "bg-[#e8eef1]" : ""}`}
          key={i}
          onClick={() => onPageChange(i)}
        >
          {i}
        </li>
      );
    }
    if (pages.length === 1) return null
    if (pages.length < 5) return pages; 
    else {
      if (currentPage === 1) return <FirstPage pages={pages}/>; 
      else if (currentPage === pages.length) return <LastPage pages={pages} />; 
      else return <PageInMiddle pages={pages} />;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, currentPage]);

  if (total === 0) return (
    <span className="text-lg text-darkBlue">
      Nie znaleziono żadnych wyników.
    </span>
  );

  return (
    <div className="flex flex-row gap-2 justify-center">
      {currentPage !== 1 && (
        <AnchorBox
          direction="left"
          onClick={() => onPageChange(currentPage - 1)}
        />
      )}
      <ul className="flex flex-row gap-2">{paginationItems}</ul>
      {currentPage !== totalPages && (
        <AnchorBox
          direction="right"
          onClick={() => onPageChange(currentPage + 1)}
        />
      )}
    </div>
  );
}

export default Pagination;
