import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/reducers/userSlice";
import { getSubscriptionDetails, getUserProfile, renewSubscription } from "../../client/AppClient";

import displayError from "../../utils/handling-errors/displayError";
import LoadingSpinner from "../../components/specific/LoadingSpinner";

import PlanNormal from "./components/plan-types/PlanNormal";
import PlanPremium from "./components/plan-types/PlanPremium";
import PlanEnterprise from "./components/plan-types/PlanEnterprise";
import displaySuccess from "../../utils/handling-success/displaySuccess";

function MyPlan() {
  const [planDetails, setPlanDetails] = useState<any>({});
  const dispatch = useDispatch();
  const effectCalled = useRef<boolean>(false);
  const [loading, setLoading] = useState(true);

  const fetchPlanDetails = async () => {
    const response: any = await getSubscriptionDetails();
    if(response.status !== 200) return displayError(dispatch, response); 
    setPlanDetails(response.data);
  }

  const updateUser = async () => {
    const userDetails = await getUserProfile();
    if (userDetails.status !== 200) return;
    dispatch(setUser(userDetails.data));
  };

  useEffect(() => {
    if (effectCalled.current) return;
    fetchPlanDetails();
    setLoading(false);
    effectCalled.current = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancelSubscription = async () => {
    const response: any = await renewSubscription("NORMAL");
    if (response.status !== 200) return displayError(dispatch, response);
    displaySuccess(dispatch, "Sukces", "Zmieniono subskrypcje na podstawową");
    updateUser();
  }

  if (loading) return <LoadingSpinner />

  if (!loading && planDetails.subscriptionType === "NORMAL")
    return <PlanNormal />;
  if (!loading && planDetails.subscriptionType === "PREMIUM")
    return (
      <PlanPremium
        planDetails={planDetails}
        handleCancelSubscription={handleCancelSubscription}
      />
    );
  if (!loading && planDetails.subscriptionType === "ENTERPRISE")
    return <PlanEnterprise planDetails={planDetails} />;
  return <></>;
}

export default MyPlan;
