import React from "react";
import Popup from "../../../components/specific/Popup";

interface PopupWealth1Interface {
  popupOpened: boolean;
  setPopupOpened: Function;
}

function PopupWealth({ popupOpened, setPopupOpened }: PopupWealth1Interface) {
  return (
    <Popup
      title={"PAMIĘTAJ!"}
      trigger={popupOpened}
      setTrigger={setPopupOpened}
      size="max-w-5xl"
    >
      <div className="flex flex-col max-h-96 overflow-auto">
        <p className="text-darkGrey text-left">
          <ul className="list-inside list-disc">
            <li>
              <strong>Wspólność małżeńska majątkowa</strong> - jeżeli Osoba, po
              której dziedziczenie sprawdzasz jest w związku małżeńskim, w
              którym obowiązuje ustrój wspólności małżeńskiej majątkowej (np. w
              sytuacji, gdy wraz z małżonkiem nie zawierali umów małżeńskich
              majątkowych tzw. intercyza), to z momentem śmierci tej osoby
              wspólność ustanie z mocy samego prawa. Powstanie wspólność
              udziałowa. Co do zasady majątek wspólny zostanie podzielony na
              dwie równe części - po 1/2 na rzecz każdego małżonka. Jeżeli
              małżonkowie nabyli jakiekolwiek składniki do majątku wspólnego, to
              należy przyjąć założenie, że w skład majątku osoby, po której
              dziedziczenie sprawdzasz wchodzi połowa tego składnika.
              <br />
              <i>
                <strong>Przykład:</strong> małżonkowie, pozostający w ustroju
                wspólności małżeńskiej majątkowej, kupili mieszkanie z
                oszczędności z wynagrodzenia za pracę, które otrzymywali po
                zawarciu małżeństwa. Mieszkanie będzie stanowiło składnik
                majątku wspólnego małżonków. W takiej sytuacji należy wpisać
                połowę wartości mieszkania, ponieważ w przypadku śmierci jednego
                z małżonków, tylko ta część będzie podlegała dziedziczeniu.
                Jeżeli mieszkanie jest warte 350.000,00 zł, to należy wpisać
                175,000,00 zł.
              </i>
            </li>
            <li>
              <strong>Współwłasność ułamkowa</strong> - jeżeli Osoba, po której
              dziedziczenie sprawdzasz jest współwłaścicielem określonego
              składnika (tj. przysługuje jej udział we własności), to oceniając
              wartość składnika, należy wziąć pod uwagę wyłącznie ten udział, a
              nie wartość całego składnika.
              <br />
              <i>
                <strong>Przykład:</strong> Agnieszce przysługuje udział 1/16 w
                prawie własności nieruchomości, którą odziedziczyła po babci.
                Nieruchomość jest warta 300.000,00 zł. Weryfikując dziedziczenie
                po Agnieszce należy wpisać wartość tego składnika jako 18.750,00
                zł.
              </i>
            </li>
            <li>
              <strong>
                Spółki (udziały, akcje, wspólnik w spółce osobowej)
              </strong>{" "}
              - w przypadku osób, które są akcjonariuszami/udziałowcami spółek
              kapitałowych lub wspólnikami spółek osobowych należy nie należy
              wpisywać wartości całej spółki, a wyłącznie wartość
              udziałów/akcji/praw przysługujących tej osobie.
            </li>
            <li>
              <strong>Inne</strong> - w tej rubryce wpisz zsumowaną wartość
              wszystkich pozostałych składników majątku, których nie
              uwzględniłeś powyżej (np. biżuteria, sprzęty, maszyny, dzieła
              sztuki itd.).
            </li>
          </ul>
        </p>
      </div>
    </Popup>
  );
}

export default PopupWealth;
