import React from "react";

import { ReactComponent as InfoIcon } from "../../../../assets/icons/Info.svg";
import { ReactComponent as DiscIcon } from "../../../../assets/icons/Disc.svg";

function ExistingClientsTable({ data, handleSave }: any) {
  return (
    <div className="mb-10 max-h-96 overflow-auto">
      <div className="w-full">
          {data.map((client: any, i: number) => {
            return (
              <div
                key={i}
                className="flex flex-row justify-between border-b last:border-b-0 border-lightBlue"
              >
                <div className="flex flex-col">
                  <div className="flex flex-col p-2 text-darkBlue">
                    {client.fullName}
                  </div>
                  <div className="flex flex-col p-2 text-darkGrey">
                    {" "}
                    <div className="flex flex-row items-center gap-3">
                      {" "}
                      <InfoIcon height={15} /> {client.additionalData}
                    </div>
                  </div>
                </div>

                <div className="flex justify-center items-center sm:pr-10">
                  <DiscIcon
                    className="blueDisc cursor-pointer"
                    onClick={() => handleSave(client.id)}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default ExistingClientsTable;
