import React, { useState, useEffect } from "react";
import LoadingStep from "../../components/LoadingStep";
import ScenarioSelect from "../../components/ScenarioSelect";
import ScenarioNumberInput from "../../components/ScenarioNumberInput";
import { nextQuestion } from "../../../../client/AppClient";
import displayError from "../../../../utils/handling-errors/displayError";
import { useDispatch } from "react-redux";
import ScenarioListInput from "../../components/ScenarioListInput";

function ScenarioQuestion({ handleNextCaseStep }: any) {
  const [questionData, setQuestionData] = useState<any>({});
  const [isFirstQuestion, setIsFirstQuestion] = useState(true);
  const [specialField, setSpecialField] = useState({
    fieldName: "",
    value: 1,
  });
  const [specialListFields, setSpecialListFields] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const getFirstQuestion = async () => {
      const response: any = await nextQuestion();
      if (response.status !== 200) return displayError(dispatch, response);
      setQuestionData(response.data);
    };
    if (Object.keys(questionData).length === 0 && isFirstQuestion) {
      getFirstQuestion();
      setIsFirstQuestion(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (answerId: string) => {
    setQuestionData({});
    const response = await nextQuestion(questionData.id, answerId);
    if (response.status !== 200 && response.status !== 204)
      return displayError(dispatch, response);
    if (response.status === 204) return handleNextCaseStep();
    setQuestionData(response.data);
  };

  const getTypeOfQuestion = () => {
    const keys = Object.keys(questionData);
    if (keys.includes("answers")) {
      return "ANSWERS";
    } else if (keys.includes("list")) {
      return "LIST_INPUT";
    } else if (keys.includes("field")) {
      return "NUMERIC_INPUT";
    }
  };

  if (Object.keys(questionData).length === 0) return <LoadingStep />;

  if (getTypeOfQuestion() === "ANSWERS")
    return <ScenarioSelect data={questionData} onSubmit={onSubmit} />;
  else if (getTypeOfQuestion() === "LIST_INPUT") {
    return (
      <ScenarioListInput
        data={questionData}
        onSubmit={onSubmit}
        specialField={specialField}
        setSpecialListFields={setSpecialListFields}
        specialListFields={specialListFields}
      />
    );
  } else {
    return (
      <ScenarioNumberInput
        data={questionData}
        onSubmit={onSubmit}
        setSpecialField={setSpecialField}
      />
    );
  }
}

export default ScenarioQuestion;
