import { Document, Page, View, Image } from "@react-pdf/renderer";
import styles from "../styles/PdfStyles";
import logo from "../../../assets/images/successio-logo.png";
import PdfDescription from "../components/PdfDescription";
import PdfRegulations from "../components/PdfRegulations";

function PdfDocument({ data, chartBase, setPdfLoaded }: any) {
  return (
    <Document onRender={() => setPdfLoaded(true)}>
      <Page size="A4" style={styles.page}>
        <View style={styles.logoWrap}>
          <View>
            <Image src={logo} style={styles.logo} />
          </View>
        </View>
        <PdfDescription title={data.name} description={data.result.text} />
        <View style={styles.chartWrap}>
          <Image src={chartBase} style={styles.chart} />
        </View>
        <PdfRegulations regulations={data.result?.regulations} />
      </Page>
    </Document>
  );
}

export default PdfDocument;
