import axios, { AxiosError } from "axios";

interface ResponseData {
  data?: any;
  status?: number;
  statusText?: string;
  headers?: any;
  error?: any;
}

export const googleTokenRequest = async (
  code: string
): Promise<ResponseData> => {
  try {
    const res = await axios.post(
      "https://oauth2.googleapis.com/token",
      {
        code: code,
        client_id:
          "721113592147-6sqk65mj60t85672p979vj2k4snuprqu.apps.googleusercontent.com",
        client_secret: process.env.REACT_APP_GOOGLE_SR,
        redirect_uri: "https://app.successio.pl/auth/social/google",
        grant_type: "authorization_code",
      },
      {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
      }
    );
    const result: ResponseData = {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
      headers: res.headers,
      error: "",
    };
    return result;
  } catch (err: any) {
    console.log(err);
    const errors = err as AxiosError;
    const result: ResponseData = {
      error: errors.code,
      status: errors.response?.status,
      data: errors.response?.data,
    };
    return result;
  }
};
