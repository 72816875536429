import { isValidPhoneNumber } from "react-phone-number-input";
import { validateEmail } from "./globalValidations";

export const validateRegisterForm = (
  email: string,
  passwd: string,
  passwdRepeat: string,
  phone: string,
  terms: boolean,
) => {
  const errors: any = {}; 

  const validEmail = validateEmail(email);
  const passwordsMatch = passwd === passwdRepeat;
  const passwordLength = passwd.length >= 8;
  const phoneValid = isValidPhoneNumber(phone);
  
  if (!validEmail) errors["email"] = "E-mail niepoprawny";
  if (!passwordsMatch) errors["passwd"] = errors["passwdRepeat"] = "Hasła nie są takie same";
  if (!passwordLength) errors["passwd"] = "Hasło musi mieć conajmniej 8 znaków";
  if (!phoneValid) errors["phone"] = "Format numeru telefonu jest nieprawidłowy";
  if (!terms) errors["terms"] = "Konieczne jest zaakceptowanie regulaminu";

  return errors;
};