import postRequest from "../../requests/postRequest";

interface addClientInterface {
  id: string;
  userId: string;
  fullName: string;
  description?: string;
  additionalData?: string;
}

export const editClientService = async (body: addClientInterface) => {
  const result = await postRequest({
    url: "/client/update",
    params: {
      id: body.id,
      userId: body.userId,
      fullName: body.fullName,
      additionalData: body.additionalData,
      description: body.description,
    },
  });
  return result;
};
