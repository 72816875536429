import React, { useState, useEffect } from "react";
import PageWrap from "../../components/layout/PageWrap";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import RenderSteps from "./steps/Steps";
import {
  resetPortion,
  updateScenarioValues,
} from "../../redux/reducers/partionSlice";
import NoAccess from "../../components/layout/NoAccess";
import getLegalPortionPersons from "./helpers/getLegalPortionPersons";
import Button from "../../components/common/button/Button";
import Header from "../../components/common/text/Header";

function LegalPortion() {
  const [formIndex, setFormIndex] = useState(1);
  const [cantCalculate, setCantCalculate] = useState<any>(null);
  

  const dispatch = useDispatch();
  const location = useLocation();
  const navgiate = useNavigate();

  const checkIfPortionIsAble = () => {
    const persons = location.state?.scenarioData.divisionOfAssets;
    const personsAble = getLegalPortionPersons(persons);
    if (personsAble.length === 0) setCantCalculate(true);
    else setCantCalculate(false);
  }
  

  useEffect(() => {
    dispatch(resetPortion());
    if (location.state !== null) {
      checkIfPortionIsAble();
      dispatch(
        updateScenarioValues({
          wealth: location.state?.scenarioData?.wealth.total / 100,
        })
      );
    }
    return () => {
      dispatch(resetPortion());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleNext = (target: number = 1) => {
    setFormIndex((prev) => prev + target);
  };

  if (location.state === null) {
    return (
      <NoAccess
        title="Brak dostępu strony"
        description="Aby utworzyć nowy zachowek, należy najpierw wybrać scenariusz dla którego ma on zostać utworzony."
      />
    );
  }

  if (cantCalculate) {
    return (
      <PageWrap>
        <div className="w-full max-w-xl sm:px-4 mx-auto">
          <div className="sm:bg-white sm:shadow-container rounded-lg sm:p-12 mt-5">
            <div className="flex flex-col">
              <Header>Zachowek</Header>
              <h2 className="text-lg font-semibold text-darkBlue mt-10 mb-7">
                Brak uprawnionych, dla których można wyliczyć zachowek
              </h2>
              <p className="text-darkGrey my-4 mb-22">
                Do zachowku są uprawnieni wyłącznie zstępni, małżonek i rodzice,
                którzy byliby powołani do spadku na mocy dziedziczenia
                ustawowego.
              </p>
              <div className="flex justify-center pt-1 mt-10 mb-4 pb-1">
                <Button onClick={() => navgiate(-1)}>Powrót</Button>
              </div>
            </div>
          </div>
        </div>
      </PageWrap>
    );
  }

  if (cantCalculate === false) {
    return (
      <PageWrap>
        {formIndex >= 8 ? (
          <RenderSteps index={formIndex} onSubmit={handleNext} />
        ) : (
          <div className="w-full max-w-xl px-4 mx-auto ">
            <RenderSteps index={formIndex} onSubmit={handleNext} />
          </div>
        )}
      </PageWrap>
    );
  }
  return <></>
}

export default LegalPortion;
