import React from "react";
import classNames from "classnames";

interface RadioSelectInterface {
  children: React.ReactNode;
  required?: boolean;
  checked?: boolean;
  id: string;
  value?: string | number;
  selected?: string | number;
  group: string;
  disabled?: boolean;
  onChange?: any;
  onClick?: any;
}

function RadioSelect({
  children,
  required,
  checked,
  id,
  value,
  selected,
  group,
  disabled,
  ...rest
}: RadioSelectInterface) {
  return (
    <label
      htmlFor={id}
      className={classNames(
        "flex flex-row gap-5 items-center relative border rounded-md py-4 px-6 bg-white",
        {
          "border-darkBlue": selected === value,
          "border-lightBlue": selected !== value,
          "bg-gray-200": disabled === true,
        }
      )}
    >
      <div className="relative mt-1">
        <input
          id={id}
          type="radio"
          className={classNames(
            "checkBox-input appearance-none h-6 w-6 border-2 bg-white p-2 rounded-full check-1 z-10",
            {
              "border-darkGrey": selected !== value,
              "border-darkBlue": selected === value,
            }
          )}
          name={group}
          value={value}
          checked={selected === value}
          required={required}
          disabled={disabled}
          readOnly
          {...rest}
        />
        <div className="checkBox-content h-3 w-3 absolute bg-darkBlue bottom-3 left-1.5 check-1 opacity-0 rounded-full transition-opacity z-0"></div>
      </div>
      <span
        className={classNames("font-medium", {
          "text-darkGrey": selected !== value,
          "text-darkBlue": selected === value,
        })}
      >
        {children}
      </span>
    </label>
  );
}

export default RadioSelect;
