import React from 'react';

const ResetMessage = ({ email }: any) => {
  return (
    <div>
      <p className="text-sm mt-5 mb-14 text-darkGrey">
        Link do zresetowania hasła został wysłany na adres
        <br />
        e-mail: <span className="text-gold semibold">{email}</span>
      </p>
    </div>
  );
};

export default ResetMessage;