import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { editAccount } from "../../../client/AppClient";
import Form from "../../../components/common/forms/Form";
import InputBordered from "../../../components/common/forms/InputBordered";
import Popup from "../../../components/specific/Popup";
import displayError from "../../../utils/handling-errors/displayError";
import displaySuccess from "../../../utils/handling-success/displaySuccess";
import { inputOnlyLetters } from "../../../validations/inputValidations";

interface EditProfileInterface {
  data: any;
  popupOpened: boolean;
  setPopupOpened: Function;
  effectCalled?: any;
}

function EditAccount({
  data,
  popupOpened,
  setPopupOpened,
  effectCalled,
}: EditProfileInterface) {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const clientData = {
      fullName: name,
      address: address,
    };
    const response = await editAccount(clientData);
    if (response.status !== 200) return displayError(dispatch, response);
    effectCalled.current = false;
    displaySuccess(dispatch, "Sukces", "Zapisano zmiany");
    setPopupOpened(false);
  };

  useEffect(() => {
    if (data.fullname) setName(data.fullname);
    if (data.address) setAddress(data.address);
  }, [data]);

  return (
    <Popup
      title={"Edytuj profil Użytkownika"}
      trigger={popupOpened}
      setTrigger={setPopupOpened}
    >
      <div className="flex flex-col">
        <Form
          id="editProfile"
          submitBtn={"Zapisz"}
          onSubmit={handleSubmit}
          buttonSize="md"
        >
          <InputBordered
            label="Imię i Nazwisko"
            name="name"
            type="text"
            placeholder="Wpisz imię i nazwisko..."
            divClassName="my-8"
            value={name}
            onChange={(e: any) => setName(e.target.value)}
            onKeyPress={inputOnlyLetters}
            required
          />
          <InputBordered
            label="Adres"
            name="email"
            type="text"
            placeholder="Wpisz adres zamieszkania..."
            divClassName="my-8"
            value={address}
            onChange={(e: any) => setAddress(e.target.value)}
            required
          />
        </Form>
      </div>
    </Popup>
  );
}

export default EditAccount;
