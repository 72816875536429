import setJwt from "../../helpers/setJwt";
import postRequestNoAuth from "../../requests/postRequestNoAuth";


interface signInInterface {
  email: string;
  password: string;
}

export const signIn = async (body: signInInterface) => {
    const result = await postRequestNoAuth({
      url: "/login",
      params: {
        email: body.email,
        password: body.password,
      },
    });
    if(result.status === 200 && result.data.twoFactor !== "true") setJwt(result.data);
    return result;
}
