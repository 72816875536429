import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

// components
import Header from "../../components/common/text/Header";
import PageWrap from "../../components/layout/PageWrap";
 import ShadowContentWrap from "../../components/layout/ShadowContentWrap";
 
import Scenarios from "./components/Scenarios";
import EditProfile from "./components/EditProfile";

// icons
import { ReactComponent as InfoIcon } from "../../assets/icons/Info.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";


import { getClientData } from "../../client/AppClient";
import displayError from "../../utils/handling-errors/displayError";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../components/specific/LoadingSpinner";

function ClientPage() {
  const [clientData, setClientData] = useState<any>({});
  const [currentTab, setCurrentTab] = useState(0);
  const [popupOpened, setPopupOpened] = useState(false);
  const [loading, setLoading] = useState(true);

  const { personId } = useParams();
  const effectCalled = useRef<boolean>(false);
  const dispatch = useDispatch();


  const fetchClientData = async () => {
    if (!personId) return;
    const response = await getClientData(personId);
    setLoading(false);
    if(response.status !== 200) return displayError(dispatch, response);
    setClientData(response.data);
  }

  useEffect(() => {
    if (effectCalled.current) return;
    fetchClientData();
    effectCalled.current = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupOpened]);

  const TabElement = ({ name, index }: any) => {
    return (
      <span
        className={`text-sm md:text-base text-center relative ${
          currentTab === index
            ? "after:absolute after:-bottom-1 after:left-0 after:bg-gold after:w-full after:h-1 after:rounded-md"
            : ""
        }`}
        onClick={() => setCurrentTab(index)}
      >
        {name}
      </span>
    );
  };

  const tabs = [
    {
      index: 0,
      name: "Lista scenariuszy",
      component: <Scenarios id={personId} clientName={clientData?.fullName} />,
    },
  ];

  if (loading) {
    return <LoadingSpinner />
  }

  if(!loading && Object.keys(clientData).length === 0) {
    return (
      <PageWrap>
        <div className="w-full max-w-6xl mx-auto">
          <ShadowContentWrap>
            <div className="flex flex-row justify-between items-center mb-6">
              <Header>Profil Osoby</Header>
            </div>
            <div className="flex flex-col md:flex-row justify-between w-11/12 md:items-end">
              <span className="text-lg text-darkBlue">Nie udało się pobrać danych z serwera. Spróbuj ponownie później</span>
            </div>
          </ShadowContentWrap>
        </div>
      </PageWrap>
    );
  }

    return (
      <>
        <EditProfile
          data={clientData}
          popupOpened={popupOpened}
          setPopupOpened={setPopupOpened}
          effectCalled={effectCalled}
        />
        <PageWrap>
          <div className="w-full max-w-6xl mx-auto">
            <ShadowContentWrap>
              <div className="flex flex-row justify-between items-center mb-6">
                <Header>Profil Osoby</Header>
                <EditIcon
                  className="cursor-pointer"
                  onClick={() => setPopupOpened(true)}
                />
              </div>
              <div className="flex flex-col md:flex-row justify-between w-11/12 md:items-end">
                <span className="text-lg text-darkBlue">
                  {clientData?.fullName}
                </span>
                <span className="flex gap-2 text-darkGrey">
                  {clientData.additionalData ? (
                    <>
                      <InfoIcon width={18} /> {clientData?.additionalData}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </ShadowContentWrap>
            <ShadowContentWrap className="mt-16 sm:mt-5">
              <div className="flex flex-row gap-5 border-b-4 border-light">
                {tabs.map((tab) => {
                  return (
                    <TabElement
                      name={tab.name}
                      index={tab.index}
                      key={tab.index}
                    />
                  );
                })}
              </div>
              <div className="mt-5">{tabs[currentTab].component}</div>
            </ShadowContentWrap>
          </div>
        </PageWrap>
      </>
    );
}

export default ClientPage;
