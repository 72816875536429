import React from 'react';
import ShadowContentWrap from '../../../components/layout/ShadowContentWrap';
import { Link } from 'react-router-dom';
import Button from '../../../components/common/button/Button';

function ContactWrap({ subscription }: any) {
    return (
      <ShadowContentWrap>
        <h4 className="text-lg text-darkBlue font-semibold">
          Potrzebujesz pomocy?
        </h4>
        <p className="text-darkGrey mt-3 mb-8">
          {subscription.subscriptionType === "NORMAL" ? (
            <>
              Chciałbyś porozmawiać o kompleksowym zabezpieczeniu swoich
              Bliskich?
              <br /> Zapraszamy do kontaktu z naszym Doradcą.
            </>
          ) : (
            <>
              Potrzebujesz pomocy w obsłudze aplikacji lub chcesz zgłosić swoje
              uwagi?
              <br /> Zapraszamy do kontaktu z Opiekunem Klienta.
            </>
          )}
        </p>
        <div className="flex justify-center items-center">
          <Link to="/contact">
            <Button>Skontaktuj się</Button>
          </Link>
        </div>
      </ShadowContentWrap>
    );
}

export default ContactWrap;