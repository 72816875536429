import React from 'react';
import Popup from '../../../components/specific/Popup';
import Button from '../../../components/common/button/Button';
import { changeUserStatus } from '../../../client/AppClient';
import displayError from '../../../utils/handling-errors/displayError';
import { useDispatch } from 'react-redux';
import displaySuccess from '../../../utils/handling-success/displaySuccess';

interface ConfirmPopupInterface {
  popupOpened: boolean;
  setPopupOpened: Function;
  confirmData: {
    title: string;
    description: string;
    id: string;
    status: string;
  };
}

function ConfirmPopup({
  popupOpened,
  setPopupOpened,
  confirmData,
}: ConfirmPopupInterface) {
  const dispatch = useDispatch();

  const updateUserStatus = async () => {
    const response = await changeUserStatus(confirmData.id, confirmData.status);
    if (response.status !== 200) return displayError(dispatch, response);
    displaySuccess(dispatch, "Sukces", "Pomyślnie wykonano akcję");
    setPopupOpened(false);
  };

  return (
    <Popup
      title={confirmData?.title}
      trigger={popupOpened}
      setTrigger={setPopupOpened}
    >
      <div className="flex flex-col">
        <p className="text-darkGrey text-center">{confirmData?.description}</p>
        <div className="max-w-xl flex flex-col gap-3 sm:gap-0 sm:flex-row justify-around mt-8">
          <Button onClick={updateUserStatus}>Tak</Button>
          <Button onClick={() => setPopupOpened(false)}>Nie</Button>
        </div>
      </div>
    </Popup>
  );
}

export default ConfirmPopup;