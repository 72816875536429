import { createContext, useContext } from "react";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../firebase";

const userAuthContext = createContext({});

export function UserAuthContextProvider({ children }: any) {

  function setUpRecaptcha(number: any, resend: boolean = false) {
    window.recaptchaVerifier! = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
    return signInWithPhoneNumber(auth, number, window.recaptchaVerifier);
  }

  function resendOtpCode(number: any) {
    // const recaptchaContainer = document.getElementById("recaptcha-container");
    // Create a new reCAPTCHA instance

    // Return the new signInWithPhoneNumber promise
    return signInWithPhoneNumber(auth, number, window.recaptchaVerifier);
  }

  return (
    <userAuthContext.Provider
      value={{
        setUpRecaptcha,
        resendOtpCode,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}