const errorCodes: { [code: number | string]: string } = {
  "error.auth.userNotFound": "Nieprawidłowy email lub hasło",
  "error.auth.invalidPassword": "Nieprawidłowy email lub hasło",
  "error.alreadyExists": "Użytownik z podanym emailem już istnieje",
  "error.auth.notAuthenticated": "Brak uprawnień",
  "error.auth.twoFactorTokenGenerationError":
    "Błąd przy generowaniu klucza autoryzacji",
  "error.auth.resetPasswordTokenExpired":
    "Klucz do resetu hasła stracił ważność",
  "error.auth.resetPasswordTokenIncorrect":
    "Klucz do resetu hasła jest nieprawidłowy",
  "error.user.phoneVerificationError": "Błąd przy weryfikacji telefonu",
  "error.subscription.renewError": "Błąd przy odnowieniu subsrkypcji",
  "error.subscription.cancelError": "Błąd przy anulowaniu subsrkypcji",
  "error.auth.resetPasswordTokenGenerationError":
    "Nie udało się wygenerować kluczu resetu hasła",
  "user.client.retrieve.failure": "Nie udało się pobrać danych z serwera",
  "error.saveError": "Błąd przy zapisie",
  "error.updateError": "Nie udało się zaktualizować",
  "error.email": "Nieprawidłowy format email",
  "error.required":
    "Niektóre z wymagynch pól zostało wprowadzone nieprawidłowo",
  "error.notFound": "Nie znaleziono",
  "error.wrongDateTimeFormat": "Nieprawidłowy format daty",
  "error.uuid": "Nieprawidłowy uuid",
  "error.minLength8": "Minimalna długość to 8 znaków",
  "error.missingBilingDetails": "Dane do faktury nie zostały wypełnione",
  "error.notAllowedPlanSwitch": "Zmiana planu niedozwolona",
  "error.userSaveError": "Użytkownik z podanym adresem email już istnieje.",
  "error.wrongUserAmount": "Nieprawidłowa ilość Użytkowników",
  "error.wrongNumberOfUsers": "Nieprawidłowa ilość Użytkowników",
  "error.negativeValue": "Odpowiedzi zostały wprowadzone nieprawidłowo",
  "error.numberOfUsersToLow": "Niewystarczająca ilość Użytkowników",
  "auth.twoFactor.invalid.token": "Nieprawidłowy kod weryfikacji",
  401: "Brak uprawnień",
  403: "Dostęp zabroniony",
  404: "(404)Nie znaleziono",
  500: "Wewnętrzny błąd serwera",
};

const inputErrorCodes: { [code: string]: string } = {
  "error.email": "Nieprawidłowy format email",
  "error.required": "Pole zostało przesłane nieprawidłowo",
  "error.minLength8": "Minimalna długość to 8 znaków",
};

export function getErrorResponse(code: number | string, key?: string) {
  if (key) return inputErrorCodes[code] || "Wystąpił nieznany błąd";
  return errorCodes[code] || "Wystąpił nieznany błąd";
}
