import React from "react";

import { ReactComponent as PhoneIcon } from "../../../assets/icons/Phone.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/Email.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit.svg";
import { ReactComponent as RoundedXIcon } from "../../../assets/icons/RoundedX.svg";
import { ReactComponent as RoundedAnchorIcon } from "../../../assets/icons/RoundedAnchor.svg";

function UsersTable({
  data,
  handleChangeStatus,
  handleEditPopupOpen,
}: any) {
  const handleUserAction = (type: "BLOCK" | "UNBLOCK", id: string, fullname: string) => {
    if (type === "BLOCK") return handleChangeStatus(id, "ARCHIVED", fullname);
    if (type === "UNBLOCK") return handleChangeStatus(id, "ACTIVE", fullname);
  };

  return (
    <div className="mb-10">
      <table className="table-auto border-collapse w-full">
        <tbody>
          {data.map((user: any, i: number) => {
            return (
              <tr key={i} className="border-b border-lightBlue ">
                <td className="flex flex-col lg:table-cell py-4 pr-4 text-darkBlue">
                  {user.fullname}
                </td>
                <td className="flex flex-col lg:table-cell p-4 lg:pl-8 text-darkBlue">
                  <span
                    className={`border rounded-md  text-xs tracking-wider py-1 px-3 max-w-fit ${
                      user.status === "ACTIVE"
                        ? "border-green text-green"
                        : "border-darkGrey text-darkGrey"
                    }`}
                  >
                    {user.status === "ACTIVE" ? "AKTYWNY" : "ZABLOKOWANY"}
                  </span>
                </td>
                <td className="flex flex-col lg:table-cell p-4 text-darkGrey">
                  {" "}
                  <div className="flex flex-row items-center gap-3">
                    {" "}
                    <PhoneIcon /> {user.phoneNumber}
                  </div>
                </td>
                <td className="flex flex-col lg:table-cell p-4 text-darkGrey">
                  {" "}
                  <div className="flex flex-row items-center gap-3">
                    {" "}
                    <EmailIcon /> {user.email}
                  </div>
                </td>
                <td className="flex flex-col lg:table-cell py-4 pl-4   text-darkGrey">
                  <div className="flex flex-row items-center gap-3">
                    {" "}
                    <EditIcon
                      className="greyEditIcon cursor-pointer"
                      onClick={() => {
                        handleEditPopupOpen(user);
                      }}
                    />
                    {user.status === "ACTIVE" ? (
                      <RoundedXIcon
                        className="cursor-pointer"
                        onClick={() =>
                          handleUserAction("BLOCK", user.id, user.fullname)
                        }
                      />
                    ) : (
                      <RoundedAnchorIcon
                        className="cursor-pointer"
                        onClick={() =>
                          handleUserAction("UNBLOCK", user.id, user.fullname)
                        }
                      />
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default UsersTable;
