import React, { useState } from "react";
import InputBordered from "../../../../components/common/forms/InputBordered";
import ShadowContentWrap from "../../../../components/layout/ShadowContentWrap";
import { inputOnlyNumbers } from "../../../../validations/inputValidations";
import { ReactComponent as Anchor } from "../../../../assets/icons/AnchorBlue.svg";
import Button from "../../../../components/common/button/Button";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { renewSubscription } from "../../../../client/AppClient";
import displayError from "../../../../utils/handling-errors/displayError";

interface IEnterprisePlan {
  pricePerUser: number;
  numberOfUsers: number;
  setIsTransactionStarted: Function;
}

const transactionUrl = process.env.REACT_APP_TRANSACTION_URL;

function ExpandAccount({
  pricePerUser,
  numberOfUsers,
  setIsTransactionStarted,
}: IEnterprisePlan) {
  const [userCount, setUserCount] = useState(numberOfUsers.toString());
  const dispatch = useDispatch();
  const location = useLocation();

   const saveRedirectLink = () => {
     const path = location.state?.navigatedFrom;
     if (path === undefined) return;
     localStorage.setItem("redirectPath", path);
   };


  const handleUpdateCounter = (value: number) => {
    const updatedNumber = `${Number(userCount) + value}`;
    if (Number(updatedNumber) >= 0) setUserCount(updatedNumber);
  };

  const submitExpand = async () => {
    const response = await renewSubscription("ENTERPRISE", Number(userCount));
    if (response.status !== 200) return displayError(dispatch, response);
    saveRedirectLink();
    setIsTransactionStarted(true);
    const token = response.data.transactionToken;
    window.location.href = transactionUrl + token;
  };

  return (
    <>
      <ShadowContentWrap className="mt-12">
        <div className="flex flex-col">
          <h4 className="text-darkBlue font-semibold text-lg">
            Przedłuż konto
          </h4>
          <div className="flex flex-col">
            <div className="flex flex-row items-center mt-20 mb-28">
              <span className="text-lg text-darkBlue">Ilość Użytkowników</span>
              <div className="relative w-2/3 mx-14">
                <InputBordered
                  label=""
                  name="name"
                  type="text"
                  divClassName=""
                  inputClassName="text-right text-greyBlue"
                  value={userCount}
                  onChange={(e: any) => setUserCount(e.target.value)}
                  onKeyPress={inputOnlyNumbers}
                  required
                />
                <span className="absolute right-0 mt-6 text-greyBlue">
                  {(pricePerUser * Number(userCount)) / 100 + " zł"}
                </span>
              </div>
              <div className="flex flex-col">
                <button>
                  <Anchor
                    className="cursor-pointer"
                    onClick={() => handleUpdateCounter(1)}
                  />
                </button>
                <button>
                  <Anchor
                    className="cursor-pointer rotate-180"
                    onClick={() => handleUpdateCounter(-1)}
                  />
                </button>
              </div>
            </div>
            <div className="flex justify-center">
              <Button onClick={submitExpand}>Dokonaj płatności</Button>
            </div>
          </div>
        </div>
      </ShadowContentWrap>
      <div className="bg-gradient-to-t from-sherpaBlue to-darkBlue p-3 sm:p-7 mt-10 sm:mb-16 rounded-lg">
        <div className="px-7 py-7 bg-white rounded-lg">
          <h5 className="text-lg text-darkBlue font-semibold">PAMIĘTAJ!</h5>
          <p className="text-darkGrey mt-3">
            Zmiana ilości Użytkowników nie zmienia daty wygaśnięcia konta. W
            przypadku zwiększenia liczby Użytkowników zostanie naliczona opłata
            uwzględniająca czas trwania aktywności Użytkownika w danym okresie
            rozliczeniowym. Dodatkowo, zostanie wygenerowana faktura różnicowa
            uwzględniająca koszt zwiększenia liczby Użytkowników w stosunku do
            poprzedniego okresu rozliczeniowego.
          </p>
        </div>
      </div>
    </>
  );
}

export default ExpandAccount;
