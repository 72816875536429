import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';


function NavbarNotLogged() {
    return (
      <nav className="relative w-full">
        <div className="flex justify-center items-center pt-10">
          <Link to="/">
            <Logo />
          </Link>
        </div>
      </nav>
    );
}

export default NavbarNotLogged;