import React, { useState, useEffect } from "react";
import PageWrap from "../../components/layout/PageWrap";
import { ReactComponent as ImageSvg } from "../../assets/images/ContactWaiting.svg";
import Popup from "../../components/specific/Popup";
import Button from "../../components/common/button/Button";
import useWebSocket, { ReadyState } from "react-use-websocket";
import Cookies from "js-cookie";
import { saveRtcToLocalStorage } from "../video-call/helpers/agoraLocalStorage";
import { useNavigate } from "react-router-dom";
import JoinSound from "../../assets/audio/joinaudio.mp3";

const socketUrl = "wss://api.successio.pl/api/consultant/socket";

function ContactWaiting() {
  const [popupOpened, setPopupOpened] = useState(false);
  const [lastRtcData, setLastRtcData] = useState<any>(null);
  const [isAudioAvailable, setIsAudioAvailable] = useState<any>(true);

  const navigate = useNavigate();
  const jwt: any = Cookies.get("jwt");
  const { lastMessage, readyState } = useWebSocket(socketUrl, {
    queryParams: { "X-Auth-Token": jwt },
  });

  useEffect(() => {
    const checkAudio = new Audio(JoinSound);
    checkAudio.volume = 0.0;
    checkAudio.play().then(_ => {}).catch(error => {
      setIsAudioAvailable(false);
    })
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
      const message = JSON.parse(lastMessage.data);
      if (message.rtcToken) {
        setLastRtcData(message);
        setPopupOpened(true);
        new Audio(JoinSound).play();
      }
    }
  }, [lastMessage]);

  const handleConnect = () => {
    setPopupOpened(false)
    const dataToSave = {
      channelId: lastRtcData.rtcToken.channelId,
      token: lastRtcData.rtcToken.token,
      uid: Number(lastRtcData.rtcToken.uid),
      startDate: new Date(),
      fullname: lastRtcData.fullname,
    };
    saveRtcToLocalStorage(dataToSave);
    setTimeout(() => {
      window.location.href = "/videoCall";
    }, 1000);
  };

  const handleReject = () => {
    setPopupOpened(false);
    setLastRtcData(null);
    navigate(0);
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Łączenie",
    [ReadyState.OPEN]: "Gotowy",
    [ReadyState.CLOSING]: "Rozłączanie",
    [ReadyState.CLOSED]: "Rozłączony",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  return (
    <>
      <Popup
        trigger={popupOpened}
        setTrigger={setPopupOpened}
        title={`Masz nowe połączenie od: ${lastRtcData?.fullname || "Jan Kowalski"}`}
      >
        <div className="flex justify-center gap-3">
          <Button onClick={handleConnect}>Odbierz</Button>
          <Button onClick={handleReject}>Odrzuć</Button>
        </div>
      </Popup>
      <PageWrap>
        <div className="w-full max-w-xl sm:px-4 mx-auto">
          <div className="flex flex-col items-center ">
            <ImageSvg className="w-full h-auto" />
            <p className="text-darkBlue font-semibold text-lg sm:text-2xl md:text-3xl text-center mt-5">
              Oczekiwane na połączenie z Klientami
            </p>
            <span>
              Status połączenia:{" "}
              <span className="font-semibold text-gold">
                {connectionStatus}
              </span>
            </span>
            {!isAudioAvailable && (
              <div className="mt-6"><Button onClick={() => setIsAudioAvailable(true)}>Włącz powiadomienia dźwiękowe</Button></div>
            )}
          </div>
        </div>
      </PageWrap>
    </>
  );
}

export default ContactWaiting;
