// get days from 2 dates

const getDays = (dateString: string) => {
    const currentDate = new Date();
    const passedDate = new Date(dateString);

    const differenceInTime = passedDate.getTime() - currentDate.getTime();

    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return Math.round(differenceInDays);
}

export default getDays;