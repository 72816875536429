import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Cookies from "js-cookie";

import App from "../views/start-page/App";
import Login from "../views/login/Login";
import Register from "../views/register/Register";
import PassForget from "../views/pass-forget/PassForget";
import ResetPassword from "../views/pass-forget/ResetPassword";
import ClientBase from "../views/scenarios-base/ScenariosBase";
import NewScenario from "../views/new-scenario/NewScenario";
import ClientPage from "../views/client-page/ClientPage";
import Plans from "../views/plans/Plans";
import MyAccount from "../views/my-account/MyAccount";
import MyPlan from "../views/my-plan/MyPlan";
import LegalPortion from "../views/legal-portion/LegalPortion";
import ScenarioFile from "../views/scenario-file/ScenarioFile";
import ScenarioPage from "../views/scenario-page/ScenarioPage";
import AlternativeScenarios from "../views/alternative-scenario/AlternativeScenarios";


import Nav from "../components/Navbars/RenderNavbar";
import Home from "../views/home/Home";
import PassChange from "../views/pass-change/PassChange";
import Contact from "../views/contact/Contact";
import PageNotFound from "../views/404/404";

import ProtectedRoute from "../components/auth/ProtectedRoute";
import usePersistUser from "../hooks/usePersistUser";
import { useSelector } from "react-redux";
import { getUser } from "../redux/reducers/userSlice";
import NotificationsGlobal from "../components/layout/NotificationsGlobal";
import LoadingSpinner from "../components/specific/LoadingSpinner";
import VideoCall from "../views/video-call/VideoCall";
import ContactWaiting from "../views/consultant/ContactWaiting";
import EnterpriseUsers from "../views/enterprise-users/EnterpriseUsers";
import TransactionResult from "../views/transaction-result/TransactionResult";
import ChooseEnterprise from "../views/plans/enterprise/ChooseEnterprise";
import EditScenario from "../views/edit-scenario/EditScenario";
import ScenarioPdf from "../views/scenario-pdf/ScenarioPdf";
import CookieConsent from "../components/specific/CookieConsent";
import SocialAuthPage from "../views/social-auth/SocialAuthPage";

export function Router() {
  const loading = usePersistUser();
  const user = useSelector(getUser);
  const isCookieAccepted = Cookies.get("cookies-accept");

  if(loading) return <LoadingSpinner />

  return (
    <BrowserRouter>
      <Nav user={user} />
      <Routes>
        <Route path="/">
          {/* ACCESS FOR NON LOGGED IN USERS ONLY */}
          <Route
            element={
              <ProtectedRoute accessBy="non-authenticated" user={user} />
            }
          >
            <Route index path="/" element={<App />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login/passwordForget" element={<PassForget />} />
            <Route path="/login/resetPassword" element={<ResetPassword />} />
            <Route path="/register" element={<Register />} />
          </Route>

          {/* ACCESS FOR LOGGED IN USERS ONLY */}
          <Route
            element={<ProtectedRoute accessBy="authenticated" user={user} />}
          >
            <Route path="/home" element={<Home />} />

            <Route path="/plans" element={<Plans />} />

            <Route
              path="/transaction/:transactionId/result"
              element={<TransactionResult />}
            />

            <Route path="/newscenario" element={<NewScenario />} />
            <Route path="/scenario/:scenarioId" element={<ScenarioPage />} />
            <Route
              path="/alternativeScenarios/"
              element={<AlternativeScenarios />}
            />
            <Route path="/legalportion" element={<LegalPortion />} />

            <Route path="/myaccount" element={<MyAccount />} />
            <Route path="/myaccount/changePassword" element={<PassChange />} />
            <Route path="/myaccount/plan" element={<MyPlan />} />

            <Route path="/scenarioFile" element={<ScenarioFile />} />

            <Route path="/contact" element={<Contact />} />
            <Route path="/videoCall" element={<VideoCall />} />
          </Route>

          {/* ACCESS FOR PREMIUM USERS ONLY */}
          <Route element={<ProtectedRoute accessBy="premium" user={user} />}>
            <Route
              path="/scenario/edit/:scenarioId"
              element={<EditScenario />}
            />
            <Route path="/scenariosbase" element={<ClientBase />} />
            <Route path="/person/:personId" element={<ClientPage />} />
          </Route>

          {/* ACCESS FOR CONSULTANTS ONLY */}
          <Route element={<ProtectedRoute accessBy="consultant" user={user} />}>
            <Route path="/consultant" element={<ContactWaiting />} />
          </Route>

          {/* ACCESS FOR ENTERPRISE ADMINS USERS ONLY */}
          <Route element={<ProtectedRoute accessBy="enterprise" user={user} />}>
            <Route path="/enterprise/users" element={<EnterpriseUsers />} />
          </Route>

          {/* ACCESS FOR PROFESSIONAL ACCOUNTS ONLY */}
          <Route
            element={<ProtectedRoute accessBy="professional" user={user} />}
          >
            <Route path="/plans/enterprise" element={<ChooseEnterprise />} />
          </Route>

          {/* ACCESS FOR ALL USERS */}
          <Route path="/scenario/pdf/:scenarioId" element={<ScenarioPdf />} />

          <Route path="/auth/social/:provider" element={<SocialAuthPage />} />

          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
      <NotificationsGlobal />
      {!isCookieAccepted && <CookieConsent />}
    </BrowserRouter>
  );
}
