import postRequest from "../../requests/postRequest";

export const subEnterpriseExtendService = async (
  numberOfUsers?: number
) => {
  const result = await postRequest({
    url: "/subscription/enterprise/extend",
    params: {
        amount: numberOfUsers
    },
  });
  return result;
};
