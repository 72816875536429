import React, { useState, useEffect, useRef } from 'react';

// components
import PageWrap from '../../components/layout/PageWrap';
import RenderSteps from "./steps/Steps";
import PopupWealth from './components/PopupWealth';
import { useDispatch } from 'react-redux';
import { clearScenario } from '../../redux/reducers/scenarioSlice';
import { checkScenarioLimit } from '../../client/AppClient';
import LoadingSpinner from '../../components/specific/LoadingSpinner';
import LimitAnnouncement from './components/LimitAnnouncement';



function NewScenario() {
    const [formIndex, setFormIndex] = useState(1);
    const [popupWealthInfo, setPopupWealthInfo] = useState(false);
    const [scenarioChecked, setScenarioChecked] = useState(null);
    const effectCalled = useRef<any>(null);
    const dispatch = useDispatch();

    const handleCheckScenarioLimit = async () => {
      const response = await checkScenarioLimit();
      if (response.status !== 200) return;
      setScenarioChecked(response.data.canCheck);
    };

    useEffect(() => {
      const handleClearRedux = () => {
        effectCalled.current = true;
        dispatch(clearScenario());
      }
      if(!effectCalled.current) {
        handleClearRedux();
        handleCheckScenarioLimit();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const handleNextCaseStep = (target: number = 1) => {
      setFormIndex((prev) => prev + target);
    };

    if(scenarioChecked === null) return <LoadingSpinner />

    if(scenarioChecked === false) return <LimitAnnouncement />
    
    return (
      <>
        <PopupWealth
          popupOpened={popupWealthInfo}
          setPopupOpened={setPopupWealthInfo}
        />
        <PageWrap>
          {formIndex === 6 ? (
            <RenderSteps
              index={formIndex}
              handleNextCaseStep={handleNextCaseStep}
            />
          ) : (
            <div className="w-full max-w-xl px-4 mx-auto ">
              <RenderSteps
                index={formIndex}
                handleNextCaseStep={handleNextCaseStep}
                setPopupWealthInfo={setPopupWealthInfo}
              />
            </div>
          )}
        </PageWrap>
      </>
    );
}

export default NewScenario;