import React from 'react';
import PageWrap from '../../components/layout/PageWrap';
import CarouselStartPage from './components/CarouselStartPage';
import Button from '../../components/common/button/Button';
import { Link } from 'react-router-dom';
import SocialAuth from '../../components/auth/SocialAuth';


function App() {
  return (
    <div className="md:h-screen content-center my-0 py-0">
      <PageWrap>
        <div className="m-auto">
          <CarouselStartPage />
        </div>
        <div className="flex justify-center w-full max-w-sm px-4 mx-auto mb-10 -mt-8">
          <SocialAuth />
        </div>
        <div className="m-auto">
          <Link to="/register">
            <Button>Zarejestruj się</Button>
          </Link>
        </div>

        <div className="py-5 m-auto">
          <div className="text-darkGrey text-sm">
            Posiadasz już konto?
            <span className="text-sherpaBlue font-semibold">
              <Link to="/login"> Zaloguj się</Link>
            </span>
          </div>
        </div>
      </PageWrap>
    </div>
  );
}

export default App;