import { useNavigate } from "react-router-dom";
import Button from "../../../components/common/button/Button";
import Popup from "../../../components/specific/Popup";

const FactureDetailsPopup = ({
  popupTrigger,
  setPopupTrigger,
  accountType,
  handleStartPremiumTransaction,
}: any) => {
  const navigate = useNavigate();

  const handleClose = (bool: boolean) => {
    setPopupTrigger({
      opened: bool,
      title: "Pakiet premium",
      type: "statement",
    });
  }

  const StatementContent = () => {
    if (accountType !== "PRIVATE") return null;
    return (
      <div className="flex flex-col gap-5">
        <span className="text-center text-darkGrey">
          Żądam rozpoczęcia świadczenia płatnej usługi przed upływem terminu do
          odstąpienia od umowy oraz przyjmuję do wiadomości, że w przypadku
          odstąpienia od umowy mam obowiązek zapłaty za świadczenia zrealizowane
          na podstawie umowy do chwili odstąpienia od umowy oraz utracę prawo
          odstąpienia od umowy z chwilą pełnego wykonania usługi.
        </span>
        <div className="flex flex-row gap-5 justify-center">
          <Button
            bgColor="transparent"
            textColor="darkBlue"
            onClick={() => handleClose(false) }
          >
            Zamknij
          </Button>
          <Button onClick={handleStartPremiumTransaction}>Akceptuję</Button>
        </div>
      </div>
    );
  };

  const FactureContent = () => {
    return (
      <div className="flex flex-col gap-5">
        <span className="text-center text-darkGrey">
          Aby zakupić pakiet premium konieczne jest wypełnienie danych do
          faktur.
        </span>
        <div className="flex flex-row gap-5 justify-center">
          <Button
            bgColor="transparent"
            textColor="darkBlue"
            onClick={() => handleClose(false)}
          >
            Zamknij
          </Button>
          <Button onClick={() => navigate("/myaccount")}>Ustawienia</Button>
        </div>
      </div>
    );
  };

  return (
    <Popup
      title={popupTrigger.title}
      trigger={popupTrigger.opened}
      setTrigger={handleClose}
    >
      {popupTrigger.type === "statement" ? <StatementContent /> : <FactureContent />}
    </Popup>
  );
};

export default FactureDetailsPopup;