import React, { useState, useEffect, useRef } from "react";
import ResultSuccess from "./components/ResultSuccess";
import ResultFailure from "./components/ResultFailure";
import ResultContactLawyer from "./components/ResultContactLawyer";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getScenario } from "../../../../redux/reducers/scenarioSlice";
import LoadingSpinner from "../../../../components/specific/LoadingSpinner";
import displayError from "../../../../utils/handling-errors/displayError";
import { getScenarioResult } from "../../../../client/AppClient";
import { setScenarioResult } from "../../../../redux/reducers/scenarioResultSlice";

function ScenarioSuccess() {
  const navigate = useNavigate();
  const scenario = useSelector(getScenario);
  const [loading, setLoading] = useState(true);
  const [resultType, setResultType] = useState("");
  const [scenarioId, setScenarioId] = useState("");
  const dispatch = useDispatch();
  const effectCalled = useRef<any>(null);
  const { state } = useLocation();

  const parseAnswersToLocalStorage = () => {
    const answers = {
      choiceAnswers: scenario?.choiceAnswers,
      numericAnswers: scenario?.numericAnswers,
      numericListAnswers: scenario?.numericListAnswers,
    };
    localStorage.setItem("scenarioAnswers", JSON.stringify(answers));
  }

  useEffect(() => {
    const handleGetScenarioResult = async () => {
      effectCalled.current = true;
      const response: any = await getScenarioResult(scenario);
      if (response.status !== 200) return [displayError(dispatch, response), setLoading(false), setResultType("FAILURE")];
      dispatch(setScenarioResult(response.data));
      parseAnswersToLocalStorage();
      setScenarioId("result")
      if(response.data.errors !== undefined) setResultType("CONTACT_LAWYER");
      else setResultType("SUCCESS");
      setLoading(false);
    };
    if(scenario && !effectCalled.current) handleGetScenarioResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigate = () => {
    let target: any = 0;
    if(scenarioId.length > 0) target = `/scenario/${scenarioId}`;
    if(state) navigate(target, { state: { clientId: state.clientId, clientName: state.clientName } });
    else navigate(target);
  };

  if (loading) return <LoadingSpinner />;
  if (resultType === "SUCCESS") return <ResultSuccess handleNavigate={handleNavigate}/>
  if (resultType === "FAILURE") return <ResultFailure handleNavigate={handleNavigate}/>
  if (resultType === "CONTACT_LAWYER") return <ResultContactLawyer/>
  return <></>


}

export default ScenarioSuccess;
