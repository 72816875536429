import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { requestContact } from "../../../client/AppClient";
import Button from "../../../components/common/button/Button";
import displayError from "../../../utils/handling-errors/displayError";
import displaySuccess from "../../../utils/handling-success/displaySuccess";


function ContactNotFound({ setFailedConnection, selectedConsultant }: any) {
  const [requestContactSent, setRequestContactSent] = useState(false);
  const dispatch = useDispatch();

  const handleRemindLater = async () => {
    const response = await requestContact(selectedConsultant);
    if (response.status !== 200) return displayError(dispatch, response);
    setRequestContactSent(true);
    return displaySuccess(dispatch, "Sukces", "Wysłano wiadomość administracji");
  };

  if (requestContactSent) {
    return (
      <div>
        <h2 className="text-lg font-semibold text-darkBlue">
          Zgłoszenie zostało wysłane administracji
        </h2>
        <p className="text-darkGrey my-4">
          Skontaktujemy się z Tobą najszybciej, jak to będzie możliwe...
        </p>
        <div className="flex flex-col gap-6 mt-10">
          <Button
            textColor="darkBlue"
            bgColor="transparent"
            onClick={() => setFailedConnection(false)}
          >
            Powrót
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-lg font-semibold text-darkBlue">
        Niestety, w tym momencie nie udało nam się znaleźć dostępnego
        specjalisty...
      </h2>
      <p className="text-darkGrey my-4">
        Spróbuj ponownie później lub zostaw nam swój kontakt, a odezwiemy się do
        Ciebie w możliwie jak najszybszym czasie!
      </p>
      <div className="flex flex-col gap-6 mt-10">
        <Button onClick={handleRemindLater}>Powiadom mnie</Button>
        <span className="text-darkGrey text-center">LUB</span>
        <Button
          textColor="darkBlue"
          bgColor="transparent"
          onClick={() => setFailedConnection(false)}
        >
          Spróbuj ponownie później
        </Button>
      </div>
    </div>
  );
}

export default ContactNotFound;
