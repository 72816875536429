import React from "react";
import classNames from "classnames";

import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

interface InputInterface {
  name: string;
  placeholder?: any;
  value?: string | number;
  required?: boolean;
  onChange?: any;
  inputClassName?: string;
  divClassName?: string;
  maxLength?: any;
  allowNegative?: boolean;
  disabled?: boolean;
}

const WealthInput = ({
  name,
  placeholder,
  value,
  inputClassName,
  divClassName,
  required,
  allowNegative=false,
  ...rest
}: InputInterface) => {

    const defaultMaskOptions = {
      prefix: "",
      suffix: " zł",
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ".",
      allowDecimal: true,
      decimalSymbol: ",",
      decimalLimit: 2, // how many digits allowed after the decimal
      integerLimit: 15, // limit length of integer numbers
      allowNegative: allowNegative,
      allowLeadingZeroes: false,
    };


    const currencyMask = createNumberMask({
      ...defaultMaskOptions
    });
  return (
    <div
      className={classNames(
        "bg-light relative flex flex-col text-left",
        divClassName
      )}
    >
      <MaskedInput
        mask={currencyMask}
        className={classNames(
          "bg-transparent text-sm font-medium placeholder-lightGrey text-sherpaBlue block w-full py-4 px-4 focus-visible:outline-none",
          inputClassName
        )}
        id={name}
        name={name}
        type={"text"}
        value={value}
        placeholder={placeholder}
        {...rest}
        required
      />
    </div>
  );
};

WealthInput.defaultProps = {
  divClassName: "mt-4",
};

export default WealthInput;
