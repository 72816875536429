import React from "react";
import classNames from "classnames";

interface InputInterface {
  label: string;
  name: string;
  type: string;
  placeholder?: any;
  value?: string | number;
  required?: boolean;
  onChange?: any;
  inputClassName?: string;
  divClassName?: string;
  maxLength?: any;
  onKeyPress?: any;
  error?: any;
}

const InputBordered = ({
  label,
  name,
  type,
  placeholder,
  value,
  inputClassName,
  divClassName,
  required,
  error,
  ...rest
}: InputInterface) => {
  return (
    <div
      className={classNames(
        `relative flex flex-col ${error ? "input-error" : ""}`,
        divClassName
      )}
      data-content={error}
    >
      <label htmlFor={name} className="text-sm text-darkGrey font-medium">
        {label}
      </label>
      <input
        className={classNames(
          `bg-transparent border ${
            error ? "border-error" : "border-lightGrey"
          } rounded text-sm font-medium placeholder-lightGrey text-sherpaBlue block w-full py-3 px-4 focus-visible:outline-none`,
          inputClassName
        )}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        {...rest}
        required={required}
      />
      {/* {error && (
        <p className="text-error text-sm font-semibold pl-2">{error}</p>
      )} */}
    </div>
  );
};

InputBordered.defaultProps = {
  inputClassName: "mt-4",
};

export default InputBordered;
