import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import 'tw-elements';

// redux
import { store } from './redux/store'
import { Provider } from 'react-redux'

// routing
import { Router } from './routing/Router';
import { UserAuthContextProvider } from './context/UserAuthContext';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <UserAuthContextProvider>
        <Router />
      </UserAuthContextProvider>
    </Provider>
  </React.StrictMode>
);
