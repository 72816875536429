function waitForResolutionHeight(data: any) {
  return new Promise<void>((resolve) => {
    const checkHeight = () => {
      if (data.videoTrack.getStats().receiveResolutionHeight !== 0) {
        resolve();
      } else {
        setTimeout(checkHeight, 100); // check again in 100ms
      }
    };
    checkHeight();
  });
}

export default waitForResolutionHeight;