const personsAble = ["dziecko", "wnuk", "prawnuk", "rodzic", "ojciec", "matka", "małżonek", "małżonka"];

interface IPersons {
    person: string;
    part: number;
}


function getLegalPortionPersons(persons: IPersons[]): IPersons[] {
  return persons.filter((item) => {
    const personWords = item.person.split(' ');
    return personWords.length <= 2 && personsAble.some((check) => item.person.toLowerCase().includes(check.toLowerCase()));
  });
}

export default getLegalPortionPersons;