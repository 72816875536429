const plansData = {
    features: [
        {
        name: "Sprawdzanie dziedziczenia ustawowego",
        base: true,
        premium: true,
    },
    {
        name: "Podpowiedzi scenariuszy alternatywnych",
        base: true,
        premium: true,
    },
    {
        name: "Wzory testamentów",
        base: true,
        premium: true,
    },
    {
        name: "Tworzenie baz scenariuszy",
        base: false,
        premium: true,
    },
    {
        name: "Brak limitu scenariuszy",
        base: false,
        premium: true,
    },
    {
        name: "Wgląd w artykuły prawne",
        base: false,
        premium: true,
    },
    {
        name: "Możliwość edycji wyniku",
        base: false,
        premium: true,
    },
    {
        name: "Zapis dokumentacji do PDF",
        base: false,
        premium: true,
    },
    {
        name: "Opiekun Klienta",
        base: false,
        premium: true,
    },
],
    price: {
        name: "Abonament trzydziestodniowy",
        base: "0 zł",
        premium: "59 zł",
    },
};

export default plansData;