import React from "react";
import ValidationButton from "../../../components/specific/ValidationButton";

interface FormInterface {
  id?: string;
  submitBtn?: any;
  onSubmit?: any;
  valid: boolean;
  children: JSX.Element | JSX.Element[];
}

function VerificationForm({ id, submitBtn, onSubmit, children, valid }: FormInterface) {
  return (
    <form
      id={id}
      className="pt-1 "
      onSubmit={(e: any) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className="mb-4">{children}</div>
      <div className="text-center pt-1 mt-12 mb-4 pb-1">
        <ValidationButton type="submit" valid={valid} id={id+"Button"}>
          {submitBtn}
        </ValidationButton>
      </div>
    </form>
  );
}

export default VerificationForm;
