import React from "react";
import { ReactComponent as FailureImg } from "../../../assets/images/failure.svg";
import Header from "../../../components/common/text/Header";
import Button from "../../../components/common/button/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";


function LimitAnnouncement() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const navigateToPlans = () => {
    navigate("/plans", { state: { navigatedFrom: location.pathname } });
  }

  return (
    <div className="w-full my-16">
      <div className="flex flex-col text-center items-center">
        <FailureImg className="w-full h-auto max-w-lg" />
        <div className="mt-14">
          <Header>Twój limit scenariuszy się wyczerpał</Header>
          <p className="text-darkGrey text-lg my-5">
            Limit scenariuszy dla pakietu podstawowego wynosi 2 razy w miesiącu.{" "}
            <br />
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-center items center gap-4 mt-8 w-full max-w-lg mx-auto px-4 sm:px-0">
          <Link to="/" className=" md:w-1/2">
            <Button bgColor="transparent" textColor="dark-blue" size="full">
              Powrót
            </Button>
          </Link>
          <span className="md:w-1/2" onClick={navigateToPlans}>
            <Button size="full">Zmień pakiet</Button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default LimitAnnouncement;
