import postRequestNoAuth from "../../requests/postRequestNoAuth";

interface passwordResetService {
  email: string;
  token: string;
  password: string;
  repassword: string;
}

export const sendPasswordReset = async (body: passwordResetService) => {
  const result = await postRequestNoAuth({
    url: "/user/update/password",
    params: {
      token: body.token,
      password: body.password,
      repassword: body.repassword,
    },
  });
  return result;
};
