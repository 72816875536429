import React, {
  useRef,
  useState,
  useEffect,
} from "react";
import InputDigit from "./InputDigit";

const fillArray = (length: any) => Array.from({ length }).map(() => "");

export const SmsCodeGroup = ({
  numberOfInputs,
  code,
  setCode,
}: any) => {
  const inputRefs = useRef<any>([]);
  const [inputValues, setInputValues] = useState(fillArray(numberOfInputs));
  const [codeLength, setCodeLength] = useState(0);

  useEffect(() => {
    if (codeLength === numberOfInputs) {
      setCode(inputValues.join(""));
    }
    if (codeLength < numberOfInputs && code.length === numberOfInputs) {
      setCode("");
    }
  }, [code, codeLength, numberOfInputs, inputValues, setCode]);

  const handleKeyDown = (e: any, index: number) => {
    if (
      e.key === "Backspace" &&
      index - 1 > -1 &&
      (e.target as HTMLInputElement).value === ""
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleOnChange = (index: number) => {
    const thisInputValue = inputRefs.current[index].value;
    if (thisInputValue.length === 1 && index < numberOfInputs) {
      if (codeLength < codeLength + 1) {
        setCodeLength(codeLength + 1);
      }
    } else if (thisInputValue.length === 0) {
      setCodeLength(codeLength - 1);
    }
    if (thisInputValue.length === 1 && index + 1 < numberOfInputs) {
      inputRefs.current[index + 1].focus();
      return;
    }
  }

  const handleSetValue = (value: any, index: any) => {
    const inputValuesClone = [...inputValues];
    inputValuesClone[index] = value;
    setInputValues(inputValuesClone);
  }

  return (
    <div className="flex flex-row gap-2 justify-center">
      {Array.from({ length: numberOfInputs }).map((_, index) => {
        return (
          <InputDigit
            key={`code-input-${index}`}
            inputValue={inputValues[index]}
            inputRef={(el: any) => (inputRefs.current[index] = el)}
            setInputValue={(value: any) => handleSetValue(value, index)}
            onChange={() => handleOnChange(index)}
            handleKeyDown={(e: any) => handleKeyDown(e, index)}
          />
        );
      })}
    </div>
  );
};
