import React from 'react';
import Form from '../common/forms/Form';
import Header from '../common/text/Header';

import { ReactComponent as AnchorIcon } from "../../assets/icons/Anchor.svg";

const maxSteps = 2;

interface WealthStepInterface {
  title: string;
  step: 1 | 2;
  children: any;
  onSubmit: Function;
  disabled?: boolean;
}

function WealthStep({ title, step, children, onSubmit, disabled }: WealthStepInterface) {
  const StepHighlight = () => {
    if (step === 1) {
      return (
        <div className="flex flex-row w-full justify-between gap-2 my-6">
          <div className="w-1/2 bg-gold h-1 rounded-lg"></div>
          <div className="w-1/2 bg-darkGrey h-1 rounded-lg"></div>
        </div>
      );
    } else if (step === 2) {
      return (
        <div className="flex flex-row w-full justify-between gap-2 my-6">
          <div className="w-1/2 bg-gold h-1 rounded-lg"></div>
          <div className="w-1/2 bg-gold h-1 rounded-lg"></div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="sm:bg-white sm:shadow-container rounded-lg sm:p-12 mt-5">
      <div className="flex flex-col">
        <Header>
          {title} {step} / {maxSteps}
        </Header>
        <StepHighlight />
        <Form
          onSubmit={onSubmit}
          disabled={disabled}
          submitBtn={
            <span className="flex items-center gap-3">
              Przejdź dalej
              <AnchorIcon height={13} className="whitePath" />{" "}
            </span>
          }
        >
          {children}
        </Form>
      </div>
    </div>
  );
}

WealthStep.defaultProps = {
  disabled: true,
};


export default WealthStep;