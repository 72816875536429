import React from "react";
// import { useDispatch } from "react-redux";
import Button from "../../../components/common/button/Button";
import Header from "../../../components/common/text/Header";
import PageWrap from "../../../components/layout/PageWrap";
// import { changeAccountType } from "../../../client/AppClient";
// import displayError from "../../../utils/handling-errors/displayError";
// import displaySuccess from "../../../utils/handling-success/displaySuccess";


function AccountType({ setAccountType }: any) {
  // const dispatch = useDispatch();

  const handleAccountChange = async (type: string) => {
    // const response = await changeAccountType(type);
    // if (response.status !== 200) return displayError(dispatch, response);
    // displaySuccess(dispatch, "Sukces", "Zmieniono typ konta");
    setAccountType(type);
  };

  return (
    <PageWrap>
      <div className="w-full max-w-4xl md:px-4 mx-auto pt-24 text-center">
        <Header>Do jakich celów planujesz użyć aplikacji?</Header>
        <div className="flex flex-col md:flex-row justify-center items center gap-4 mt-12 px-5">
          <Button py="4" onClick={() => handleAccountChange("PRIVATE")}>
            Do celów prywatnych
          </Button>
          <Button py="4" onClick={() => handleAccountChange("PROFESSIONAL")}>
            Do celów zawodowych
          </Button>
        </div>
      </div>
    </PageWrap>
  );
}

export default AccountType;
