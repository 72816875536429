const getVideoDimensions = (videoTrack: any) => {
  const browserHeight = window.innerHeight;

  const videoHeight = videoTrack._videoHeight;
  const videoWidth = videoTrack._videoWidth;

  const ratio = browserHeight / videoHeight;

  const displayWidth = videoWidth * ratio;
  const displayHeight = videoHeight * ratio;
  return {
    height: displayHeight,
    width: displayWidth,
  };
};

export default getVideoDimensions;