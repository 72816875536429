import React from 'react';

const getFormattedDate = (parsedDate: string) => {
  const date = new Date(parsedDate);
  const today = new Date().getTime();
  let day = date.toLocaleString("default", { day: "2-digit" });
  let month = date.toLocaleString("default", { month: "2-digit" });
  let year = date.toLocaleString("default", { year: "numeric" });

  if (date.getTime() < today) {
    let formattedDate = day + "." + month + "." + year + " (+3 dni)";
    return formattedDate;
  }
  let formattedDate = day + "." + month + "." + year;
  return formattedDate;
};

function ExpirationDate( { planDetails }: any) {
    if (!planDetails.expirationDate) return null;
    return (
      <div className="flex flex-row justify-between items-center mb-4">
        <span className="text-darkGrey">Data wygaśnięcia:</span>
        <span className="text-sherpaBlue">
          {planDetails.expirationDate
            ? getFormattedDate(planDetails.expirationDate)
            : ""}
        </span>
      </div>
    );
}

export default ExpirationDate;