import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {ReactComponent as AnchorIcon} from "../../../assets/icons/Anchor.svg";
import {ReactComponent as PlusIcon} from "../../../assets/icons/Plus.svg";
import { getScenarioList } from '../../../client/AppClient';
import Button from '../../../components/common/button/Button';
import getFormattedDate from '../../../utils/dates/formatDate';
import displayError from '../../../utils/handling-errors/displayError';


function Scenarios({ id, clientName }: any) {
  const [scenarios, setScenarios] = useState([]);
  const effectCalled = useRef<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigateToScenario = (id: number) => {
    navigate(`/scenario/${id}`);
  };

  const fetchScenarios = async () => {
    const response: any = await getScenarioList(id);
    if (response.status !== 200) return displayError(dispatch, response);
    setScenarios(response.data);
  };

  useEffect(() => {
    if (effectCalled.current) return;
    fetchScenarios();
    effectCalled.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col">
      {scenarios.length === 0 ? (
        <p className="text-sherpaBlue">Brak zapisanych scenariuszy</p>
      ) : (
        scenarios?.map((el: any, index: number) => {
          return (
            <div
              className="flex flex-row justify-between items-center border-b-2 border-light px-1 py-5"
              key={index}
            >
              <div className="flex flex-col gap-1 items-start md:flex-row md:gap-3 md:items-center">
                <span
                  className="text-darkBlue cursor-pointer"
                  onClick={() => handleNavigateToScenario(el.id)}
                >
                  {el.name}
                </span>
                <span className="text-darkGrey">
                  {getFormattedDate(el.createDate)}
                </span>
              </div>
              <AnchorIcon
                className="cursor-pointer"
                onClick={() => handleNavigateToScenario(el.id)}
              />
            </div>
          );
        })
      )}
      <div className="flex justify-center mt-10">
        <Link
          to="/newscenario"
          state={{ clientId: id, clientName: clientName }}
        >
          <Button>
            <PlusIcon /> {""} Dodaj scenariusz
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default Scenarios;