import React, { useState, useEffect, useRef } from "react";
import AgoraRTC, { ClientConfig } from "agora-rtc-sdk-ng";
import Videos from "./components/Videos";
import {
  createCameraVideoTrack,
  createMicrophoneAudioTrack,
} from "agora-rtc-react";
import Controls from "./components/Controls";
import AudioOnly from "./components/AudioOnly";
import { getUserRole } from "../../redux/reducers/userSlice";
import { useSelector } from "react-redux";
import { getRtcFromLocalStorage, removeRtcFromLocalStorage } from "./helpers/agoraLocalStorage";
import { refreshAgoraToken } from "../../client/AppClient";
import CallInfo from "./components/CallInfo";

import JoinSound from "../../assets/audio/joinaudio.mp3";

const config: ClientConfig = { mode: "rtc", codec: "vp8" };

const useMicrophone: any = createMicrophoneAudioTrack();
const useCamera: any = createCameraVideoTrack();

const options: any = {
  appid: process.env.REACT_APP_AGORA_ID,
  channel: null,
  token: null,
  uid: 0,
};

const checkReady = (devices: any[]) => {
  if (
    (devices[0].ready && devices[1].ready) ||
    (devices[0].ready && devices[1].error !== null)
  ) {
    return true;
  }
  return false;
};

function VideoCall() {
  const [localTracks] = useState<any>({
    videoTrack: null,
    audioTrack: null,
  });
  const [remoteUser, setRemoteUser] = useState<any>({});
  const [remoteUserAudio, setRemoteUserAudio] = useState(false);
  const [start, setStart] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [userLeft, setUserLeft] = useState(false);
  const [noData, setNoData] = useState(false);

  const effectCalled = useRef<any>(null);
  const userRole = useSelector(getUserRole);

  const microphone = useMicrophone();
  const camera = useCamera();
  const devices = [microphone, camera];

  const ready = checkReady(devices);

  const client = AgoraRTC.createClient(config);

  const getAccessData = () => {
    const agoraData = getRtcFromLocalStorage();
    if(agoraData === null) return setNoData(true);
    options.channel = agoraData.channelId;
    options.token = agoraData.token;
    options.uid = agoraData.uid;
  };

  useEffect(() => {
    if (effectCalled.current) return;
    getAccessData();
    effectCalled.current = true;
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function init() {
      await join();
    }
    if (ready && !noData) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  // function for checking if all users are in room, if not automatically leave after 30 seconds
  useEffect(() => {
    let throbber: any, timer = 0;
    if(userRole !== "CONSULTANT" && Object.keys(remoteUser).length === 0 && remoteUserAudio === false) {
      throbber = setInterval(() => {
        timer++;
        if (timer === 30) return leave();
      }, 1000);
    } else {
      clearInterval(throbber)
      timer = 0;
    };
    return () => {clearInterval(throbber); timer = 0};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteUser, remoteUserAudio])

  const join = async () => {
    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserLeft);
    client.on("token-privilege-will-expire", handleRefreshToken);
    // case 1 - microphone and camera are available
    if (microphone.ready && camera.ready) {
      [options.uid, localTracks.audioTrack, localTracks.videoTrack] =
        await Promise.all([
          client.join(
            options.appid,
            options.channel,
            options.token || null,
            options.uid || null
          ),
          AgoraRTC.createMicrophoneAudioTrack({
            encoderConfig: "music_standard",
          }),
          AgoraRTC.createCameraVideoTrack(),
        ]);

      await client.publish(Object.values(localTracks));
      setStart(true);
    } else {
      // case 2 - only microphone is available
      [options.uid, localTracks.audioTrack] = await Promise.all([
        client.join(
          options.appid,
          options.channel,
          options.token || null,
          options.uid || null
        ),
        AgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: "music_standard",
        }),
      ]);

      await client.publish([localTracks.audioTrack]);
      setStart(true);
    }
  };

  const leave = async () => {
    setStart(false);
    setIsDisconnected(true);
    for (const trackName in localTracks) {
      const track = localTracks[trackName];
      if (track) {
        track.stop();
        track.close();
        track.removeAllListeners();
        localTracks[trackName] = undefined;
      }
    }
    setRemoteUser({});
    client.removeAllListeners();
    await client.leave();
    removeRtcFromLocalStorage();
    if (userRole === "CONSULTANT") {
      window.location.href = "/consultant";
    } else {
      window.location.href = "/contact";
    }
  };

  const subscribe = async (user: any, mediaType: any) => {
    await client.subscribe(user, mediaType);
    if (mediaType === "video") {
      setRemoteUser(user);
      user.videoTrack?.play(`remotePlayer-${user.uid}`);
    }
    if (mediaType === "audio") {
      user.audioTrack?.play();
      if (
        Object.keys(remoteUser).length === 0 &&
        user.videoTrack === undefined
      ) {
        setRemoteUserAudio(true);
      }
    }
  };

  const handleUserLeft = () => {
    console.log("===========================")
    console.log("user left")
    console.log("===========================")
    setRemoteUser({});
    setRemoteUserAudio(false);
    setUserLeft(true);
  };

  const handleUserPublished = (user: any, mediaType: any) => {
    const rtcClient: any = client;
    if (rtcClient?._users.length > 1) {
      return leave();
    }
    subscribe(user, mediaType);
    // play audio when someone joined
    new Audio(JoinSound).play();
  };

  const handleRefreshToken = async () => {
    const response: any = await refreshAgoraToken(options.channel);
    if (response.status !== 200) return;
    await client.renewToken(response.data.token)
  }

  if(noData) {
    setTimeout(() => {
      leave();
    }, 1500)
    
    return (
      <div className="relative w-full sm:min-h-screen h-full bg-black">
        <p className="text-xl text-lightBlue text-center font-semibold absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
          Nie udało się dołączyć do kanału... zaraz nastąpi przekierowanie
        </p>
      </div>
    );
  }

  return (
    <div className="relative w-full min-h-full  h-full bg-black">
      {ready && localTracks && start && (
        <>
          <CallInfo client={client} />
          <Controls localTracks={localTracks} leave={leave} />
        </>
      )}
      {start && localTracks.videoTrack && (
        <Videos
          remoteUser={remoteUser}
          remoteUserAudio={remoteUserAudio}
          localTracks={localTracks}
          userLeft={userLeft}
        />
      )}
      {start && !localTracks.videoTrack && (
        <AudioOnly
          remoteUser={remoteUser}
          remoteUserAudio={remoteUserAudio}
          userLeft={userLeft}
        />
      )}
      {isDisconnected && !start && (
        <p className="text-xl text-lightBlue text-center font-semibold absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
          Rozłączanie... zaraz nastąpi przekierowanie
        </p>
      )}
    </div>
  );
}

export default VideoCall;
