import React from "react";
import classNames from "classnames";

import Input from "react-phone-number-input/input";


interface InputInterface {
  name: string;
  label: string;
  placeholder?: any;
  value?: string;
  setValue: Function;
  required?: boolean;
  onChange: any;
  inputClassName?: string;
  divClassName?: string;
  maxLength?: any;
  error?: any;
}

const defaultCountry = "+48 ";

const PhoneInput = ({
  name,
  label,
  placeholder,
  value,
  setValue,
  inputClassName,
  divClassName,
  required,
  error,
  ...rest
}: InputInterface) => {
  const parseDefaultCountry = () => {
    if(value?.length === 0 || !Boolean(value)) setValue(defaultCountry);
  }
  return (
    <div
      className={classNames(
        `formControl border-b relative flex flex-col text-left ${
          error ? "input-error border-b-error" : "border-b-lightGrey"
        }`,
        divClassName
      )}
      data-content={error}
    >
      <label htmlFor={name} className="text-sm text-darkGrey font-medium">
        {label}
      </label>
      <Input
        international
        className={classNames(
          "bg-transparent text-sm font-medium placeholder-lightGrey text-sherpaBlue block w-full pb-1.5 focus-visible:outline-none",
          inputClassName
        )}
        id={name}
        name={name}
        type={"text"}
        value={value}
        placeholder={placeholder}
        onFocus={parseDefaultCountry}
        {...rest}
        required
      />
      {/* {error && <p className="text-error text-sm font-semibold pl-2">{error}</p>} */}
    </div>
  );
};

PhoneInput.defaultProps = {
  inputClassName: "mt-4",
};

export default PhoneInput;
