import React, { useState, useEffect } from "react";
import { getTransactionsList } from "../../../client/AppClient";
import ShadowContentWrap from "../../../components/layout/ShadowContentWrap";
import Pagination from "../../../components/specific/Pagination";
import getFormattedDate from "../../../utils/dates/formatDate";

const status: any = {
  "PAID": "Opłacona",
  "PENDING": "Oczekiwanie",
  "FAILED": "Niepowodzenie"
}

function TransactionTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const itemsPerPage = 10;
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      const response = await getTransactionsList(currentPage, itemsPerPage);
      if (response.status !== 200) return;
      setTransactions(response.data.items);
      setTotalTransactions(response.data.total);
      setLoading(false);
    };
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  if (loading) return <></>;

  return (
    <ShadowContentWrap className="sm:p-14 mb-10">
      <div className="text-left mb-6">
        <span className="text-lg font-semibold text-darkBlue">
          Lista transakcji
        </span>
      </div>

      <table className="table-fixed border-collapse w-full mb-10">
        <tbody>
          {transactions.map((transaction: any, i: number) => {
            return (
              <tr key={i} className="border-b border-lightBlue ">
                <td className="flex flex-col md:table-cell md:w-3/5 py-4 text-darkBlue">
                  {transaction.description}
                </td>
                <td className="flex flex-col md:table-cell py-4">
                  <div
                    className={`flex flex-row items-center md:justify-center gap-3 
                  ${
                    transaction.status === "PAID" ? "text-green" :
                    transaction.status === "PENDING" ? "text-warning" :
                    "text-error" 
                  }
                  `}
                  >
                    {status[transaction.status]}
                  </div>
                </td>
                <td className="flex flex-col md:table-cell py-4 text-darkGrey">
                  <div className="flex flex-row items-center md:justify-end gap-3">
                    {getFormattedDate(transaction.createDate)}
                  </div>
                </td>
                <td className="flex flex-col md:table-cell py-4  text-darkGrey">
                  <div className="flex flex-row items-center md:justify-end gap-3">
                    {transaction.amount / 100} zł
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Pagination
        total={totalTransactions}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={(page: any) => setCurrentPage(page)}
      />
    </ShadowContentWrap>
  );
}

export default TransactionTable;
