import React from "react";
import classnames from "classnames";

interface ToggleInterface {
  checked?: true | false;
  disabled?: true | false;
  value?: any;
  onChange?: any;
  onClick?: any;
}

function Toggle({ checked, disabled, value, ...rest }: ToggleInterface) {
  const switchPositionDefault = {
    transform: "translateX(3px)",
  };
  const switchPositionChecked = {
    transform: "translateX(calc(50% + 9px))",
  };

  return (
    <>
      <label className="inline-block">
        <span
          className={classnames(
            "flex rounded-3xl cursor-pointer h-8 w-[55px] border border-darkBlue transition-all duration-200",
            {
              "bg-white": checked === false || disabled === true,
              "bg-darkBlue": checked === true,
            }
          )}
        >
          <input
            className="absolute opacity-0"
            value={value}
            defaultChecked={checked}
            type="checkbox"
            disabled={disabled}
            {...rest}
          />
          <span
            className="text-white flex items-center h-full relative transition-all duration-200"
            style={checked ? switchPositionChecked : switchPositionDefault}
          >
            <span
              className={classnames("rounded-full inline-block h-7 w-7 transition-all duration-200", {
                "bg-white": checked === true,
                "bg-darkBlue": checked === false,
              })}
            />
          </span>
        </span>
      </label>
    </>
  );
}

export default Toggle;
