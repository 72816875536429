import { conformToMask } from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
  prefix: "",
  suffix: " zł",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: " ",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 1000, // limit length of integer numbers
  allowNegative: true,
  allowLeadingZeroes: false,
};
const mask = createNumberMask(defaultMaskOptions);

const stringToCurrency = (string: string) => {
    return conformToMask(string, mask).conformedValue;
}

export default stringToCurrency;