import React, { useState, useEffect, useRef } from "react";
import ResultSuccess from "./components/ResultSuccess";
import ResultFailure from "./components/ResultFailure";
import ResultContactLawyer from "./components/ResultContactLawyer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../../components/specific/LoadingSpinner";
import displayError from "../../../../utils/handling-errors/displayError";
import { getScenarioResult, saveScenario } from "../../../../client/AppClient";
import { getEditScenario, getNewEditScenario } from "../../../../redux/reducers/scenarioEditSlice";

function ScenarioEditSuccess({ id }: any) {
  const navigate = useNavigate();
  const editScenario: any = useSelector(getEditScenario);
  const newEditScenario = useSelector(getNewEditScenario);
  const [loading, setLoading] = useState(true);
  const [resultType, setResultType] = useState("");
  const dispatch = useDispatch();
  const effectCalled = useRef<any>(null);

  const handleGetScenarioResult = async () => {
    const response: any = await getScenarioResult(newEditScenario);
    if (response.status !== 200) {
      displayError(dispatch, response);
      setResultType("FAILURE");
      return false;
    } else if (response.data.errors !== undefined) {
      setResultType("CONTACT_LAWYER");
      return false;
    }
    else return response.data;
  };

  useEffect(() => {
    const updateScenario = async () => {
      effectCalled.current = true;
      const newResult = await handleGetScenarioResult();
      if (!newResult) return setLoading(false);
      const data = {
        name: newEditScenario.name,
        divisionOfAssets: newResult.divisionOfAssets,
        wealth: newEditScenario.wealth,
        choiceAnswers: newEditScenario.choiceAnswers,
        numericAnswers: newEditScenario.numericAnswers,
        numericListAnswers: newEditScenario.numericListAnswers,
        result: {
          id: newResult.result.id,
        },
      };
      const response: any = await saveScenario(data, editScenario.clientId, id);
      if (response.status !== 200) 
        return [
          displayError(dispatch, response),
          setLoading(false),
        ];
      setResultType("SUCCESS");
      setLoading(false);
    };
    if (editScenario && newEditScenario && !effectCalled.current) updateScenario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNavigate = () => {
    navigate(`/scenario/${id}`);
  };

  if (loading) return <LoadingSpinner />;
  if (resultType === "SUCCESS")
    return <ResultSuccess handleNavigate={handleNavigate} />;
  if (resultType === "FAILURE")
    return <ResultFailure handleNavigate={handleNavigate} />;
  if (resultType === "CONTACT_LAWYER") return <ResultContactLawyer handleNavigate={handleNavigate}/>;
  return <></>;
}

export default ScenarioEditSuccess;
