import React, { useState } from "react";
import classNames from "classnames";
import { ReactComponent as PasswordHidden } from "../../../assets/icons/Password.svg";
import { ReactComponent as PasswordOpen } from "../../../assets/icons/PasswordOpen.svg";

interface InputInterface {
  label: string;
  name: string;
  type: string;
  placeholder?: any;
  value?: string | number;
  required?: boolean;
  onChange?: any;
  inputClassName?: string;
  divClassName?: string;
  maxLength?: any;
  error?: any;
  onKeyPress?: any;
}

const Input = ({
  label,
  name,
  type,
  placeholder,
  value,
  inputClassName,
  divClassName,
  required,
  error,
  ...rest
}: InputInterface) => {
  const [currentType, setCurrentType] = useState(type);

  const handleTypeChange = () => {
    if (currentType === "text") {
      setCurrentType("password");
    } else if (currentType === "password") {
      setCurrentType("text");
    }
  };

  return (
    <>
      <div
        className={classNames(
          `formControl border-b ${
            error ? `input-error border-b-error` : "border-b-lightGrey"
          } relative flex flex-col text-left`,
          divClassName
        )}
        data-content={error}
      >
        <label htmlFor={name} className="text-sm text-darkGrey font-medium">
          {label}
        </label>
        <input
          className={classNames(
            "bg-transparent  text-sm font-medium placeholder-lightGrey text-sherpaBlue block w-full pb-1.5 focus-visible:outline-none",
            inputClassName
          )}
          id={name}
          name={name}
          type={currentType}
          value={value}
          placeholder={placeholder}
          {...rest}
          required={required}
        />
        {type === "password" && (
          <div className="absolute bottom-[8%] right-[1%] cursor-pointer z-10">
            {currentType === "password" ? (
              <PasswordHidden onClick={handleTypeChange} height={20} />
            ) : (
              <PasswordOpen onClick={handleTypeChange} height={20} />
            )}
          </div>
        )}
      </div>
      {/* {error && (
        <p className="text-error text-sm font-semibold pl-2">{error}</p>
      )} */}
    </>
  );
};

Input.defaultProps = {
  inputClassName: "mt-4",
};

export default Input;
