import React, { useState, useEffect } from 'react';
import Logo from "./Logo";
import {ReactComponent as SettingsIcon} from "../../assets/icons/Settings.svg"
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';

function Navbar() {
    const [isOpened, setIsOpened] = useState(false);
    
    const handleClose = (e: any) => {
        if (e.target.id === "sidebarfixed") setIsOpened(false);
    }

    useEffect(() => {
      if (isOpened) {
        document.body.classList.add("hide-overflow");
      } else {
        document.body.classList.remove("hide-overflow");
      }
      return () => document.body.classList.remove("hide-overflow");
    }, [isOpened, setIsOpened]);

    return (
      <>
        <nav className="relative flex flex-row justify-between items-center w-full border-b-8 border-light px-4 sm:px-14 py-4">
          <div className="">
            <Link to="/home">
              <Logo width="200" />
            </Link>
          </div>
          <div className="p-2">
            <SettingsIcon
              className="cursor-pointer"
              onClick={() => setIsOpened((prev) => !prev)}
            />
          </div>
        </nav>
        <div
          id="sidebarfixed"
          className={`z-20 transition-all bg-black bg-opacity-40 fixed inset-0 bg-b ${
            isOpened ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
          onClick={handleClose}
        ></div>
        <Sidebar setIsOpened={setIsOpened} isOpened={isOpened} />
      </>
    );
}

export default Navbar;