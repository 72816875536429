import React from 'react';
import parse from "html-react-parser";
import { ReactComponent as AnchorIcon } from "../../../assets/icons/Anchor.svg";
import { useNavigate } from 'react-router-dom';


function DataTabs({ infoTabsData, categoryTitle, setPopupData }: any) {
  const navigate = useNavigate();

  const handleRenderPopup = (title: string, wysiwyg: string) => {
    setPopupData({
      opened: true,
      title: title,
      message: (
        <div className="adnotation w-full max-h-[400px] overflow-auto">
          {parse(wysiwyg)}
        </div>
      ),
    });
  };

  return (
    <div className="flex  flex-col shadow-small mb-4 md:mb-8 rounded w-full md:w-1/2">
      {infoTabsData?.map((tab: any, index: number) => {
        return (
          <div
            key={index}
            onClick={
              tab.fileId
                ? () =>
                    navigate("/scenarioFile", {
                      state: {
                        id: tab.fileId,
                        description: tab.text,
                        title: categoryTitle,
                        subtitle: tab.name,
                      },
                    })
                : () => handleRenderPopup(tab.title, tab.answer)
            }
            className="flex flex-row justify-between items-center px-4 py-6 border-b-2 border-light cursor-pointer"
          >
            <span
              className="text-darkBlue w-[95%] whitespace-nowrap overflow-hidden overflow-ellipsis"
              title={tab.title}
            >
              {tab.fileId ? `${tab.name}` : `${tab.title}`}
            </span>
            <AnchorIcon />
          </div>
        );
      })}
    </div>
  );
}

export default DataTabs;