import React from 'react';
import Input from '../../../components/common/forms/Input';
import { inputOnlyLetters } from '../../../validations/inputValidations';
import PhoneInput from '../../../components/common/forms/PhoneInput';

interface IRegisterInputs {
    email: string;
    setEmail: (value: string) => void;
    fullName: string;
    setFullName: (value: string) => void;
    phone: string;
    setPhone: any
    errors: any;
}

function RegisterInputs({ email, setEmail, fullName, setFullName, phone, setPhone, errors}: IRegisterInputs) {
    return (
        <>
            <Input
              label="E-mail"
              name="E-mail"
              type="text"
              placeholder="Wpisz e-mail"
              divClassName="my-8"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              error={errors?.email}
              required
            />
            <Input
              label="Imię i nazwisko"
              name="fullnames"
              type="text"
              placeholder="Wpisz imię i nazwisko"
              divClassName="my-8"
              value={fullName}
              onChange={(e: any) => setFullName(e.target.value)}
              onKeyPress={inputOnlyLetters}
              required
            />
            <PhoneInput
              label="Numer telefonu"
              name="phoneNumber"
              placeholder="Wpisz numer telefonu"
              divClassName={`mt-8 mb-4`}
              value={phone}
              setValue={setPhone}
              onChange={setPhone}
              error={errors?.phone}
              required
            />
        </>
    );
}

export default RegisterInputs;