import React from 'react';

function SubscriptionCost({ planDetails }: any) {
  if (!planDetails.expirationDate) return null;
  return (
    <div className="flex flex-row justify-between items-center mb-4">
      <span className="text-darkGrey">Abonament miesięczny:</span>
      <span className="text-sherpaBlue">
        {planDetails.totalPrice ? planDetails.totalPrice / 100 + " zł" : "0 zł"}
      </span>
    </div>
  );
}

export default SubscriptionCost;