import { StyleSheet, Font } from "@react-pdf/renderer";
import lato from "../../../assets/fonts/Lato-Regular.ttf";
import latoBold from "../../../assets/fonts/Lato-Bold.ttf";
import latoMedium from "../../../assets/fonts/Lato-Medium.ttf";
import latoItalic from "../../../assets/fonts/Lato-Italic.ttf";

Font.register({
  family: "Lato",
  src: lato,
  format: "truetype",
  fontWeight: "normal",
});
Font.register({
  family: "Lato",
  src: latoMedium,
  format: "truetype",
  fontWeight: "medium",
});
Font.register({
  family: "Lato",
  src: latoBold,
  format: "truetype",
  fontWeight: "bold",
});
Font.register({
  family: "Lato",
  src: latoItalic,
  format: "truetype",
  fontStyle: "italic",
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Lato",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: "white",
    padding: 40,
  },
  section: {
    position: "relative",
    marginVertical: 8,
  },
  header: {
    fontSize: 20,
    fontWeight: "bold",
    marginVertical: 2,
    color: "#18627F",
    textAlign: "left",
  },
  subheader: {
    fontSize: 15,
    fontWeight: "bold",
    marginVertical: 2,
    color: "#18627F",
    textAlign: "left",
  },
  title: {
    fontSize: 15,
    marginVertical: 2,
    color: "#4B4B4B",
    textAlign: "left",
  },
  description: {
    fontSize: 13,
    marginVertical: 2,
    color: "#18627F",
    textAlign: "left",
  },
  descriptionWrap: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  logoWrap: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  logo: {
    display: "flex",
    width: 170,
    alignSelf: "flex-end",
  },
  chartWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 30,
  },
  chart: {
    width: 350,
  },
  article: {
    fontSize: 11,
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 4,
    color: "#4B4B4B",
    textAlign: "left",
    zIndex: 10,
  },
  regulations: {
    fontSize: 11,
    marginVertical: 10,
    fontStyle: "italic",
    marginLeft: 21,
    color: "#4B4B4B",
    textAlign: "left",
  },
});

export default styles;