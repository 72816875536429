import React from 'react';

interface HeaderInterface {
    children: React.ReactNode;
}

function Header({ children }: HeaderInterface ) {
    return (
        <h1 className="text-2xl md:text-4xl text-darkBlue font-semibold">
            {children}
        </h1>
    );
}

export default Header;