import React from "react";
import ScenarioName from "./static-steps/ScenarioName";
import ScenarioWealth from "./static-steps/ScenarioWealth";
import ScenarioWealthStep1 from "./static-steps/ScenarioWealthStep1";
import ScenarioWealthStep2 from "./static-steps/ScenarioWealthStep2";
import ScenarioSuccess from "./final-step/ScenarioSuccess";
import ScenarioQuestion from "./api-step/ScenarioQuestion";

interface RenderStepsInterface {
  index: number;
  handleNextCaseStep: Function;
  setPopupWealthInfo?: Function;
}

const RenderSteps = ({
  index,
  handleNextCaseStep,
  setPopupWealthInfo,
}: RenderStepsInterface) => {
  switch (index) {
    case 1:
      return <ScenarioName handleNextCaseStep={handleNextCaseStep} />;
    case 2:
      return <ScenarioWealth handleNextCaseStep={handleNextCaseStep} />;
    case 3:
      return (
        <ScenarioWealthStep1
          handleNextCaseStep={handleNextCaseStep}
          setPopupWealthInfo={setPopupWealthInfo}
        />
      );
    case 4:
      return (
        <ScenarioWealthStep2
          handleNextCaseStep={handleNextCaseStep}
          setPopupWealthInfo={setPopupWealthInfo}
        />
      );
    case 5:
      return <ScenarioQuestion handleNextCaseStep={handleNextCaseStep} />;
    case 6:
      return <ScenarioSuccess />;
    default:
      return <></>;
  }
};

export default RenderSteps;
