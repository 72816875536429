import React, { useState, useEffect } from "react";

import PageWrap from "../../components/layout/PageWrap";
import ScenarioCarousel from "./components/ScenarioCarousel";
import CheckboxGroup from "./components/CheckboxGroup";
import Chart from "./components/Chart";
import PopupScenarioDetails from "../../components/specific/PopupScenarioDetails";
import PopupSaveScenario from "./components/PopupSaveScenario";

import { ReactComponent as DiscIcon } from "../../assets/icons/Disc.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";
import { ReactComponent as AnchorIcon } from "../../assets/icons/Anchor.svg";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getScenarioResult,
  setScenarioResult,
} from "../../redux/reducers/scenarioResultSlice";
import LoadingSpinner from "../../components/specific/LoadingSpinner";
import NoAccess from "../../components/layout/NoAccess";
import { getSavedScenario, saveScenario } from "../../client/AppClient";
import displayError from "../../utils/handling-errors/displayError";
import displaySuccess from "../../utils/handling-success/displaySuccess";
import PopupRestricted from "../../components/auth/PopupRestricted";
import { getUserSubscription } from "../../redux/reducers/userSlice";
import isNormalRestricted from "../../utils/isNormalRestricted";
import InfoTabs from "./components/InfoTabs";
import ContactWrap from "./components/ContactWrap";
import ExportPdfWrap from "./components/ExportPdfWrap";
import AlternativeScenariosWrap from "./components/AlternativeScenariosWrap";

function ScenarioPage() {
  const [data, setData] = useState<any>({});
  const [currentAdnotation, setCurrentAdnotation] = useState<any>({
    index: "",
    data: {},
  });
  const [popupSave, setPopupSave] = useState(false);
  const [popupData, setPopupData] = useState<any>({
    opened: false,
    title: "",
    message: "",
  });
  const [restricted, setRestrcited] = useState({
    opened: false,
    title: "",
  });
  const [loading, setLoading] = useState(true);

  const reduxScenario = useSelector(getScenarioResult);
  const subscription = useSelector(getUserSubscription);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { scenarioId } = useParams();
  const { state } = useLocation();

  const getDataFromLocalStorage = () => {
    const localStorageData = localStorage.getItem("scenarioDetails");
    if (localStorageData === null) return;
    dispatch(setScenarioResult(JSON.parse(localStorageData)));
    setData(JSON.parse(localStorageData));
    localStorage.removeItem("scenarioDetails");
  };

  const mergeDataWithAnswers = () => {
    const answers = localStorage.getItem("scenarioAnswers");
    if (answers !== null) {
      const mergedData = Object.assign(JSON.parse(answers), reduxScenario);
      setData(mergedData);
    } else setData(reduxScenario);
  };

  const getReduxData = () => {
    // if redirected from choosing plans access saved data from localStorage and delete it after saving to redux
    if (Object.keys(reduxScenario).length === 0) getDataFromLocalStorage();
    // if not redirected, access data from redux
    else mergeDataWithAnswers();
  };

  const handleSave = async (id: string) => {
    // api call to save scenario to user id
    const response: any = await saveScenario(data, id);
    if (response.status !== 200) return displayError(dispatch, response);
    displaySuccess(dispatch, "Sukces", "Zapisano scenariusz");
    if (response.data.id) navigate(`/scenario/${response?.data?.id}`);
  };

  const clickSaveButton = () => {
    if (isNormalRestricted(subscription))
      return setRestrcited({
        opened: true,
        title: "Tylko Użytkownik Premium może zapisać scenariusz",
      });
    if (scenarioId !== "result") return;
    if (state !== null) handleSave(state.clientId);
    else setPopupSave(true);
  };

  useEffect(() => {
    const fetchScenarioData = async () => {
      if (!scenarioId) return setLoading(false);
      const response = await getSavedScenario(scenarioId);
      if (response.status !== 200) return displayError(dispatch, response);
      setData(response.data);
      setLoading(false);
    };

    // if scenario result fresh after wizard get data from redux
    if (scenarioId === "result") {
      getReduxData();
      setLoading(false);
    }
    // if scenario result is saved to database fetch it from backend
    else fetchScenarioData();
    setPopupSave(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioId]);

  const handleRenderPopup = (title: string, message: string) => {
    setPopupData({
      opened: true,
      title: title,
      message: message,
    });
  };

  const handleRenderRegulationPopup = () => {
    if (isNormalRestricted(subscription))
      return setRestrcited({
        opened: true,
        title: "Tylko Użytkownik Premium może mieć podgląd do przepisów prawa",
      });
    handleRenderPopup("Przepis prawa", data?.result?.regulations);
  };

  const handleRemoveRestrictedPopup = () => {
    setRestrcited({
      opened: false,
      title: "",
    });
  };

  const ContentSlide = () => {
    const handleChangeAdnotation = (index: string, adnotation: any) => {
      if (currentAdnotation.index === index)
        return setCurrentAdnotation({ index: "", data: {} });
      setCurrentAdnotation({ index: index, data: adnotation.questions });
    };

    return (
      <div className="w-full h-full min-w-full inline-flex whitespace-pre-wrap px-6 md:px-14">
        <div className="flex flex-col">
          <span className="text-darkGrey">{data?.name}</span>
          <p className="font-semibold text-white text-lg mt-3 mb-10">
            {data?.result?.text}
          </p>
          {data.annotationList?.map((adnotation: any, index: number) => {
            return (
              <CheckboxGroup
                key={index}
                id={adnotation.id}
                group="scenarioadnotation"
                selected={currentAdnotation.index}
                value={`${index}`}
                onClick={(e: any) =>
                  handleChangeAdnotation(e.target.value, adnotation)
                }
              >
                {adnotation.name}
              </CheckboxGroup>
            );
          })}
        </div>
      </div>
    );
  };

  const ChartSlide = () => {
    return (
      <div className="w-full h-full min-w-full inline-flex justify-center items-start whitespace-pre-wrap">
        <Chart data={data?.divisionOfAssets} />
      </div>
    );
  };

  if (loading) return <LoadingSpinner />;
  else if (Object.keys(data).length === 0 && !loading) {
    return (
      <NoAccess
        title="Brak dostępu strony"
        description="Scenariusz nie został zapisany lub nie masz do niego dostępu"
      />
    );
  }

  const ScenarioIcon = () => {
    if (data.id) {
      return (
        <div className="flex flex-row items-center">
          <EditIcon
            className={"cursor-pointer whiteEditIcon"}
            onClick={() => navigate(`/scenario/edit/${data.id}`)}
          />
        </div>
      );
    }
    else {
      return (
        <div className="flex flex-row items-center gap-5">
          <span className="text-darkGrey">
            Nie zapisano
          </span>
          <DiscIcon className={"cursor-pointer"} onClick={clickSaveButton} />
        </div>
      );
    }
  }

  return (
    <>
      <PopupSaveScenario
        popupOpened={popupSave}
        setPopupOpened={setPopupSave}
        handleSave={handleSave}
      />
      <PopupScenarioDetails popupData={popupData} setPopupData={setPopupData} />
      <PopupRestricted
        popupOpened={restricted.opened}
        setPopupOpened={handleRemoveRestrictedPopup}
        title={restricted.title}
      />
      <PageWrap>
        <div className="w-full max-w-6xl mx-auto my-10">
          <div className="bg-gradient-to-b from-sherpaBlue to-darkBlue rounded-t-lg pt-14">
            <div className="flex flex-row justify-between items-center mb-10 px-6 md:px-14">
              <h1 className="text-2xl md:text-4xl text-white font-semibold">
                Szczegóły scenariusza
              </h1>
              <ScenarioIcon />
            </div>
            <ScenarioCarousel>
              <ContentSlide />
              {data.divisionOfAssets && data?.divisionOfAssets.length !== 0 && (
                <ChartSlide />
              )}
            </ScenarioCarousel>
            <div
              className="bg-sherpaBlue flex flex-row justify-between items-center shadow-checkbox px-6 md:px-14 py-4 cursor-pointer"
              onClick={handleRenderRegulationPopup}
            >
              <span className="text-white font-semibold text-lg">
                Przepis prawa
              </span>
              <AnchorIcon className="whitePath" />
            </div>
          </div>
          {/* bottom section */}
          <div className="bg-white shadow-container rounded-b-lg px-6 md:px-14 py-10 flex flex-col">
            <InfoTabs
              currentAdnotation={currentAdnotation}
              setPopupData={setPopupData}
            />
            <AlternativeScenariosWrap data={data} />
          </div>
          <ContactWrap subscription={subscription} />
          <ExportPdfWrap scenarioId={data.id} />
        </div>
      </PageWrap>
    </>
  );
}

export default ScenarioPage;
