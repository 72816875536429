import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { updateScenarioNumericAnswers } from "../../../redux/reducers/scenarioSlice";
import Text from "../../../components/common/text/Text";
import NextStep from "../../../components/steps/NextStep";
import StepsInput from "../../../components/steps/StepsInput";
import { inputOnlyNumbers } from "../../../validations/inputValidations";
import { setNotifications } from "../../../redux/reducers/notificationsSlice";

function ScenarioNumberInput({ onSubmit, data, setSpecialField }: any) {
  const [value, setValue] = useState("");

  const dispatch = useDispatch();

  const displayLocalError = (message: string) => {
      const alert = {
        title: "Błąd",
        message: message,
        type: "error",
      };
      dispatch(setNotifications(alert));
  }

  const setSpecificField = (value: string) => {
    // used for storing specific fieldName like NO_OF_CHILDREN and its value to help rendering list input
    if(data.field.name !== "FIELD_1") {
      setSpecialField({
        fieldName: data.field.name,
        value: Number(value),
      });
    }
  }

  const handleSubmit = () => {
    if (value.length > 0) {
      if(Number(value) >= 100) return displayLocalError("Wprowadzona wartość jest za duża")
      dispatch(updateScenarioNumericAnswers({ [data.id]: Number(value) }));
      setSpecificField(value);
      onSubmit(data.field.id);
    }
  };

  return (
    <NextStep
      title={"Nowy scenariusz"}
      onSubmit={handleSubmit}
      disabled={value.length === 0}
    >
      <h2 className="text-lg font-semibold text-darkBlue">
        {data?.questionText}
      </h2>
      <div className="flex flex-col gap-3 mt-5">
        <StepsInput
          name={data?.field?.name}
          placeholder={data?.field?.placeholder}
          inputClassName="py-6"
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
          onKeyPress={inputOnlyNumbers}
          key={data?.field?.id}
          required
        />
      </div>
      <Text>{data?.explains}</Text>
    </NextStep>
  );
}

export default ScenarioNumberInput;
