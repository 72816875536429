import React, { useState, useEffect } from "react";
import RadioSelect from "../../../components/common/forms/RadioSelect";
import WealthInput from "../../../components/steps/WealthInput";


interface ExpandableInputInterfce {
  value: string | number;
  setValue: Function;
  selectValue: string;
  selected: string;
  name: any;
  group: string;
  setSelected: Function;
  maxLength?: number;
  onKeyPress?: any;
  closeHelper?: any;
}

function ExpandableInputGroup({
  value,
  setValue,
  name,
  group,
  selectValue,
  selected,
  setSelected,
  ...rest
}: ExpandableInputInterfce) {
  const [opened, setOpened] = useState(selected === selectValue);

    useEffect(() => {
        setOpened(selected === selectValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])


  return (
    <div className="flex flex-col">
      <RadioSelect
        id={group + selectValue}
        group={group}
        value={selectValue}
        selected={selected}
        onClick={(e: any) => setSelected(e.target.value)}
      >
        {name}
      </RadioSelect>
      {opened && (
        <WealthInput
          name={name}
          placeholder="Wpisz wartośc..."
          inputClassName="py-6"
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
          required
          {...rest}
        />
      )}
    </div>
  );
}

export default ExpandableInputGroup;
