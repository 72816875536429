import React from "react";
import DataTabs from "./DataTabs";

const AlternativeScenariosCategory = ({ data, setPopupData }: any) => {
  function splitArrays(array1: any[], array2: any[]) {
    const computedArray = array1.concat(array2);
    const halfOfLength = Math.round(computedArray.length / 2);
    const result1 = computedArray.splice(0, halfOfLength);
    const result2 = computedArray;
    return [result1, result2];
  }

  return (
    <>
      {data.map((el: any, index: number) => {
        const categoryData = splitArrays(
          el.questions,
          el.alternativeScenarioFiles
        );
        return (
          <div
            className="flex flex-col gap-5 sm:bg-white sm:px-14 py-10 rounded-lg"
            key={index}
          >
            <h2 className="text-darkBlue text-lg font-semibold">{el.name}</h2>
            <div className="flex flex-col md:flex-row md:gap-5 justify-between">
              {categoryData[0] === undefined &&
              categoryData[1] === undefined ? (
                <h4 className="text-darkGrey">
                  Brak scenariuszy alternatywnych do wyświetlenia
                </h4>
              ) : (
                <>
                  <DataTabs
                    infoTabsData={categoryData[0]}
                    categoryTitle={el.name}
                    setPopupData={setPopupData}
                  />
                  <DataTabs
                    infoTabsData={categoryData[1]}
                    categoryTitle={el.name}
                    setPopupData={setPopupData}
                  />
                </>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AlternativeScenariosCategory;
