import React, { useState, useEffect } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useDispatch } from 'react-redux';
import { editEnterpriseUser } from '../../../client/AppClient';
import Form from '../../../components/common/forms/Form';
import InputBordered from '../../../components/common/forms/InputBordered';
import PhoneInput from '../../../components/common/forms/PhoneInput';
import Popup from '../../../components/specific/Popup';
import displayError from '../../../utils/handling-errors/displayError';
import displaySuccess from '../../../utils/handling-success/displaySuccess';
import { inputOnlyLetters } from '../../../validations/inputValidations';



interface EditProfileInterface {
  data: any;
  popupOpened: boolean;
  setPopupOpened: Function;
  effectCalled?: any;
};

function EditProfile({
  data,
  popupOpened,
  setPopupOpened,
  effectCalled,
}: EditProfileInterface) {
  const [fullname, setFullname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [errors, setErrors] = useState<any>(null);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setErrors(null);
    const clientData = {
      fullname: fullname,
      phoneNumber: formatPhoneNumberIntl(phoneNumber),
      email: email,
      address: address
    };
    const response = await editEnterpriseUser(data.id, clientData);
    if (response.status !== 200) {
      const errorResponse = displayError(dispatch, response);
      if (errorResponse !== undefined) setErrors(errorResponse);
      return;
    }
    effectCalled.current = false;
    displaySuccess(dispatch, "Sukces", "Zapisano zmiany");
    setPopupOpened(false);
  };

  useEffect(() => {
    if (data.fullname) setFullname(data.fullname);
    if (data.phoneNumber) setPhoneNumber(data.phoneNumber);
    if (data.email) setEmail(data.email);
    if (data.address) setAddress(data.address);
  }, [data]);

  return (
    <Popup
      title={"Edytuj profil Użytkownika"}
      trigger={popupOpened}
      setTrigger={setPopupOpened}
    >
      <div className="flex flex-col">
        <Form
          id="editProfile"
          submitBtn={"Zapisz"}
          onSubmit={handleSubmit}
          buttonSize="md"
        >
          <InputBordered
            label="Imię i Nazwisko"
            name="name"
            type="text"
            placeholder="Wpisz imię i nazwisko..."
            divClassName="my-8"
            value={fullname}
            onChange={(e: any) => setFullname(e.target.value)}
            onKeyPress={inputOnlyLetters}
            error={errors?.fullName}
            required
          />
          <PhoneInput
            label="Numer telefonu"
            name="phone"
            placeholder="Np. +48 555 444 333"
            divClassName="my-8 border-b-0"
            inputClassName={`mt-4 border rounded pb-3 py-3 px-4 ${
              errors?.phoneNumber ? "border-error" : "border-lightGrey"
            }`}
            value={phoneNumber}
            setValue={setPhoneNumber}
            onChange={setPhoneNumber}
            error={errors?.phoneNumber}
            required
          />
          <InputBordered
            label="Adres e-mail"
            name="email"
            type="text"
            placeholder="Wpisz e-mail..."
            divClassName="my-8"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            error={errors?.email}
            required
          />
          <InputBordered
            label="Adres"
            name="address"
            type="text"
            placeholder="Wpisz adres zamieszkania..."
            divClassName="my-8"
            value={address}
            onChange={(e: any) => setAddress(e.target.value)}
            error={errors?.address}
          />
        </Form>
      </div>
    </Popup>
  );
}

export default EditProfile;