import isApiDateOlderThanToday from "./dates/isApiDateOlderThanToday";

const isNormalRestricted = (subscription: any) => {
    // if subscription plan is NORMAL return that is restricted
    if (subscription.subscriptionType === "NORMAL") return true;
    // if subscription don't have expirationDate return that is restricted
    if (!subscription.expirationDate) return true;
    const isNotExpired = isApiDateOlderThanToday(subscription.expirationDate);
    // if subscription is not expried return that is not restricted
    if (isNotExpired) return false;
    // return that is restricted
    return true;
}

export default isNormalRestricted;