import React, { useState, useEffect } from "react";
import { AgoraVideoPlayer, IAgoraRTCRemoteUser } from "agora-rtc-react";
import getVideoDimensions from "../helpers/getVideoDimensions";
import waitForResolutionHeight from "../helpers/waitForResolution";

interface VideoInterface {
  remoteUser: IAgoraRTCRemoteUser;
  remoteUserAudio: boolean;
  userLeft: boolean;
}

function isRemoteUser(remoteUser: IAgoraRTCRemoteUser) {
  return Object.keys(remoteUser).length > 0;
}

const getMiniatureVideDimensions = () => {
  const baseHeight = 200;
  const videoHeight = 480;
  const videoWidth = 430;

  const ratio = baseHeight / videoHeight;

  const displayWidth = videoWidth * ratio;
  const displayHeight = videoHeight * ratio;

  return {
    height: displayHeight,
    width: displayWidth,
  };
};

const AudioOnly = ({
  remoteUser,
  remoteUserAudio,
  userLeft,
}: VideoInterface) => {
  const [localDimensions, setLocalDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [remoteDimensions, setRemoteDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setLocalDimensions(getMiniatureVideDimensions());
    if (isRemoteUser(remoteUser) && remoteUser.videoTrack) {
      waitForResolutionHeight(remoteUser).then(() => {
        setRemoteDimensions(getVideoDimensions(remoteUser.videoTrack));
      });
    }
  }, [remoteUser]);

  return (
    <div
      id="videos"
      className="w-full h-[calc(100vh_-_83px)] sm:h-screen flex justify-center bg-black"
    >
      <div
        id="localVideoPlayer"
        className={`vid localVideo absolute top-10 right-10 z-10 rounded-lg [&>*]:rounded-lg border border-lightBlue`}
        style={{
          width: `${localDimensions.width}px`,
          height: `${localDimensions.height}px`,
        }}
      >
        <p className="text-white font-semibold h-full flex justify-center items-center">
          Wideo wyłączone
        </p>
      </div>
      {isRemoteUser(remoteUser) && remoteUser.videoTrack && (
        <AgoraVideoPlayer
          id={`remotePlayer-${remoteUser.uid}`}
          className="vid"
          style={{
            width: `${remoteDimensions.width}px`,
            height: `${remoteDimensions.height}px`,
            zIndex: 1,
          }}
          videoTrack={remoteUser.videoTrack}
          key={remoteUser.uid}
        />
      )}
      {!isRemoteUser(remoteUser) && remoteUserAudio && (
        <div>
          <p className="text-white font-semibold h-full flex justify-center items-center">
            Wideo wyłączone
          </p>
        </div>
      )}
      {!isRemoteUser(remoteUser) && !remoteUserAudio && (
        <div className="w-full h-full flex items-center justify-center">
          <p className="text-xl text-lightBlue text-center font-semibold">
            {userLeft
              ? "Użytkownik rozłączył się"
              : "Oczekiwanie na połączenie..."}
          </p>
        </div>
      )}
    </div>
  );
};

export default AudioOnly;
