import React, { useState, useLayoutEffect } from "react";
import Header from "../../components/common/text/Header";
import PageWrap from "../../components/layout/PageWrap";
import ShadowContentWrap from "../../components/layout/ShadowContentWrap";

import { ReactComponent as DownloadIcon } from "../../assets/icons/Download.svg";

import { useLocation } from "react-router-dom";
import NoAccess from "../../components/layout/NoAccess";
import { downloadFile } from "../../client/AppClient";
import displayError from "../../utils/handling-errors/displayError";
import { useDispatch, useSelector } from "react-redux";
import PopupRestricted from "../../components/auth/PopupRestricted";
import { getUserSubscription } from "../../redux/reducers/userSlice";
import isNormalRestricted from "../../utils/isNormalRestricted";

function ScenarioFile() {
  const [fileData, setFileData] = useState<any>({});
  const [popupOpened, setPopupOpened] = useState(false);
  const subscription = useSelector(getUserSubscription);
  const location = useLocation();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (location.state !== null) {
      setFileData(location.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = async () => {
    if (isNormalRestricted(subscription)) return setPopupOpened(true);
    const response: any = await downloadFile(fileData.id);
    if (response.status !== 200) return displayError(dispatch, response);
    const fileURL = window.URL.createObjectURL(response.data);
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = fileData.subtitle;
    alink.click();
  };

  if (location.state === null) {
    return (
      <NoAccess
        title="Brak dostępu do pliku"
        description="Aby wyświetlić plik przejdź przez listę dostępnych plików na stronie alternatywnych scenariuszy."
      />
    );
  }

  const ImportantInformation = () => {
    if (
      fileData?.title !== "Testament" &&
      fileData?.title !== "Zarząd sukcesyjny"
    )
      return null;
    const testamentDescription =
      "Aby testament był ważny, konieczne jest jego własnoręczne napisanie w całości, podpisanie i opatrzenie datą";
    const zarzadDescription =
      "Pamiętaj! Oświadczenie to powinno mieć formę pisemną pod rygorem nieważności albo formę elektroniczną (opatrzone kwalifikowanym podpisem elektronicznym).";
    const description =
      fileData?.title === "Testament"
        ? testamentDescription
        : zarzadDescription;
    return (
      <div className="bg-gradient-to-t from-sherpaBlue to-darkBlue p-3 sm:p-7 mt-4 sm:mb-16 rounded-lg">
        <div className="px-7 py-5 bg-white rounded-lg">
          <h5 className="text-lg text-darkBlue font-semibold">PAMIĘTAJ!</h5>
          <p className="text-darkGrey mt-3">{description}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <PopupRestricted
        popupOpened={popupOpened}
        setPopupOpened={setPopupOpened}
        title="Zapis pliku do PDF jest dostępny dla Użytkowników Premium"
      />
      <PageWrap>
        <div className="w-full max-w-5xl mx-auto">
          <ShadowContentWrap className="sm:p-14 mt-16">
            <div className="flex flex-row justify-between items-center mb-10">
              <Header>{fileData?.title}</Header>
              <DownloadIcon
                className="cursor-pointer"
                onClick={handleDownload}
              />
            </div>

            <h2 className="text-lg font-semibold text-darkBlue">
              {fileData?.subtitle}
            </h2>
            <p className="mt-5 text-darkGrey leading-8">
              {fileData?.description}
            </p>
          </ShadowContentWrap>
          <ImportantInformation />
        </div>
      </PageWrap>
    </>
  );
}

export default ScenarioFile;
