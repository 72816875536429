import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import PageWrap from '../../components/layout/PageWrap';
import { useUserAuth } from '../../context/UserAuthContext';
import { setNotifications } from '../../redux/reducers/notificationsSlice';

import { SmsCodeGroup } from './components/SmsCodeGroup';
import VerificationForm from './components/VerificationForm';

const errorMessages: { [message: string]: string } = {
  "Firebase: Error (auth/code-expired).": "Kod stracił ważność",
  "Firebase: Error (auth/invalid-verification-code).": "Nieprawidłowy kod"
}

const getFirebaseError = (mesage: string) => {
  return errorMessages[mesage] || "Wystąpił problem z weryfikacją";
}


function Verification({ number, submitRegister }: any) {
  const [code, setCode] = useState("");
  const [otpResult, setOtpResult] = useState<any>({});

  const effectCalled = useRef<boolean>(false);
  const numberOfInputs = 6;

  const dispatch = useDispatch();

  const { setUpRecaptcha, resendOtpCode }: any = useUserAuth();

  const getOTP = async () => {
    try {
      const response = await setUpRecaptcha(number);
      setOtpResult(response);
    } catch (err: any) {
      console.log(err.message)
    }
  };

  // init recaptcha to allow sending SMS message
  useEffect(() => {
    if (effectCalled.current) return;
    getOTP();
    effectCalled.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    if (code === "" || code === null) return;
    try {
      const response = await otpResult.confirm(code);
      if (response) {
        submitRegister();
      }
    } catch (err: any) {
      const alert = {
        title: "Błąd",
        message: getFirebaseError(err.message),
        type: "error",
      };
      dispatch(setNotifications(alert));
    }
  };

  const handleRequestAgain = async () => {
    try {
      const response = await resendOtpCode(number);
      setOtpResult(response);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PageWrap>
      <div className="w-full max-w-sm px-4 mx-auto pt-20 text-center">
        <h1 className="text-4xl text-darkBlue font-medium">Kod weryfikacji</h1>
        <p className=" mt-5 mb-14 text-darkGrey">
          Wprowadź kod weryfikacyjny, który wysłaliśmy na numer telefonu
          <span className="text-gold font-bold"> {number}</span>
        </p>
        <div>
          <VerificationForm
            id="smsValidation"
            submitBtn="Potwierdź kod"
            valid={numberOfInputs === code.length}
            onSubmit={handleSubmit}
          >
            <SmsCodeGroup
              numberOfInputs={numberOfInputs}
              code={code}
              setCode={setCode}
            />
          </VerificationForm>
          <div id="recaptcha-container"></div>
        </div>
        <div className="flex flex-row justify-between text-sm">
          <span className="text-darkGrey  ">Kod weryfikacyjny nie dotarł?</span>
          <span
            className="text-darkBlue cursor-pointer"
            onClick={handleRequestAgain}
          >
            Wyślij ponownie
          </span>
        </div>
      </div>
    </PageWrap>
  );
}

export default Verification;