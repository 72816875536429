import postRequestNoAuth from "../../requests/postRequestNoAuth";


export const refreshTokenService = async (token: string) => {
  const result = await postRequestNoAuth({
    url: "/login/refresh",
    params: {
      refreshToken: token,
    },
  });
  return result;
};
