import { useState } from "react";
import Cookies from "js-cookie";
import app, { startCollectingAnalitycs } from "../../firebase";
import Button from "../common/button/Button";
import Popup from "./Popup";
import Toggle from "../common/controls/Toggle";

function CookieConsent() {
  const [popupTrigger, setPopupTrigger] = useState(false);
  // used for hiding after first accept, second time it will be readed directly from cookies
  const [isAccepted, setIsAccepted] = useState(false);
  const [analitycsAgreement, setAnalitycsAgreement] = useState(false);

  const handleAgreementOnChange = () => {
    setAnalitycsAgreement((prev) => !prev);
  }

  const handleAcceptAll = () => {
    Cookies.set("cookies-accept", "2", { expires: 999 });
    startCollectingAnalitycs(app);
    setIsAccepted(true);
  };

  const handleAcceptNecessary = () => {
    Cookies.set("cookies-accept", "1", { expires: 999 });
    setIsAccepted(true);
  };

  const openCookiesSettings = () => {
    setPopupTrigger(true);
  };

  const handleSave = () => {
    if (analitycsAgreement) handleAcceptAll();
    else handleAcceptNecessary();
  }

  if (isAccepted) return null;

  return (
    <>
      <Popup
        title="Ustawienia cookies"
        trigger={popupTrigger}
        setTrigger={setPopupTrigger}
      >
        <h6 className="font-bold text-darkBlue">Informacje ogólne:</h6>
        <p className="text-darkGrey">
          Pliki cookies to dane informatyczne, w szczególności pliki tekstowe,
          które generowane są automatycznie przez przeglądarkę internetową
          Użytkownika strony internetowej i zapisywane i przechowywane na
          urządzeniu końcowym Użytkownika (np. komputerze, tablecie,
          smartfonie). Pliki te pozwalają gromadzić informacje związane z
          Użytkownikami strony internetowej oraz ich urządzeniami w momencie jej
          odwiedzania. Pliki cookies pozwalają ustalić m. in liczbę użytkowników
          odwiedzających stronę internetową, jak również sposób w jaki
          Użytkownicy z niej korzystają.
        </p>
        <h6 className="font-bold text-darkBlue mt-2">Ustawienia cookies:</h6>
        <div className="flex flex-col w-full gap-5 mt-2">
          <div className="flex flex-col gap-3 pb-4 border-b-2 border-light">
            <div className="flex flex-row flex-wrap justify-between items-center">
              <span className="text-greyBlue font-semibold">Niezbędne</span>
              <Toggle value={true} checked={true} />
            </div>
            <p className="text-darkGrey">
              Pliki cookies umożliwiające poruszanie się po stronie internetowej
              oraz korzystanie z jej funkcji (zapewniają poprawne działanie
              strony internetowej){" "}
            </p>
          </div>

          <div className="flex flex-col gap-3 pb-4">
            <div className="flex flex-row flex-wrap justify-between items-center">
              <span className="text-greyBlue font-semibold">
                Analityczne/Wydajnościowe
              </span>
              <Toggle
                value={analitycsAgreement}
                checked={analitycsAgreement === true}
                onClick={handleAgreementOnChange}
              />
            </div>
            <p className="text-darkGrey">
              Pliki cookies wykorzystywane do tworzenia analiz i statystyk
              związanych ze sposobem korzystania przez Użytkowników ze strony
              internetowej, co pozwala na ulepszanie jej struktury i zawartości.
            </p>
          </div>
          <Button onClick={handleSave}>Zapisz</Button>
        </div>
      </Popup>

      <div className="fixed w-full max-w-3xl right-0 bottom-0 shadow-container p-4 bg-white bg-opacity-90 rounded-lg flex flex-col justify-between items-center gap-3">
        <div className="w-full text-xs ">
          Stosujemy i wykorzystujemy pliki cookies w celu zapewnienia
          prawidłowego funkcjonowania strony internetowej (pliki cookies
          konieczne). Pliki cookies - za Twoją zgodą - mogą być dodatkowo
          wykorzystywane przez nas lub naszych partnerów w celach analitycznych
          i wydajnościowych. Jeśli chcesz wyrazić zgodę kliknij „Zaakceptuj
          wszystkie”. Wyrażoną zgodę możesz wycofać w każdym momencie,
          zmieniając wybrane ustawienia plików cookies.
          <br />
          <br />
          Korzystanie z plików cookies we wskazanych powyżej celach wiąże się z
          przetwarzaniem Twoich danych osobowych. Administratorem Twoich danych
          osobowych jest SUCCESSIO spółka z ograniczoną odpowiedzialnością z
          siedzibą w Białymstoku, ul. Adama Mickiewicza 48/205, 15-232 Białystok
          W określonych przypadkach administratorami danych mogą być również
          nasi partnerzy. Więcej informacji o zasadach zbierania i
          wykorzystywania plików cookies oraz o przetwarzaniu Twoich danych
          osobowych, w tym o przysługujących Ci w związku z tym prawach,
          znajdziesz w{" "}
          <a
            href="https://successio.pl/polityka-prywatnosci-successio/"
            target="_blank"
            rel="noreferrer"
            className="text-gold"
          >
            Polityce prywatności{" "}
          </a>
        </div>
        <div className="w-full mt-5 md:mt-0 ml-0 md:ml-5 flex flex-col md:flex-row  gap-3 justify-end">
          <Button onClick={handleAcceptAll} size="full">
            Zaakceptuj wszystkie
          </Button>
          <Button
            bgColor="transparent"
            textColor="dark-blue"
            size="full"
            onClick={handleAcceptNecessary}
          >
            Tylko konieczne
          </Button>
          <Button
            bgColor="transparent"
            textColor="dark-blue"
            onClick={openCookiesSettings}
            size="full"
          >
            Zarządzaj cookies
          </Button>
        </div>
      </div>
    </>
  );
}

export default CookieConsent;
