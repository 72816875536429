import React, { useState, useEffect, useRef } from 'react';
import Header from '../../components/common/text/Header';
import PageWrap from '../../components/layout/PageWrap';
import {ReactComponent as PhoneIcon} from "../../assets/icons/Phone.svg"
import {ReactComponent as EmailIcon} from "../../assets/icons/Email.svg"
import Toggle from '../../components/common/controls/Toggle';
import InputBordered from '../../components/common/forms/InputBordered';
import Form from '../../components/common/forms/Form';
import ShadowContentWrap from '../../components/layout/ShadowContentWrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setUser } from '../../redux/reducers/userSlice';
import { getUserProfile, updateFacture } from '../../client/AppClient';
import displayError from '../../utils/handling-errors/displayError';
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";
import EditAccount from './components/EditAccount';
import displaySuccess from '../../utils/handling-success/displaySuccess';

function MyAccount() {
    const [popupOpened, setPopupOpened] = useState(false);
    const [isBusinessAccount, setIsBusinessAcount] = useState(false);
    const [invoiceName, setInvoiceName] = useState("");
    const [nip, setNip] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("");
    const [invoiceAddress, setInvoiceAddress] = useState("");

    const effectCalled = useRef<boolean>(false);
    const dispatch = useDispatch();
    const userData = useSelector(getUser);

    const fetchAccountData = async () => {
      const response = await getUserProfile();
      if (response.status !== 200) return displayError(dispatch, response);
      dispatch(setUser(response.data))
    };

     useEffect(() => {
       if (effectCalled.current) return;
       fetchAccountData();
       effectCalled.current = true;
       // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [popupOpened]);

    useEffect(() => {
      if (!userData.billingDetails) return;
      setIsBusinessAcount(userData.billingDetails.isCompany);
      setInvoiceName(userData.billingDetails.name);
      setNip(userData.billingDetails.nip);
      setInvoiceAddress(userData.billingDetails.address);
      setCity(userData.billingDetails.city);
      setZipCode(userData.billingDetails.zipCode);
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handleChangeAccountType = () => {
        // make request to change account type
        setIsBusinessAcount((prev) => !prev);
    }

    const handleSave = async () => {
      const data = {
        isCompany: isBusinessAccount,
        name: invoiceName,
        nip: nip,
        zipCode: zipCode,
        city: city,
        address: invoiceAddress
      }
      const response = await updateFacture(data);
      if(response.status !== 200) return displayError(dispatch, response);
      displaySuccess(dispatch, "Zapisano", "Zapisano pomyślnie");
    }

    return (
      <>
        <EditAccount
          popupOpened={popupOpened}
          setPopupOpened={setPopupOpened}
          data={userData}
          effectCalled={effectCalled}
        />
        <PageWrap>
          <div className="w-full max-w-6xl mx-auto">
            <ShadowContentWrap>
              <div className="flex flex-row justify-between items-center mb-6">
                <Header>Twoje konto</Header>
                <EditIcon
                  className="cursor-pointer"
                  onClick={() => setPopupOpened(true)}
                />
              </div>
              <div className="flex flex-col md:flex-row justify-between w-11/12 md:items-end">
                <span className="text-lg text-darkBlue">
                  {userData.fullname}
                </span>
                {userData?.phoneNumber && (
                  <span className="flex gap-2 text-darkGrey">
                    <PhoneIcon width={18} /> {userData.phoneNumber}
                  </span>
                )}
                <span className="flex gap-2 text-darkGrey">
                  <EmailIcon width={20} /> {userData.email}
                </span>
              </div>
            </ShadowContentWrap>

            <ShadowContentWrap className="mb-10">
              <div className="text-left mb-6">
                <span className="text-lg font-semibold text-darkBlue">
                  Dane do faktur
                </span>
              </div>

              <div className="flex flex-row justify-end items-center gap-3 mb-8">
                <span className="text-darkGrey">
                  {isBusinessAccount ? "Konto firmowe" : "Konto prywatne"}
                </span>
                <Toggle
                  value={isBusinessAccount}
                  checked={isBusinessAccount === true}
                  onChange={handleChangeAccountType}
                />
              </div>

              <Form id="invoice" submitBtn={"Zapisz"} onSubmit={handleSave}>
                <div className="flex flex-col gap-7">
                  <InputBordered
                    label="Nazwa"
                    name="name"
                    type="text"
                    placeholder=""
                    divClassName=""
                    value={invoiceName}
                    onChange={(e: any) => setInvoiceName(e.target.value)}
                    required
                  />
                  <InputBordered
                    label={isBusinessAccount ? "NIP" : "NIP (opcjonalnie)"}
                    name="name"
                    type="text"
                    placeholder=""
                    divClassName=""
                    value={nip}
                    onChange={(e: any) => setNip(e.target.value)}
                    required={isBusinessAccount}
                  />
                  <InputBordered
                    label="Kod pocztowy"
                    name="name"
                    type="text"
                    placeholder=""
                    divClassName=""
                    value={zipCode}
                    onChange={(e: any) => setZipCode(e.target.value)}
                    required
                  />
                  <InputBordered
                    label="Miasto"
                    name="name"
                    type="text"
                    placeholder=""
                    divClassName=""
                    value={city}
                    onChange={(e: any) => setCity(e.target.value)}
                    required
                  />
                  <InputBordered
                    label="Ulica i numer domu"
                    name="name"
                    type="text"
                    placeholder=""
                    divClassName=""
                    value={invoiceAddress}
                    onChange={(e: any) => setInvoiceAddress(e.target.value)}
                    required
                  />
                </div>
              </Form>
            </ShadowContentWrap>
          </div>
        </PageWrap>
      </>
    );
}

export default MyAccount;