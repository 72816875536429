import React, { useState, useEffect, useRef } from "react";
import PageWrap from "../../components/layout/PageWrap";
import { ReactComponent as ImageSvg } from "../../assets/images/Transaction.svg";
import { ReactComponent as SpinSvg } from "../../assets/icons/LoadingSpin.svg";
import Button from "../../components/common/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { getTransactionStatus, getUserProfile } from "../../client/AppClient";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/reducers/userSlice";

function TransactionResult() {
  const [status, setStatus] = useState<102 | 200 | 400>(102);
  const effectCalled = useRef<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { transactionId } = useParams();

  const updateUser = async () => {
    const userDetails = await getUserProfile();
    if (userDetails.status !== 200) return;
    dispatch(setUser(userDetails.data));
  };

  useEffect(() => {
    if (effectCalled.current) return;
    waitForTransactionStatus();
    effectCalled.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const waitForTransactionStatus = async () => {
    if (transactionId === undefined) return;
    const response: any = await getTransactionStatus(transactionId);
    return new Promise<void>((resolve) => {
      const checkStatus = () => {
        if (response.status !== 200 && response.status !== 400) setTimeout(checkStatus, 2000); // check again in 2000ms
        setStatus(response.status);
        updateUser();
        resolve();
      };
      checkStatus();
    });
  };

  const handleBack = () => {
    const redirectUrl = localStorage.getItem("redirectPath");
    if(redirectUrl === null) navigate("/");
    else {
      localStorage.removeItem("redirectPath");
      navigate(redirectUrl);
    }
    // navigate back to given url
  };

  const textData = {
    102: {
      title: "Transakcja w trakcie",
      description:
        "Transkacja jest w trakcie przetwarzania, \n za chwilę otrzymasz informację o statusie płatności",
    },
    200: {
      title: "Sukces!",
      description:
        "Transkacja zakończona pomyślnie, \n kliknij przycisk aby powrócić do aplikacji",
    },
    400: {
      title: "Niepowodzenie",
      description:
        "Transkacja się nie udała, \n kliknij przycisk aby powrócić do aplikacji",
    },
  };

  return (
    <PageWrap>
      <div className="w-full max-w-xl sm:px-4 mx-auto">
        <div className="flex flex-col items-center ">
          <ImageSvg className="h-auto w-full max-w-sm" width={400} />
          <p className="text-darkBlue font-semibold text-lg sm:text-2xl md:text-3xl text-center mt-5">
            {textData[`${status}`].title}
          </p>
          <p className="text-sm max-w-xs text-darkGrey text-center">
            {textData[`${status}`].description}
          </p>
          <span>
            <div className="mt-10">
              {status === 102 ? (
                <SpinSvg className="animate-spin spinGold h-5 w-5" />
              ) : (
                <Button onClick={handleBack}>Powrót</Button>
              )}
            </div>
          </span>
        </div>
      </div>
    </PageWrap>
  );
}

export default TransactionResult;
