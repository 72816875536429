import React, { useState, useEffect, useRef } from "react";
import SubHeader from "../../components/common/text/SubHeader";
import PageWrap from "../../components/layout/PageWrap";
import SearchBar from "./components/SearchBar";
import UsersTable from "./components/UsersTable";
import Pagination from "../../components/specific/Pagination";
import SortPopup from "./components/SortPopup";
import Button from "../../components/common/button/Button";
import CreateUser from "./components/CreateUser";
import LoadingSpinner from "../../components/specific/LoadingSpinner";
import FilterPopup from "./components/FilterPopup";
import ConfirmPopup from "./components/ConfirmPopup";
import ShadowContentWrap from "../../components/layout/ShadowContentWrap";
import { ClickOutside } from "../../components/specific/ClickOutside";

import { ReactComponent as SortIcon } from "../../assets/icons/Filters.svg";
import { ReactComponent as FiltersIcon } from "../../assets/icons/Filters2.svg";

import { useDispatch } from "react-redux";
import EditProfile from "./components/EditProfile";
import { getEnterpriseUserList } from "../../client/AppClient";
import displayError from "../../utils/handling-errors/displayError";

function EnterpriseUsers() {
  const [loading, setLoading] = useState(true);
  const [filterVisibility, setFilterVisibility] = useState(false);
  const [sortVisibility, setSortVisibility] = useState(false);
  
  const [popupOpened, setPopupOpened] = useState(false);
  const [editPopupOpened, setEditPopupOpened] = useState(false);
  const [confirmPopupOpened, setConfirmPopupOpened] = useState(false);
  
  const [currentSort, setCurrentSort] = useState(1);
  const [currentStatus, setCurrentStatus] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  
  const [fetchedData, setFetchedData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  
  const [confirmData, setConfirmData] = useState<any>({});
  const [editUserData, setEditUserData] = useState({});
  
  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const effectCalled = useRef(false);

  useEffect(() => {
    const fetchUserBase = async () => {
      const response = await getEnterpriseUserList(
        currentPage,
        itemsPerPage,
        currentSort,
        currentStatus,
        searchValue
      );
      if (response.status !== 200) return displayError(dispatch, response);
      setFetchedData(response.data.items);
      setTotalUsers(response.data.total);
    };
    fetchUserBase();
    if (!effectCalled.current) {
      // call only on first render
      setLoading(false);
    }
    effectCalled.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentStatus,
    searchValue,
    currentPage,
    currentSort,
    popupOpened,
    editPopupOpened,
    confirmPopupOpened,
  ]);

  const handleFilterVisibility = () => {
    setFilterVisibility((prev) => !prev);
  };
  const handleSortVisibility = () => {
    setSortVisibility((prev) => !prev);
  };
  const handleClose = () => {
    setTimeout(() => {
      setFilterVisibility(false);
      setSortVisibility(false);
    }, 1);
  };

  const handleSearchChange = (searchValue: string) => {
    setCurrentPage(1);
    setSearchValue(searchValue);
  };

  const handleChangeSort = (sort: number) => {
    if (sort === currentSort) return;
    setCurrentSort(sort);
  };

  const handleChangeFilter = (status: string) => {
    if (status === currentStatus) return;
    setCurrentStatus(status);
  };

  const handleChangeStatus = (id: string, status: string, fullname: string) => {
    const popupData = {
      title:
        status === "ARCHIVED" ? "Zablokuj Użytkownika" : "Odblokuj Użytkownika",
      description: `Czy na pewno chcesz ${
        status === "ARCHIVED" ? "zablokować" : "odblokować"
      } ${fullname}?`,
      id: id,
      status: status,
    };
    setConfirmData(popupData);
    setConfirmPopupOpened(true);
  }

  const handleEditPopupOpen = (data: any) => {
    setEditUserData(data);
    setEditPopupOpened(true);
  };

  const SortComponent = () => {
    return (
      <>
        <SortIcon className="cursor-pointer" onClick={handleSortVisibility} />
        <ClickOutside onClickOutside={handleClose} showInfo={sortVisibility}>
          <div className="absolute mt-2 z-10 right-0 top-full">
            <SortPopup
              handleChangeFilter={handleChangeSort}
              selected={currentSort}
            />
          </div>
        </ClickOutside>
      </>
    );
  };

  const FilterComponent = () => {
    return (
      <>
        <FiltersIcon
          className="cursor-pointer"
          onClick={handleFilterVisibility}
        />
        <ClickOutside onClickOutside={handleClose} showInfo={filterVisibility}>
          <div className="absolute mt-2 z-10 right-0 top-full">
            <FilterPopup
              handleChangeFilter={handleChangeFilter}
              selected={currentStatus}
            />
          </div>
        </ClickOutside>
      </>
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <ConfirmPopup
        popupOpened={confirmPopupOpened}
        setPopupOpened={setConfirmPopupOpened}
        confirmData={confirmData}
      />
      <EditProfile
        data={editUserData}
        popupOpened={editPopupOpened}
        setPopupOpened={setEditPopupOpened}
        effectCalled={effectCalled}
      />
      <CreateUser popupOpened={popupOpened} setPopupOpened={setPopupOpened} />
      <PageWrap>
        <div className="w-full max-w-6xl mx-auto ">
          <ShadowContentWrap>
            <div className="flex flex-col md:flex-row justify-between mb-10 gap-5 md:gap-0">
              <div className="w-full md:w-1/2">
                <SubHeader>Baza Użytkowników</SubHeader>
              </div>
              <div className="flex flex-row items-center gap-3 w-full md:w-2/5 relative">
                <SearchBar handleSearchChange={handleSearchChange} />
                <SortComponent />
                <FilterComponent />
              </div>
            </div>
            {searchValue.length > 0 && (
              <h3 className="text-xl font-semibold text-darkBlue mb-6">
                Wyniki wyszukiwania
              </h3>
            )}
            <UsersTable
              data={fetchedData}
              handleChangeStatus={handleChangeStatus}
              handleEditPopupOpen={handleEditPopupOpen}
            />
            <Pagination
              total={totalUsers}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={(page: any) => setCurrentPage(page)}
            />
            <div className="flex justify-center mt-10">
              <Button onClick={() => setPopupOpened(true)}>
                Utwórz profil Użytkownika
              </Button>
            </div>
          </ShadowContentWrap>
        </div>
      </PageWrap>
    </>
  );
}

export default EnterpriseUsers;
