import postRequest from "../../requests/postRequest";



export const getScenarioResultService = async (body: any) => {
  const result = await postRequest({
    url: "/scenario/result",
    params: {
      name: body.name,
      wealth: body.wealth,
      numericAnswers: body.numericAnswers,
      choiceAnswers: body.choiceAnswers,
      numericListAnswers: body.numericListAnswers,
    },
  });
  return result;
};
