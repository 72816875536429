import React from 'react';

function UsersCount({ planDetails }: any) {
    return (
      <div className="flex flex-row justify-between items-center mb-4">
        <span className="text-darkGrey">Ilość Użytkowników:</span>
        <span className="text-sherpaBlue">{planDetails.numberOfUsers}</span>
      </div>
    );
}

export default UsersCount;