import { PDFDownloadLink } from "@react-pdf/renderer";
import Button from "../../../components/common/button/Button";
import PdfDocument from "../pdf-document/PdfDocument";
function PdfDownload({ data, chartBase, setPdfLoaded }: any) {
  return (
    <PDFDownloadLink
      document={
        <PdfDocument
          data={data}
          chartBase={chartBase}
          setPdfLoaded={setPdfLoaded}
        />
      }
      fileName="Podsumowanie scenariusza.pdf"
    >
      <Button data-html2canvas-ignore="true">Pobierz PDF</Button>
    </PDFDownloadLink>
  );
}

export default PdfDownload;
