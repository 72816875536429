import React from "react";
import { ReactComponent as CheckedIcon } from "../../../assets/icons/CheckGold.svg";
import { ReactComponent as XIcon } from "../../../assets/icons/X.svg";
import Button from "../../../components/common/button/Button";
import { PlansContentInterface } from "./types";

function PlansDesktop({
  plansData,
  choosePlan,
  accountType
}: PlansContentInterface) {
  const RenderIcon = ({ includes }: { includes: boolean }) => {
    if (!includes) {
      return (
        <div className="flex justify-center">
          <XIcon />
        </div>
      );
    }
    return (
      <div className="flex justify-center">
        <CheckedIcon />
      </div>
    );
  };
  return (
    <div className="flex flex-row justify-center mt-10 mb-8">
      <div className="flex flex-col w-1/4 py-8 px-5">
        <div className="h-14"></div>
        {plansData.features.map((el, key) => (
          <div className="flex items-center h-16 text-darkGrey" key={key}>
            {el.name}
          </div>
        ))}
        <div className="flex items-center h-16 text-darkGrey">
          {plansData.price.name}
        </div>
        <div className="flex items-center h-16"></div>
      </div>

      <div className="flex flex-col w-1/4 bg-white bg-opacity-70 rounded-lg shadow-container p-8">
        <div className="flex items-center justify-center h-14">
          <span className="text-lg text-darkBlue font-semibold px-20 text-center leading-tight">
            Pakiet podstawowy
          </span>
        </div>
        {plansData.features.map((el, key) => (
          <div
            className="flex items-center justify-center h-16 text-darkGrey"
            key={key}
          >
            <RenderIcon includes={el.base} />
          </div>
        ))}
        <div className="flex items-center justify-center h-16 text-darkGrey">
          {plansData.price.base}
        </div>
        <div className="xl:px-10 mt-6">
          <div className="flex justify-center">
            <Button
              size="sm"
              textColor="darkBlue"
              bgColor="transparent"
              onClick={() => choosePlan("NORMAL")}
            >
              Wybieram Wersję Podstawową
            </Button>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-1/4 bg-white rounded-lg z-10 shadow-container p-8">
        <div className="flex items-center justify-center h-14">
          <span className="text-lg text-darkBlue font-semibold px-20 text-center leading-tight">
            Pakiet Premium
          </span>
        </div>
        {plansData.features.map((el, key) => (
          <div
            className="flex items-center justify-center h-16 text-darkGrey"
            key={key}
          >
            <RenderIcon includes={el.premium} />
          </div>
        ))}
        <div className="flex items-center justify-center h-16 text-darkGrey">
          {plansData.price.premium}
        </div>
        <div className="xl:px-10 mt-6">
          <div className="flex flex-col justify-center">
            <Button size="sm" onClick={() => choosePlan("PREMIUM")}>
              Wybieram Pakiet <br /> Premium
            </Button>
          </div>
        </div>
      </div>

      {accountType === "PROFESSIONAL" && (
        <div className="w-1/4 flex flex-col bg-white rounded-lg bg-opacity-70 shadow-container p-14 items-center justify-center text-center ">
          <span className="text-lg text-darkBlue font-semibold leading-tight">
            Zakup <br /> Enterprise
          </span>
          <p className="my-5 text-darkGrey">
            Propozycje dopasowane do potrzeb Twojego biznesu, skontaktuj się z
            nami
          </p>
          <Button
            size="md"
            py="5"
            textColor="darkBlue"
            bgColor="transparent"
            onClick={() => choosePlan("ENTERPRISE")}
          >
            Wybieram Enterprise
          </Button>
        </div>
      )}
    </div>
  );
}

export default PlansDesktop;