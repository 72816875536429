import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { updateInheritanceShare } from "../../../redux/reducers/partionSlice";

import RadioSelect from "../../../components/common/forms/RadioSelect";
import Text from "../../../components/common/text/Text";
import NextStep from "../../../components/steps/NextStep";
import { useLocation } from "react-router-dom";
import getLegalPortionPersons from "../helpers/getLegalPortionPersons";


function LegalPortionTarget({ onSubmit }: any) {
    const [persons, setPersons] = useState<any>([]);
    const [value, setValue] = useState("");
    const [selectedValue, setSelectedValue] = useState("");

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
      const setLocationData = () => {
        const sharesData = location.state?.scenarioData.divisionOfAssets;
        const personsAble = getLegalPortionPersons(sharesData);
        setPersons(personsAble);
      };
      if (location.state) setLocationData();
    }, [location]);

    const handleClick = (e: any, part: number) => {
      setValue(`${part}`);
      setSelectedValue(e.target.value)
    }
    
    const handleSubmit = () => {
      if (value.length > 0) {
        dispatch(updateInheritanceShare(Number(value) / 100));
        onSubmit();
      }
    };

    return (
      <NextStep
        title={"Zachowek"}
        onSubmit={handleSubmit}
        disabled={value.length === 0}
      >
        <h2 className="text-lg font-semibold text-darkBlue">
          Dla jakiej Osoby wyliczasz zachowek?
        </h2>
        <div className="flex flex-col gap-3 mt-5">
          {
            persons?.map((person: any, index: number) => {
              return (
                <RadioSelect
                  id={`legalportiontarget${index}`}
                  group="legalportiontarget"
                  selected={selectedValue}
                  value={`${person.part + index}`}
                  onClick={(e: any) => handleClick(e, person.part)}
                  key={index}
                >
                  {person.person}
                </RadioSelect>
              );
            })
          }
        </div>
        <Text>
          Do zachowku są uprawnieni wyłącznie zstępni, małżonek i rodzice,
          którzy byliby powołani do spadku na mocy dziedziczenia ustawowego.
        </Text>
      </NextStep>
    );
}

export default LegalPortionTarget;