import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../client/AppClient";
import { getUser, setUser } from "../redux/reducers/userSlice";
import { handleLogout } from "../utils/handling-errors/displayError";

const usePersistUser = () => {
    const user = useSelector(getUser);
    const dispatch = useDispatch()
    const token = Cookies.get("jwt");

    
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        async function persistUser() {
            const userDetails = await getUserProfile();
            if(userDetails.status !== 200) {
                handleLogout(dispatch);
                setLoading(false);
            } else {
                dispatch(setUser(userDetails.data));
                setLoading(false);
            }
        }
        if (token && !user) {
            persistUser();
        } else {
            setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading;
}

export default usePersistUser;