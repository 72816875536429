import React from 'react';
import Form from '../common/forms/Form';
import Header from '../common/text/Header';
import { ReactComponent as AnchorIcon } from "../../assets/icons/Anchor.svg";

function NextStep({ children, onSubmit, disabled, title }: any) {
    return (
      <div className="sm:bg-white sm:shadow-container rounded-lg sm:p-12 mt-5">
        <div className="flex flex-col">
          <Header>{title}</Header>
          <div className="mt-10">
            <Form
              onSubmit={onSubmit}
              disabled={disabled}
              submitBtn={
                <span className="flex items-center gap-3">
                  Przejdź dalej
                  <AnchorIcon height={13} className="whitePath" />{" "}
                </span>
              }
            >
              {children}
            </Form>
          </div>
        </div>
      </div>
    );
}

NextStep.defaultProps = {
  disabled: true
};

export default NextStep;