import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from "recharts";
import getTextColor from "../../../utils/colorBasedOnBackground";
import getClosestFraction from "../../../utils/getClosestFraction";

const COLORS = [
  "#E0B16B",
  "#FFF2DF",
  "#A9916E",
  "#B58B4E",
  "#da9a3b",
  "#715224",
  "#bbb8b4",
  "#FFEDCB",
  "#FEEAE3",
  "#FFB67D",
];
const RADIAN = Math.PI / 180;
const mobileBreakpoint = 768;
const smallMobileScreen = 409;

const getColorIndex = (index: number) => {
  const lastDigitString = String(index).slice(-1);
  const lastDigitsNumber = Number(lastDigitString);
  return lastDigitsNumber;
};

const getChartHeight = (pdf: boolean, data: any[]) => {
  const isMobile = window.innerWidth <= mobileBreakpoint;
  if (!isMobile && !pdf) return 400;
  const isMobileSmallScreen = window.innerWidth <= smallMobileScreen;

  const wrapWidth = pdf ? 25 : (isMobileSmallScreen ? 17 : 25);
  const numberOfLines = Math.ceil(
    data.reduce((a: number, b: any) => a + b.person.length, 0) / wrapWidth
  );
  const averageHeightOfLegendLine = pdf ? 30 : 35;

  if (pdf) return 300 + numberOfLines * averageHeightOfLegendLine;

  return 300 + numberOfLines * averageHeightOfLegendLine;
};

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const textFill = getTextColor(COLORS[getColorIndex(index)]);
  return (
    <text
      x={x}
      y={y}
      fill={textFill}
      textAnchor="middle"
      dominantBaseline="central"
      className="font-semibold"
    >
      {getClosestFraction(percent)}
    </text>
  );
};



const renderCustomTooltip = ( { payload }: any) => {
  if (payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div>
          {payload.map((entry: any, index: number) => {
            return (
              <div className="flex flex-row items-center gap-3 px-3 bg-white rounded-sm shadow-md" key={index}>
                <span
                  className={`h-4 w-4 rounded-full`}
                  style={{ backgroundColor: entry.payload.fill }}
                ></span>
                <span className="text-black">{entry.name}: {Math.round(entry.value * 100) / 100}%</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
}

export default function Chart({ data, pdf=false }: any) {
  const [height, setHeight] = useState(getChartHeight(pdf, data));

  useEffect(() => {
    const handleWindowSizeChange = () => {
      const newHeight = getChartHeight(pdf, data);
      if (newHeight !== height) {
        setHeight(newHeight);
      }
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [data, height, pdf]);

  const getChartData = () => {
    const chartData: any = [];
    data.forEach((el: any) => {
      chartData.push({
        name: el.person,
        value: el.part,
      });
    });
    return chartData;
  };


  const renderCustomizedLegend = ({ payload }: any) => {
    return (
      <div className="flex flex-row gap-5 items-center justify-center flex-wrap" 
      style={pdf ? {flexDirection: "row", gap: "20px" } : {}}
      >
        {payload.map((entry: any, index: number) => {
          return (
            <div className="flex flex-row items-center gap-3" key={index}>
              <span
                className={`h-4 w-4 rounded-full`}
                style={{ backgroundColor: entry.color }}
              ></span>
              <span className={pdf ? "text-darkBlue" : `text-white`}>
                {entry.value}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <ResponsiveContainer width={pdf ? 500 : "100%"} height={height} id={"chart"} className={pdf ? "z-0 absolute overflow-hidden" : ""}>
      {data ? (
        <PieChart>
          <Pie
            data={getChartData()}
            startAngle={-270}
            labelLine={false}
            label={renderCustomizedLabel}
            isAnimationActive={!pdf}
            outerRadius={110}
            stroke={"#003F53"}
            dataKey="value"
          >
            {data.map((_: any, index: number) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend
            content={renderCustomizedLegend}
          />
          <Tooltip content={renderCustomTooltip} />
        </PieChart>
      ) : (
        <></>
      )}
    </ResponsiveContainer>
  );
}
