import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateScenarioChoiceAnswers } from "../../../redux/reducers/scenarioSlice";

import RadioSelect from "../../../components/common/forms/RadioSelect";
import Text from "../../../components/common/text/Text";
import NextStep from "../../../components/steps/NextStep";

function ScenarioSelect({ onSubmit, data }: any) {
  const [value, setValue] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (value.length > 0) {
      dispatch(updateScenarioChoiceAnswers({ [data.id]: value }));
      onSubmit(value);
    }
  };

  return (
    <NextStep
      title={"Nowy scenariusz"}
      onSubmit={handleSubmit}
      disabled={value.length === 0}
    >
      <h2 className="text-lg font-semibold text-darkBlue">
        {data?.questionText}
      </h2>
      <div className="flex flex-col gap-3 mt-5">
        {data?.answers.map((el: any, key: number) => {
          return (
            <RadioSelect
              id={el.id}
              group={data?.id}
              selected={value}
              value={el.id}
              onClick={(e: any) => setValue(e.target.value)}
              key={key}
            >
              {el.text}
            </RadioSelect>
          );
        })}
      </div>
      <Text>{data?.explains}</Text>
    </NextStep>
  );
}

export default ScenarioSelect;
