import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../common/button/Button";
import Popup from "../specific/Popup";

interface IPopupRestricted {
  popupOpened: boolean;
  setPopupOpened: Function;
  title: string;
}

function PopupRestricted({ popupOpened, setPopupOpened, title}: IPopupRestricted) {
    const location = useLocation();
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate("/plans", { state: { navigatedFrom: location.pathname } });
    }
  return (
    <Popup
      title="PAMIĘTAJ!"
      trigger={popupOpened}
      setTrigger={setPopupOpened}
      size="max-w-5xl"
    >
      <div className="flex flex-col">
        <p className="text-darkGrey text-center">
          {title}
        </p>
        <div className="flex justify-center mt-10">
          <Button onClick={handleNavigate}>Zmień pakiet</Button>
        </div>
      </div>
    </Popup>
  );
}

export default PopupRestricted;