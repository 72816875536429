import React, { useState, useEffect } from "react";
import { ICameraVideoTrack, IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";

import { ReactComponent as MicrophoneSvg } from "../../../assets/icons/Microphone.svg";
import { ReactComponent as VideoSvg } from "../../../assets/icons/Video.svg";
import { ReactComponent as HangUpSvg } from "../../../assets/icons/HangUp.svg";

interface ControlsInterface {
  localTracks: {
    audioTrack: IMicrophoneAudioTrack;
    videoTrack: ICameraVideoTrack;
  };
  leave: Function;
}

const Controls = ({ localTracks, leave }: ControlsInterface) => {
  const [trackState, setTrackState] = useState({ video: true, audio: true });

  useEffect(() => {
    if (
      localTracks.audioTrack === null ||
      localTracks.audioTrack === undefined
    ) {
      setTrackState((ps: any) => {
        return { ...ps, audio: false };
      });
    }
    if (
      localTracks.videoTrack === null ||
      localTracks.videoTrack === undefined
    ) {
      setTrackState((ps: any) => {
        return { ...ps, video: false };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLocalVideoClassName = () => {
    if (trackState.video) {
      document.querySelector(".localVideo")?.classList.add("localVideoOff");
    } else {
      document.querySelector(".localVideo")?.classList.remove("localVideoOff");
    }
  };

  const mute = async (type: "audio" | "video") => {
    if (type === "audio" && localTracks.audioTrack) {
      await localTracks.audioTrack.setEnabled(!trackState.audio);
      setTrackState((ps: any) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video" && localTracks.videoTrack) {
      await localTracks.videoTrack.setEnabled(!trackState.video);
      changeLocalVideoClassName();
      setTrackState((ps: any) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  return (
    <div className="controls w-full max-w-sm fixed bottom-0 sm:bottom-10 left-1/2 -translate-x-1/2 flex gap-5 justify-center bg-gradient-to-b from-sherpaBlue to-darkBlue sm:rounded-lg py-4 text-white z-20">
      <p
        className={`bg-white bg-opacity-10 rounded-full flex items-center p-2 cursor-pointer relative ${
          trackState.video
            ? "on"
            : "after:absolute after:h-full after:w-1 after:bg-white after:left-[45%] after:rounded-lg after:rotate-45"
        }`}
        onClick={() => mute("video")}
      >
        <VideoSvg />
      </p>
      {
        <p
          className="bg-lightRed rounded-full flex items-center p-2 cursor-pointer"
          onClick={() => leave()}
        >
          <HangUpSvg width={35} height={25} />
        </p>
      }
      {/* {
        <p
          className="bg-lightRed rounded-full flex items-center p-2 cursor-pointer"
          onClick={() => changeCamera()}
        >
          ch
        </p>
      } */}
      <p
        className={`bg-white bg-opacity-10 rounded-full flex items-center p-2 cursor-pointer relative ${
          trackState.audio
            ? "on"
            : "after:absolute after:h-full after:w-1 after:bg-white after:left-[45%] after:rounded-lg after:rotate-45"
        }`}
        onClick={() => mute("audio")}
      >
        <MicrophoneSvg />
      </p>
    </div>
  );
};

export default Controls;