const storageKey = "agoraRtc";

export const saveRtcToLocalStorage = (data: any) => {
    localStorage.setItem(storageKey, JSON.stringify(data));
}

export const getRtcFromLocalStorage = () => {
    const storageData = localStorage.getItem(storageKey);
    if(storageData === null) return null;
    return JSON.parse(storageData);
}

export const removeRtcFromLocalStorage = () => {
    localStorage.removeItem(storageKey);
}