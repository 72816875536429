import React from 'react';
import AddClientForm from '../../../../components/final-forms/AddClientForm';



function NewClient({ handleSave }: any) {

  const handleSaveOnSuccess = (data: any) => {
    handleSave(data.id);
  }

  return (
    <AddClientForm
      handleSuccessSubmit={handleSaveOnSuccess}
      submitBtn="Utwórz profil i zapisz scenariusz"
    />
  );
}

export default NewClient;