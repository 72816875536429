import Cookies from "js-cookie";
import { refreshTokenCall } from "../AppClient";
import setJwt from "./setJwt";

const refreshToken = async () => {
  const expire = Cookies.get("jwtExpire");
  if (!expire) return
  const expireDate = new Date(expire);
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  if(tomorrow.getTime() > expireDate.getTime()) {
    const jwtRefresh = Cookies.get("jwtRefresh");
    const response = await refreshTokenCall(jwtRefresh!);
    if(response.status !== 200) return;
    setJwt(response.data);
  }
}

export default refreshToken;