import React, { useEffect, useState } from 'react';
import Button from '../../../components/common/button/Button';
import Header from '../../../components/common/text/Header';
import { ReactComponent as SuccessImg } from "../../../assets/images/success.svg";
import { useDispatch, useSelector } from 'react-redux';
import { getPortion, updatePortionSumWealth } from '../../../redux/reducers/partionSlice';
import LoadingSpinner from '../../../components/specific/LoadingSpinner';



function LegalPortionSuccess({ onSubmit }: any) {
  const selector = useSelector(getPortion);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  useEffect(() => {
    let a, b, c, d, indicator;
    indicator = selector.targetDisabled;
    a = selector.inheritanceShare;
    b = selector.scenarioValues.wealth;
    c = selector.additionalValues;
    d = selector.beneficiaryValues
    const Z = indicator * a * (b+c) - d;
    const result = Z.toFixed(2);
    dispatch(updatePortionSumWealth(result));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = () => {
    onSubmit();
  };

  if (loading) return <LoadingSpinner />;

  return (
    <div className="w-full my-16">
      <div className="flex flex-col text-center items-center">
        <SuccessImg className="w-full h-auto max-w-lg" />
        <div className="mt-14">
          <Header>Sukces!</Header>
          <p className="text-darkGrey text-lg my-5">
            Wyliczenie zachowku powiodło się!<br />
            Możesz teraz przejść do wyniku.
          </p>
        </div>
        <Button onClick={handleClick}>Przejdź do wyniku</Button>
      </div>
    </div>
  );
}

export default LegalPortionSuccess;