import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { changePassword } from '../../client/AppClient';
import Form from '../../components/common/forms/Form';
import InputBordered from '../../components/common/forms/InputBordered';
import PageWrap from '../../components/layout/PageWrap';
import ShadowContentWrap from '../../components/layout/ShadowContentWrap';
import { setNotifications } from '../../redux/reducers/notificationsSlice';
import displayError from '../../utils/handling-errors/displayError';
import displaySuccess from '../../utils/handling-success/displaySuccess';
import { resetPassValidation } from '../../validations/resetPassValidation';
import ChangeMessage from './components/ChangeMessage';



function PassChange() {
    const [oldPass, setOldPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [newPassRepeat, setNewPassRepeat] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const dispatch = useDispatch();

    const displayLocalError = (message: string) => {
      const alert = {
        title: "Błąd",
        message: message,
        type: "error",
      };
      dispatch(setNotifications(alert));
    };

    const handleRequest = async () => {
      const data = {
        oldPassword: oldPass,
        newPassword: newPass,
        reNewPassword: newPassRepeat,
      };
      const response = await changePassword(data);
      if(response.status !== 200) return displayError(dispatch, response);
      displaySuccess(dispatch, "Sukces", "Zapisano zmiany");
      setSubmitted(true);

    }

    const handleSubmit = () => {
        // check if old pass is valid
        const validation = validatePasswords();
        if(!validation) return;
        handleRequest();
    }

    const validatePasswords = () => {
      const errors = resetPassValidation(newPass, newPassRepeat);
      if (errors.length === 0) return true;
      errors.forEach((el) => displayLocalError(el));
      return false;
    };

    const checkIfNotEmpty = () => {
      if (newPass.length > 0 && newPassRepeat.length > 0 && oldPass.length > 0) {
        return false;
      }
      return true;
    };

    if(submitted) return <ChangeMessage />

    return (
      <PageWrap>
        <div className="w-full max-w-xl px-4 mx-auto ">
          <ShadowContentWrap>
            <h3 className="text-darkBlue text-lg font-semibold text-left">
              Zmień hasło
            </h3>
            <div className="flex flex-col">
              <Form
                id="editProfile"
                submitBtn={"Zapisz"}
                onSubmit={handleSubmit}
                buttonSize="md"
                disabled={checkIfNotEmpty()}
              >
                <InputBordered
                  label="Stare hasło"
                  name="oldpass"
                  type="password"
                  placeholder="Wpisz swoję hasło..."
                  divClassName="my-8"
                  value={oldPass}
                  onChange={(e: any) => setOldPass(e.target.value)}
                  required
                />
                <InputBordered
                  label="Nowe hasło"
                  name="newpass"
                  type="password"
                  placeholder="Wpisz nowe hasło..."
                  divClassName="my-8"
                  value={newPass}
                  onChange={(e: any) => setNewPass(e.target.value)}
                  required
                />
                <InputBordered
                  label="Powtórz nowe hasło"
                  name="newpassr"
                  type="password"
                  placeholder="Powtórz nowe hasło..."
                  divClassName="my-8"
                  value={newPassRepeat}
                  onChange={(e: any) => setNewPassRepeat(e.target.value)}
                  required
                />
              </Form>
            </div>
          </ShadowContentWrap>
        </div>
      </PageWrap>
    );
}

export default PassChange;