import React from "react";
import classNames from "classnames";

interface InputInterface {
  name: string;
  placeholder?: any;
  value?: string | number;
  required?: boolean;
  onChange?: any;
  inputClassName?: string;
  divClassName?: string;
  maxLength?: any;
  disabled?: boolean;
  onKeyPress?: any;
}

const StepsInput = ({
  name,
  placeholder,
  value,
  inputClassName,
  divClassName,
  required,
  ...rest
}: InputInterface) => {
  return (
    <div
      className={classNames(
        "bg-light relative flex flex-col text-left",
        divClassName
      )}
    >
      <input
        className={classNames(
          "bg-transparent text-sm font-medium placeholder-lightGrey text-sherpaBlue block w-full py-4 px-4 focus-visible:outline-none",
          inputClassName
        )}
        id={name}
        name={name}
        type={"text"}
        value={value}
        placeholder={placeholder}
        {...rest}
        required
      />
    </div>
  );
};

StepsInput.defaultProps = {
  divClassName: "mt-4",
};

export default StepsInput;
