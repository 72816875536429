import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/common/button/Button';
import PageWrap from '../../../components/layout/PageWrap';

function ChangeMessage() {
    return (
      <PageWrap>
        <div className="w-full max-w-xl px-4 mx-auto ">
          <div className="mt-14">
            <p className="text-lg font-medium my-2 text-darkGrey text-center">
              Hasło zostało zmienione
            </p>
            <br />
            <Link to="/" className="flex justify-center">
              <Button>Powrót na stronę główną</Button>
            </Link>
          </div>
        </div>
      </PageWrap>
    );
}

export default ChangeMessage;