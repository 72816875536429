import React, { Ref } from 'react';

interface InputDigitInterface {
  onChange: Function;
  handleKeyDown: any;
  setInputValue: Function;
  inputValue: string;
  inputRef: Ref<any>;
}

function InputDigit({
  inputValue,
  onChange,
  setInputValue,
  handleKeyDown,
  inputRef,
}: InputDigitInterface) {
  const handleInput = (e: any) => {
    const { value } = e.target;
    const isANumberRegex = /[0-9\b]/g;
    if (!isANumberRegex.test(value)) {
      e.target.value = "";
      return;
    }
  };

  const handleChange = (e: any) => {
    if (e.target.value !== inputValue) {
      setInputValue(e.target.value);
      onChange?.();
    }
  };
  return (
    <div className="bg-white w-16 h-20 rounded-md border border-lightBlue relative flex flex-col text-left p-2">
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onInput={handleInput}
        maxLength={1}
        ref={inputRef}
        className="bg-transparent text-4xl text-darkBlue text-center w-full h-full font-medium  focus-visible:outline-none"
      />
    </div>
  );
}

export default InputDigit;