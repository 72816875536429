/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { ReactComponent as AnchorIcon } from "../../../assets/icons/Anchor.svg";

function ScenarioCarousel({ children }: any) {
  const [activeIndex, setActiveIndex] = useState(0);  

  const updateIndex = (index: number) => {
    const totalCount = React.Children.count(children);
    if (index < 0) {
      index = totalCount - 1;
    } else if (index >= totalCount) {
      index = 0;
    }
    setActiveIndex(index);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  });

  return (
    <div {...handlers} className="overflow-hidden">
      <div
        className="flex whitespace-nowrap transition-transform duration-300 relative"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {children}
      </div>
      <div className="flex items-center justify-center p-0 mb-8 mt-5 gap-2">
        {children[1] && (
          <div
            className="flex cursor-pointer"
            onClick={() => updateIndex(activeIndex - 1)}
          >
            <AnchorIcon
              className="whitePath rotate-180 cursor-pointer py-0.5"
              height={16}
              width={18}
            />
          </div>
        )}
        {children.map((child: any, index: number) => {
          if(child !== undefined) return (
            <div
              className="flex items-center cursor-pointer py-1"
              onClick={() => updateIndex(index)}
              key={index}
            >
              <button
                key={index}
                className={`bg-white h-1.5 transition-opacity cursor-pointer
                ${
                  activeIndex === index ? "w-5 opacity-100" : "w-3 opacity-50"
                }`}
              ></button>
            </div>
          );
          
        })}
        {children[1] && (
          <div
            className="flex cursor-pointer"
            onClick={() => updateIndex(activeIndex + 1)}
          >
            <AnchorIcon
              className="whitePath cursor-pointer py-0.5"
              height={16}
              width={18}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ScenarioCarousel;
