import React, { useState, useEffect } from "react";
import PageWrap from "../../components/layout/PageWrap";
import Button from "../../components/common/button/Button";

import { ReactComponent as HomeSvg } from "../../assets/images/home.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getRedirectAfterLoginUrl,
  getUser,
  getUserRole,
  getUserSubscription,
  setRedirectAfterLoginUrl,
  setUser,
  // isFreshUser,
  // setUserAfterRegister,
} from "../../redux/reducers/userSlice";
import PopupRestricted from "../../components/auth/PopupRestricted";
import isNormalRestricted from "../../utils/isNormalRestricted";
import AccountType from "../register/components/AccountType";
import { changeAccountType, getUserProfile } from "../../client/AppClient";
import displayError from "../../utils/handling-errors/displayError";
import displaySuccess from "../../utils/handling-success/displaySuccess";

function Home() {
  const [popupOpened, setPopupOpened] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = useSelector(getUserRole);
  const subscription = useSelector(getUserSubscription);
  const user = useSelector(getUser);
  const redirectAfterLoginUrl = useSelector(getRedirectAfterLoginUrl);
  const isConsultant = userRole === "CONSULTANT";

  const handleChangeAccountType = async (type: string) => {
    const response = await changeAccountType(type);
    if (response.status !== 200) return displayError(dispatch, response);
    const userDetails = await getUserProfile();
    if (userDetails.status !== 200) return displayError(dispatch, userDetails);
    dispatch(setUser(userDetails.data));
    displaySuccess(dispatch, "Sukces", "Zapisano zmiany");
  };

  useEffect(() => {
    if (redirectAfterLoginUrl) {
      navigate(redirectAfterLoginUrl);  
      dispatch(setRedirectAfterLoginUrl(null));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const handleNavigateToClientBase = () => {
    if (isNormalRestricted(subscription)) return setPopupOpened(true);
    navigate("/scenariosbase");
  }

  if (!user?.accountType) return <AccountType setAccountType={handleChangeAccountType} />;

    return (
      <>
        <PopupRestricted
          popupOpened={popupOpened}
          setPopupOpened={setPopupOpened}
          title="Tworzenie bazy scenariuszy jest dostępne tylko dla Użytkowników Premium"
        />
        <PageWrap>
          <div className="w-full max-w-4xl px-4 mx-auto  text-center">
            <div className="flex justify-center items-center">
              <HomeSvg className="h-auto" />
            </div>
            {isConsultant && (
              <div className="mt-5 -mb-5 max-w-2xl mx-auto">
                <Link to="/consultant" className=" md:w-1/2">
                  <Button py="4" size="full">
                    Rozmowy wideo
                  </Button>
                </Link>
              </div>
            )}
            <div className="flex flex-col md:flex-row justify-center items center gap-4 mt-12 max-w-2xl mx-auto">
              <Link to="/newscenario" className=" md:w-1/2">
                <Button py="4" size="full">
                  Stwórz scenariusz spadkowy
                </Button>
              </Link>
              <span className="md:w-1/2" onClick={handleNavigateToClientBase}>
                <Button py="4" size="full">
                  Baza scenariuszy
                </Button>
              </span>
            </div>
          </div>
        </PageWrap>
      </>
    );
}

export default Home;
