import postRequestNoAuth from "../../requests/postRequestNoAuth";

export const isEmailUnique = async (email: string) => {
  const result = await postRequestNoAuth({
    url: "/user/checkEmail",
    params: {
      email: email,
    },
  });
  return result;
};
