import { ReactComponent as GoogleIcon } from "../../assets/icons/google.svg";

function SocialAuth() {
    const handleGoogleAuth = () => {
        window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=profile%20email&client_id=721113592147-6sqk65mj60t85672p979vj2k4snuprqu.apps.googleusercontent.com&response_type=code&redirect_uri=https://app.successio.pl/auth/social/google&prompt=consent`;
  
    }

    const SocialButton = () => {
      return (
        <div
          className="flex flex-row justify-center cursor-pointer items-center gap-3 py-2 px-3 rounded-lg border border-lightBlue"
          onClick={handleGoogleAuth}
        >
          <GoogleIcon width={25} />
          <span className="font-bold text-left text-base tracking-normal text-dark">
            Google
          </span>
        </div>
      );
    };

    return (
        <div className='w-full flex flex-row items-center justify-center mt-6 -mb-4' >
            <SocialButton />
        </div>
    );
}

export default SocialAuth;  