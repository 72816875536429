import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateScenarioListAnswers } from "../../../redux/reducers/scenarioSlice";
import { setNotifications } from "../../../redux/reducers/notificationsSlice";
import { inputOnlyNumbers } from "../../../validations/inputValidations";
import getSpecificFieldPlaceholder from "../helpers/specificFieldPlaceholders";
import Text from "../../../components/common/text/Text";
import NextStep from "../../../components/steps/NextStep";
import StepsInput from "../../../components/steps/StepsInput";

function ScenarioListInput({
  onSubmit,
  data,
  specialField,
  setSpecialListFields,
  specialListFields,
}: any) {
  const [values, setValues] = useState<any>({});
  const dispatch = useDispatch();

  let specificFieldValue = specialField.value;
  const searchedSpecialListField = specialListFields.find(
    (list: any) => list.fieldName === data.list.dependsOnName
  );
  if (searchedSpecialListField)
    specificFieldValue = searchedSpecialListField.value;

  const displayLocalError = (message: string) => {
    const alert = {
      title: "Błąd",
      message: message,
      type: "error",
    };
    dispatch(setNotifications(alert));
  };

  const setSpecificField = (values: any) => {
    // used for storing specific fieldName like NO_OF_CHILDREN and its value to help rendering list input
    const arrayOfValues = Object.values(values);
    if (data.list.name !== "FIELD_1") {
      setSpecialListFields((prev: any) => [
        ...prev,
        {
          fieldName: data.list.name,
          value: arrayOfValues.reduce(
            (partialSum: number, a: any) => partialSum + a,
            0
          ),
        },
      ]);
    }
  };

  const areInputsInvalid = () => {
    const invalidInputs = Object.values(values).some(
      (v) => v === undefined || v === "" || v === null
    );
    const isNumberOfValuesInvalid =
      Object.values(values).length === specificFieldValue;
    return !invalidInputs && isNumberOfValuesInvalid;
  };

  const checkIfInputsTooBig = () => {
    const invalidInputs = Object.values(values).some((v) => Number(v) >= 100);
    if (invalidInputs) return true;
    else return false;
  };

  const handleSubmit = () => {
    const areInputsTooBig = checkIfInputsTooBig();
    if (areInputsTooBig)
      return displayLocalError("Wprowadzone wartośći są za duże");
    setSpecificField(values);
    dispatch(updateScenarioListAnswers({ [data.id]: Object.values(values) }));
    onSubmit(data.list.id);
  };

  const handleOnChange = (e: any, index: number) => {
    if (e.target.value === "") {
      setValues((prev: any) => ({
        ...prev,
        [index]: "",
      }));
    } else {
      setValues((prev: any) => ({
        ...prev,
        [index]: Number(e.target.value),
      }));
    }
  };

  return (
    <NextStep
      title={"Nowy scenariusz"}
      onSubmit={handleSubmit}
      disabled={!areInputsInvalid()}
    >
      <h2 className="text-lg font-semibold text-darkBlue">
        {data?.questionText}
      </h2>
      <div className="flex flex-col gap-3 mt-5">
        {[...Array(specificFieldValue)].map((_, index) => {
          return (
            <StepsInput
              name={data?.list?.name}
              placeholder={
                getSpecificFieldPlaceholder(data.list.name) +
                "nr. " +
                (index + 1)
              }
              inputClassName="py-6"
              value={values[index] === undefined ? "" : values[index]}
              onChange={(e: any) => handleOnChange(e, index)}
              onKeyPress={inputOnlyNumbers}
              key={index}
              required
            />
          );
        })}
      </div>
      <Text>{data?.explains}</Text>
    </NextStep>
  );
}

export default ScenarioListInput;
