import postRequestNoAuth from "../../requests/postRequestNoAuth";

const url =
  window.location.protocol +
  "//" +
  window.location.host +
  "/login/resetPassword";

export const sendPasswordResetEmail = async (email: string) => {
  const result = await postRequestNoAuth({
    url: "/user/resetPassword",
    params: {
      email: email,
      url: url,
    },
  });
  return result;
};
