const enterpriseData = [
  "Wgląd w artykuły prawne",
  "Opiekun Klienta",
  "Możliwość edycji wyniku",
  "Wzory testamentów",
  "Brak limitu scenariuszy",
  "Zapis dokumentacji do PDF",
  "Tworzenie użytkowników PREMIUM",
  "Dziedziczenie ustawowego",
  "Możliwość tworzenia baz scenariuszy",
  "Scenariusze alternatywne",
];

export default enterpriseData;
