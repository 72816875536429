import React from "react";

import LegalPortionTarget from "./LegalPortionTarget";
import LegalPortionDisabled from "./LegalPortionDisabled";
import LegalPortionInheritanceShare from "./LegalPortionInheritanceShare";
import LegalPortionInheritanceValue from "./LegalPortionInheritanceValue";
import LegalPortionPastInheritances from "./LegalPortionPastInheritances";
import LegalPortionBequests from "./LegalPortionBequests";
import LegalPortionSuccess from "./LegalPortionSuccess";
import LegalPortionResult from "./LegalPortionResult";
import LegalPortionBeneficiary from "./LegalPortionBeneficiary";

interface RenderStepsInterface {
  index: number;
  onSubmit: Function;
  locationData?: any;
}

const RenderSteps = ({
  index,
  onSubmit,
}: RenderStepsInterface) => {
  switch (index) {
    case 1:
      return <LegalPortionTarget onSubmit={onSubmit} />;
    case 2:
      return <LegalPortionDisabled onSubmit={onSubmit} />;
    case 3:
      return <LegalPortionInheritanceShare onSubmit={onSubmit} />;
    case 4:
      return <LegalPortionInheritanceValue onSubmit={onSubmit} />;
    case 5:
      return <LegalPortionPastInheritances onSubmit={onSubmit} />;
    case 6:
      return <LegalPortionBequests onSubmit={onSubmit} />;
    case 7:
      return <LegalPortionBeneficiary onSubmit={onSubmit} />;
    case 8:
      return <LegalPortionSuccess onSubmit={onSubmit} />;
    case 9:
      return <LegalPortionResult />;
    default:
      return <></>;
  }
};
export default RenderSteps;
