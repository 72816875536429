export const resetPassValidation = (
  passwd: string,
  passwdRepeat: string,
) => {
  const errors: string[] = [];

  const passwordsMatch = passwd === passwdRepeat;
  const passwordLength = passwd.length >= 8;
  if (!passwordsMatch) errors.push("Hasła nie są takie same");
  if (!passwordLength) errors.push("Hasło musi mieć conajmniej 8 znaków");
  return errors;
};
