export const inputOnlyLetters = (event: any) => {
  if (!/[-a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s]/.test(event.key)) {
    event.preventDefault();
  }
};

export const inputOnlyNumbers = (event: any) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const inputZipCode = (event: any) => {
  if (!/[0-9\s-]/.test(event.key)) {
    event.preventDefault();
  }
};