import { useEffect, useRef } from "react";

interface ClickOutsideInterface {
    showInfo: boolean;
    onClickOutside: Function;
    children: JSX.Element;
}

export function ClickOutside( { showInfo ,onClickOutside, children }: ClickOutsideInterface ) {
  const ref = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  if (!showInfo) return null;

  return (
    <div ref={ref} className="contents">
      {children}
    </div>
  );
}
