import React from "react";
import { ReactComponent as ContactLawyerImg } from "../../../../../assets/images/contactlawyer.svg";

import Header from "../../../../../components/common/text/Header";
import Button from "../../../../../components/common/button/Button";
import { useNavigate } from "react-router-dom";



function ResultContactLawyer() {
  const navigate = useNavigate();
    const handleNavigate = () => {
    navigate("/");
  };
  return (
    <div className="w-full my-16">
      <div className="flex flex-col text-center items-center">
        <ContactLawyerImg className="w-full h-auto max-w-lg" />
        <div className="mt-14">
          <Header>Skontaktuj się ze specjalistą</Header>
          <p className="text-darkGrey text-lg my-5">
            <span>
              Przepraszamy, Twój przypadek wymaga bardziej szczegółowej analizy.
              Prosimy o nawiązanie kontaktu z wybranym specjalistą.
            </span>
          </p>
        </div>
        <Button onClick={handleNavigate}>Powrót do ekranu głównego</Button>
      </div>
    </div>
  );
}

export default ResultContactLawyer;
