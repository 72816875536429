import postRequest from "../../requests/postRequest";

export const twoFactorService = async (option: boolean) => {
  const result = await postRequest({
    url: "/user/update/twoFactor",
    params: {
      twoFactor: option,
    },
  });
  return result;
};
