import React from "react";

interface ShadowContentInterface {
  children: React.ReactNode;
  className?: string;
  padding: string;
}

function ShadowContentWrap({ children, className, padding }: ShadowContentInterface) {
  return (
    <div
      className={`sm:bg-white sm:shadow-container rounded-lg sm:p-${padding} mt-5 ${className}`}
    >
      {children}
    </div>
  );
}

ShadowContentWrap.defaultProps = {
  padding: "10",
};

export default ShadowContentWrap;
