import React from "react";
import classnames from "classnames";

interface ButtonInterface {
  id?: string;
  py?: string;
  size?: "sm" | "md" | "lg" | "full";
  font?: "normal" | "semibold" | "bold";
  children: any;
  valid: boolean;
  type?: any;
  onSubmit?: any;
  onClick?: any;
}

function Button({
  id,
  size,
  py,
  valid,
  font,
  ...rest
}: ButtonInterface) {
  return (
    <button
      id={id}
      className={classnames(
        `text-white py-${py} inline-block rounded-lg font-${font}`,
        {
          "bg-mediumGrey": valid === false,
          "pointer-events-none": valid === false,
          "cursor-not-allowed": valid === false,
          "bg-darkBlue": valid === true,
          "pointer-events-auto": valid === true,
          "px-5": size === "sm",
          "px-16": size === "md",
          "px-15": size === "lg",
          "w-full": size === "full",
        }
      )}
      {...rest}
    />
  );
}

Button.defaultProps = {
  valid: false,
  py: "3",
  size: "md",
  font: "regular",
};

export default Button;
