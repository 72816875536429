import React, { useState, useEffect } from "react";

import RadioSelect from "../common/forms/RadioSelect";
import ScenarioInput from "./StepsInput";
import WealthInput from "./WealthInput";

interface ExpandableInputInterfce {
  value: string | number;
  setValue: Function;
  name: any;
  id: string;
  type?: "currency" | "string";
  maxLength?: number;
  onKeyPress?: any;
  closeHelper?: any;
  setCloseHelper?: Function;
  onClick?: Function;
}

function ExpandableInput({
  value,
  setValue,
  name,
  id,
  type,
  closeHelper,
  setCloseHelper,
  onClick,
  ...rest
}: ExpandableInputInterfce) {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    const autoFill = () => {
      const valueToPass = value;
      setOpened(true);
      setTimeout(() => {
        setValue(Number(valueToPass));
      }, 200);
    };
    if (value) autoFill();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (closeHelper) {
      setOpened(false);
    }
  }, [closeHelper]);

  useEffect(() => {
    if (type === "string") {
      setValue("");
    } else {
      setValue();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <div className="flex flex-col">
      <RadioSelect
        id={id + "select"}
        group={id}
        onClick={() => setOpened((prev) => !prev)}
        value={opened.toString()}
        selected={"true"}
      >
        {name}
      </RadioSelect>
      {opened && type === "currency" && (
        <WealthInput
          name={name}
          placeholder="Wpisz wartośc..."
          inputClassName="py-6"
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
          required
          {...rest}
        />
      )}
      {opened && type === "string" && (
        <ScenarioInput
          name={name}
          placeholder="Wpisz wartośc..."
          inputClassName="py-6"
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
          required
          {...rest}
        />
      )}
    </div>
  );
}

ExpandableInput.defaultProps = {
  type: "currency",
};

export default ExpandableInput;
