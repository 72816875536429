import postRequest from "../../requests/postRequest";

interface passwordResetService {
  newPassword: string;
  reNewPassword: string;
  oldPassword: string;
}

export const changePasswordService = async (body: passwordResetService) => {
  const result = await postRequest({
    url: "/user/changePassword",
    params: {
      oldPassword: body.oldPassword,
      newPassword: body.newPassword,
      reNewPassword: body.reNewPassword,
    },
  });
  return result;
};
