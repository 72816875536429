import React from 'react';
import { useDispatch } from 'react-redux';
import { sendTokenEmail } from '../../../client/AppClient';
import Form from '../../../components/common/forms/Form';
import Input from '../../../components/common/forms/Input';
import displayError from '../../../utils/handling-errors/displayError';
import { validateEmail } from '../../../validations/globalValidations';


const ResetForm = ({ email, setEmail, setIsSent }: any) => {
  const dispatch = useDispatch();
  const handlePasswordReset = async () => {
    // ADD API REQUEST TO RESET PASSWORD
    const response = await sendTokenEmail(email);
    if(response.status !== 200) return displayError(dispatch, response);
    setIsSent(true);
  };
  return (
    <div>
      <p className="text-sm mt-5 mb-14 text-darkGrey">
        Wprowadź swój adres e-mail, aby zresetowac haslo
      </p>
      <Form
        id="forgetPass"
        submitBtn="Zresetuj haslo"
        onSubmit={handlePasswordReset}
        disabled={!validateEmail(email)}
      >
        <Input
          label="E-mail"
          name="E-mail"
          type="text"
          placeholder="Wpisz e-mail"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          required
        />
      </Form>
    </div>
  );
};

export default ResetForm;