import postRequest from "../../requests/postRequest";

export const getSavedScenarioService = async (scenarioId: string) => {
  const result = await postRequest({
    url: `/scenario/${scenarioId}/details`,
    params: {
      scenarioId: scenarioId,
    },
  });
  return result;
};
