import React from 'react';
import ShadowContentWrap from '../../../components/layout/ShadowContentWrap';
import Button from '../../../components/common/button/Button';

function LegalPortionWrap({ handleNavigateToPortion }: any) {
  return (
    <ShadowContentWrap>
      <h4 className="text-lg text-darkBlue font-semibold">
        Pamiętaj o zachowku!
      </h4>
      <p className="text-darkGrey mt-3 mb-8">
        Najbliżsi krewni spadkodawcy są chronieni przed byciem pominiętym w
        testamencie.
      </p>
      <div className="flex justify-center items-center">
        <Button
          textColor="darkBlue"
          bgColor="transparent"
          onClick={handleNavigateToPortion}
        >
          Wylicz zachowek
        </Button>
      </div>
    </ShadowContentWrap>
  );
}

export default LegalPortionWrap;