import { signIn } from "./services/auth/loginService";
import { signUp } from "./services/user/registerService";
import { userProfile } from "./services/user/userProfileService";
import { refreshTokenService } from "./services/auth/refreshTokenService";
import { sendPasswordReset } from "./services/password-reset/passResetService";
import { sendPasswordResetEmail } from "./services/password-reset/passResetEmailService";
import { isEmailUnique } from "./services/user/emailValidationService";
import { accountType } from "./services/user/accountTypeService";
import { clientBaseRequest } from "./services/clients/clientBaseService";
import { clientDataRequest } from "./services/clients/clientDataService";
import { addClientService } from "./services/clients/addClientService";
import { twoFactorService } from "./services/user/updateTwoFactor";
import { logoutService } from "./services/auth/logoutService";
import { editClientService } from "./services/clients/editClientService";
import { changePasswordService } from "./services/user/changePasswordService";
import { scenarioListService } from "./services/scenario/scenarioListService";
import { updateFactureService } from "./services/factures/updateFactureService";
import { editAccountService } from "./services/user/editAccountService";
import { verifyPhoneService } from "./services/auth/verifyPhoneService";
import { verifyResetTokenService } from "./services/password-reset/verifyResetTokenService";
import { subscriptionDetailsService } from "./services/subscriptions/subDetailsService";
import { subscriptionRenewService } from "./services/subscriptions/subRenewService";
import { alternativeScenariosService } from "./services/scenario/alternativeScenariosService";
import { agoraTokenService } from "./services/agora/agoraTokenService";
import { agoraCallService } from "./services/agora/agoraCallService";
import { agoraRefreshTokenService } from "./services/agora/agoraRefreshTokenService";
import { downloadFileService } from "./services/scenario/downloadFileService";
import { requestContactService } from "./services/agora/requestContactService";
import { nextQuestionService } from "./services/scenario/nextQuestionService";
import { saveScenarioService } from "./services/scenario/saveScenarioService";
import { getScenarioResultService } from "./services/scenario/getScenarioResultServices";
import { getSavedScenarioService } from "./services/scenario/getSavedScenarioService";
import { transactionStatusService } from "./services/subscriptions/transactionStatusService";
import { transactionListService } from "./services/subscriptions/transactionListService";
import { createUserService } from "./services/enterprise/createUserService";
import { enterpriseUserListService } from "./services/enterprise/enterpriseUserListService";
import { changeUserStatusService } from "./services/enterprise/changeUserStatusService";
import { editEnterpriseUserService } from "./services/enterprise/editEnterpriseUserService";
import { subEnterpriseExtendService } from "./services/subscriptions/subEnterpriseExtendService";
import { twoFactorLoginService } from "./services/auth/twoFactorLoginService";
import { checkScenarioLimitService } from "./services/scenario/checkScenarioLimitService";
import { socialAuthService } from "./services/auth/socialAuthService";

// LOGIN
export async function loginRequest(data: any) {
    return await signIn(data);
}
export async function twoFactorLoginRequest(data: any) {
    return await twoFactorLoginService(data);
}
export async function refreshTokenCall(token: string) {
    return await refreshTokenService(token);
}
export async function logoutRequest() {
    return await logoutService();
}
export async function updateVerifyPhone(option: boolean) {
    return await verifyPhoneService(option)
}

export async function socialAuthRequest(provider: string, token: string) {
  return await socialAuthService(provider, token);
}

// REGISTER
export async function registerRequest(data: any) {
    return await signUp(data);
}

export async function emailUniqueRequest(email: string) {
  return await isEmailUnique(email);
}

// USER
export async function getUserProfile() {
  return await userProfile();
}

export async function changeAccountType(type: string) {
    return await accountType(type);
}

export async function updateTwoFactor(option: boolean) {
    return await twoFactorService(option);
}

export async function changePassword(data: any) {
    return await changePasswordService(data);
}

export async function editAccount(data: any) {
    return await editAccountService(data);
}

// PASSWORD RESET
export async function resetPassword(data: any) {
    return await sendPasswordReset(data);
}

export async function sendTokenEmail(email: string) {
    return await sendPasswordResetEmail(email);
}

export async function verifyResetToken(token: string) {
    return await verifyResetTokenService(token);
}

// CLIENTS
export async function getClientBase(
  currentPage?: number,
  itemsPerPage?: number,
  sort?: number,
  filter?: string
) {
  return await clientBaseRequest(currentPage, itemsPerPage, sort, filter);
}

export async function addClient(data: any) {
    return await addClientService(data);
}

export async function getClientData(clientData: string) {
    return await clientDataRequest(clientData);
}

export async function editClient(data: any) {
    return await editClientService(data);
}

// FACTURES

export async function updateFacture(data: any) {
    return await updateFactureService(data);
}

// SCENARIOS

export async function getScenarioList(clientId: string) {
    return await scenarioListService(clientId);
}

export async function getAlternativeScenarios() {
    return await alternativeScenariosService();
}

export async function nextQuestion(questionId?: string, answerId?: string) {
    return await nextQuestionService(questionId, answerId);
}

export async function saveScenario(body: any, clientId: string, scenarioId?: string) {
    return await saveScenarioService(body, clientId, scenarioId);
}

export async function getScenarioResult(body: any) {
    return await getScenarioResultService(body);
}

export async function getSavedScenario(scenarioId: string) {
    return await getSavedScenarioService(scenarioId);
}

export async function checkScenarioLimit() {
    return await checkScenarioLimitService();
}

// SUBSCRIPTIONS

export async function getSubscriptionDetails() {
  return await subscriptionDetailsService();
}

export async function renewSubscription(
  subscriptionType: string,
  numberOfUsers?: number
) {
  return await subscriptionRenewService(subscriptionType, numberOfUsers);
}

export async function downloadFile(fileId: string) {
    return await downloadFileService(fileId);
}

export async function getTransactionStatus(transactionId: string) {
    return await transactionStatusService(transactionId);
}

export async function getTransactionsList(currentPage: number, itemsPerPage: number) {
    return await transactionListService(currentPage, itemsPerPage);
}

export async function extendEnterpriseSubscription(numberOfUsers: number) {
    return await subEnterpriseExtendService(numberOfUsers);
}

// AGORA

export async function getAgoraToken(channelId?: string) {
    return await agoraTokenService(channelId)
} 

export async function requestCall(role: string) {
    return await agoraCallService(role);
}

export async function refreshAgoraToken(channelId: string) {
    return await agoraRefreshTokenService(channelId);
}

export async function requestContact(role: string) {
    return await requestContactService(role);
}

// ENTERPRISE

export async function getEnterpriseUserList(
  currentPage?: number,
  itemsPerPage?: number,
  sort?: number,
  status?: string,
  searchValue?: string
) {
  return await enterpriseUserListService(
    currentPage,
    itemsPerPage,
    sort,
    status,
    searchValue
  );
}

export async function createEnterpriseUser(body: any) {
    return await createUserService(body);
}

export async function changeUserStatus(id: string, status: string) {
    return await changeUserStatusService(id, status);
}

export async function editEnterpriseUser(id: string, body: any) {
    return await editEnterpriseUserService(id, body);
}