import React from 'react';
import { View, Text } from "@react-pdf/renderer";
import styles from '../styles/PdfStyles';

interface IPDFDescription {
    title: string;
    description: string;
}

function PdfDescription({ title, description }: IPDFDescription) {
  return (
    <View style={styles.section}>
      <View style={styles.descriptionWrap}>
        <Text style={styles.header}>Szczegóły scenariusza</Text>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.description}>{description}</Text>
      </View>
    </View>
  );
}

export default PdfDescription;