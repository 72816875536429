import React, { useState } from "react";

import { useDispatch } from "react-redux";
import {
  updateAdditionalValues,
  updateAdditionalValuesInfo,
} from "../../../redux/reducers/partionSlice";

import Accordion from "../../../components/common/controls/Accordion";
import RadioSelect from "../../../components/common/forms/RadioSelect";
import NextStep from "../../../components/steps/NextStep";
import ExpandableInputGroup from "../components/ExpandableInputGroup";
import inputCurrencyToNumber from "../../../utils/inputCurrencyToNumber";

function LegalPortionPastInheritances({ onSubmit }: any) {
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (value !== undefined) {
      const valueNumber = inputCurrencyToNumber(value);
      if (selectedValue === "yes") {
        dispatch(updateAdditionalValuesInfo({ isPastInheritances: true }));
      }
      dispatch(updateAdditionalValues(valueNumber));
      onSubmit();
    }
  };

  return (
    <NextStep
      title={"Zachowek"}
      onSubmit={handleSubmit}
      disabled={value === undefined || value === ""}
    >
      <h2 className="text-lg font-semibold text-darkBlue">
        Czy Osoba dla której dziedziczenie sprawdzasz dokonywała w przeszłości
        darowizn?
      </h2>
      <div className="flex flex-col gap-3 mt-5">
        <ExpandableInputGroup
          group="legalportionpast"
          name="Tak"
          value={value}
          setValue={setValue}
          selectValue="yes"
          setSelected={setSelectedValue}
          selected={selectedValue}
        />
        <RadioSelect
          id="legalportionpastno"
          group="legalportionpast"
          selected={selectedValue}
          value="0"
          onClick={(e: any) => {
            setValue(e.target.value);
            setSelectedValue(e.target.value);
          }}
        >
          Nie
        </RadioSelect>
      </div>
      <p className="text-darkGrey mt-5 mb-7">
        Jeśli tak, wpisz ile te darowizny wynosiły.
      </p>
      <Accordion title="Darowizny - dodatkowe informacje">
        <p className="text-darkGrey">
          Wartość darowizny ustala się według stanu chwili jej dokonania, ale
          według cen z chwili ustalenia zachowku.
          <br />
          <br />
          Do spadku nie zalicza się drobnych darowizn, zwyczajowo w danych
          stosunkach przyjętych, a więc np. prezentów urodzinowych, ślubnych czy
          z okazji awansu.
          <br />
          <br />
          Do spadku nie zalicza się darowizn na rzecz osoby trzeciej lub
          nieuprawnionej do zachowku, jeśli upłynęło minimum 10 lat od tej
          darowizny.
          <br />
          <br />
          Obliczając zachowek dla zstępnego, nie dolicza się darowizn
          uczynionych przez spadkodawcę w czasie, gdy nie miał on zstępnych (do
          300 dni przed urodzeniem).
        </p>
      </Accordion>
    </NextStep>
  );
}

export default LegalPortionPastInheritances;
