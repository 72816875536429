import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getUserAccountType } from "../../../redux/reducers/userSlice";
import { getScenarioResult } from "../../../redux/reducers/scenarioResultSlice";
import { renewSubscription } from "../../../client/AppClient";
import { inputOnlyNumbers } from "../../../validations/inputValidations";
import displayError from "../../../utils/handling-errors/displayError";
import enterpriseData from "../data/enterpriseData";
import PageWrap from "../../../components/layout/PageWrap";
import Header from "../../../components/common/text/Header";
import InputBordered from "../../../components/common/forms/InputBordered";
import ShadowContentWrap from "../../../components/layout/ShadowContentWrap";
import Button from "../../../components/common/button/Button";
import TransactionStatus from "../../../components/payments/TransactionStatus";
import { ReactComponent as Anchor } from "../../../assets/icons/AnchorBlue.svg";
import { ReactComponent as CheckSvg } from "../../../assets/icons/CheckGold.svg";

const transactionUrl = process.env.REACT_APP_TRANSACTION_URL;

function ChooseEnterprise() {
  const [userCount, setUserCount] = useState("");
  const [enterprisePrice, setEnterprisePrice] = useState(5900);
  const [isTransactionStarted, setIsTransactionStarted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const scenarioDetails = useSelector(getScenarioResult);
  const accountType = useSelector(getUserAccountType);
  const userDetails = useSelector(getUser);

  useEffect(() => {
    // if user have changed price per user it will be updated
    if (userDetails?.subscription.pricePerUser) {
      setEnterprisePrice(userDetails?.subscription.pricePerUser);
    }
  }, [userDetails]);

  const saveScenarioDetails = () => {
    // used when navigated from scenario-page to save redux content to localStorage
    if (
      location?.state?.navigatedFrom !== "/scenario/result" ||
      Object.keys(scenarioDetails).length === 0
    )
      return;
    localStorage.setItem("scenarioDetails", JSON.stringify(scenarioDetails));
  };

  const saveRedirectLink = () => {
    const path = location.state?.navigatedFrom;
    if (path === undefined) return;
    localStorage.setItem("redirectPath", path);
  };

  const handleStartEnterpriseTransaction = async () => {
    const response = await renewSubscription("ENTERPRISE", Number(userCount));
    if (response.status !== 200) return displayError(dispatch, response);
    setIsTransactionStarted(true);
    saveScenarioDetails();
    saveRedirectLink();
    const token = response.data.transactionToken;
    window.location.href = transactionUrl + token;
  };

  const handleBackToPlans = () => {
    navigate("/plans", {
      state: { navigatedFrom: location.state?.navigatedFrom },
    });
  };

  const handleUpdateCounter = (value: number) => {
    const updatedNumber = `${Number(userCount) + value}`;
    if (Number(updatedNumber) >= 0) setUserCount(updatedNumber);
  };

  const handlePlanChoose = () => {
    if (accountType === "PRIVATE") return;
    handleStartEnterpriseTransaction();
  };

  if (isTransactionStarted) return <TransactionStatus />;

  return (
    <PageWrap>
      <div className="w-full max-w-6xl mx-auto">
        <ShadowContentWrap>
          <div className={`text-center items-center mt-5 mb-10`}>
            <Header>Pakiet Enterprise</Header>
          </div>
          <div className="flex flex-row gap-5 mt-5 justify-around flex-wrap">
            {enterpriseData.map((text, index) => {
              return (
                <div
                  className="flex flex-row gap-3 items-center w-full md:w-1/3 lg:w-1/3"
                  key={index}
                >
                  <CheckSvg />
                  <span className="text-darkGrey w-4/5">{text}</span>
                </div>
              );
            })}
          </div>
        </ShadowContentWrap>
        <ShadowContentWrap>
          <div className="flex flex-row items-center mt-10 mb-28">
            <span className="text-lg text-darkBlue">Ilość Użytkowników</span>
            <div className="relative w-2/3 mx-14">
              <InputBordered
                label=""
                name="name"
                type="text"
                divClassName=""
                inputClassName="text-right text-greyBlue"
                placeholder={"0"}
                value={userCount}
                onChange={(e: any) => setUserCount(e.target.value)}
                onKeyPress={inputOnlyNumbers}
                required
              />
              <span className="absolute right-0 mt-6 text-greyBlue">
                {(enterprisePrice * Number(userCount)) / 100 + " zł"}
              </span>
            </div>
            <div className="flex flex-col">
              <button>
                <Anchor
                  className="cursor-pointer"
                  onClick={() => handleUpdateCounter(1)}
                />
              </button>
              <button>
                <Anchor
                  className="cursor-pointer rotate-180"
                  onClick={() => handleUpdateCounter(-1)}
                />
              </button>
            </div>
          </div>
          <div className="flex flex-col md:flex-row mx-auto justify-center gap-3 w-full md:w-1/2">
            <Button
              size="full"
              onClick={handleBackToPlans}
              bgColor="transparent"
              textColor="darkBlue"
            >
              Powrót
            </Button>
            <Button
              size="full"
              onClick={handlePlanChoose}
              disabled={userCount === "0" || !Boolean(userCount) || accountType === "PRIVATE"}
            >
              Dokonaj płatności
            </Button>
          </div>
        </ShadowContentWrap>
        <div className="bg-gradient-to-t from-sherpaBlue to-darkBlue p-3 sm:p-7 mt-10 sm:mb-16 rounded-lg">
          <div className="px-7 py-7 bg-white rounded-lg">
            <h5 className="text-lg text-darkBlue font-semibold">PAMIĘTAJ!</h5>
            <p className="text-darkGrey mt-3">
              W trakcie trwania okresu rozliczeniowego opłacona ilość
              Użytkowników nie może zostać zmniejszona.
              <br />
              Możliwość zmniejszenia liczby Użytkowników będzie dostępna w
              ramach kolejnej subskrybcji.
            </p>
          </div>
        </div>
      </div>
    </PageWrap>
  );
}

export default ChooseEnterprise;
