const inputCurrencyToNumber = (price: string) => {
  // get value from input -> split to integer and decimal -> format to number -> sum up
  if (price === undefined) return 0;
  let number = 0,
    integerString = "0",
    decimalString = "0",
    divide = 10;
  const splitted = price.split(",");
  if (splitted[0] !== undefined) {
    integerString = splitted[0].replace(/[zł. ]+/g, "");
  }
  if (splitted[1] !== undefined) {
    decimalString = splitted[1].replace(/[zł. ]+/g, "");
    if (decimalString.length === 1) divide = 10;
    if (decimalString.length === 2) divide = 100;
  }
  number = Number(integerString) + Number(decimalString) / divide;
  return number;
};

export default inputCurrencyToNumber;
