const isApiDateOlderThanToday = (parsedDate: string) => {
  const givenDate = new Date(parsedDate);
  givenDate.setDate(givenDate.getDate() + 3);
  const givenDateTime = givenDate.getTime();
  const today = new Date().getTime();

  if (givenDateTime > today) return true;
  return false;
};

export default isApiDateOlderThanToday;
