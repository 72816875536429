export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isFullName = (word: string) => {
    return /^[A-Z][a-z]+\s[A-Z][a-z]+$/.test(word);
}

export const isOnlyNumbers = (word: string) => {
    return /^\d+$/.test(word);
}


export const handleApiErrorCode = (errCode: string | undefined) => {
  switch (errCode) {
    case "ERR_NETWORK":
      return "Network error"
    case "ERR_BAD_REQUEST":
      return "Wrong login"
    default:
      return "Something went wrong"
  }
}