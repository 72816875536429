import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetPassword, verifyResetToken } from '../../client/AppClient';
import Form from '../../components/common/forms/Form';
import Input from '../../components/common/forms/Input';
import PageWrap from '../../components/layout/PageWrap';
import { setNotifications } from '../../redux/reducers/notificationsSlice';
import { resetPassValidation } from '../../validations/resetPassValidation';
import displayApiError from '../../utils/handling-errors/displayError';
import { useNavigate } from 'react-router-dom';
import NoAccess from '../../components/layout/NoAccess';
import LoadingSpinner from '../../components/specific/LoadingSpinner';
import displaySuccess from '../../utils/handling-success/displaySuccess';

function ResetPassword() {
    const [token, setToken] = useState<any>(null);
    const [newPass, setNewPass] = useState("");
    const [passRepeat, setPassRepeat] = useState("");
    const [noAccess, setNoAccess] = useState(false);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validateToken = async (token: any) => {
      if(!token) return (setNoAccess(true), setLoading(false))
      const response = await verifyResetToken(token);
      setLoading(false);
      if (response.status !== 200) return setNoAccess(true);;
      setToken(token);
    }

    useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const tokenFromUrl = searchParams.get("token");
      validateToken(tokenFromUrl);
    }, [])

    const redirectWithDelay = () => {
      setTimeout(() => {
        navigate("/login");
      }, 1000)
    }

    const handleResetPassword = async () => {
        const validPasswords = validatePasswords();
        if(!validPasswords) return;
        const data = {
          password: newPass,
          repassword: passRepeat,
          token: token,
        };
        const response = await resetPassword(data);
        if(response.status !== 200) return displayApiError(dispatch, response);
        
        displaySuccess(dispatch, "Sukces", "Hasło zresetowane!");
        localStorage.removeItem("resetEmail");
        redirectWithDelay();
    }

    const displayLocalError = (message: string) => {
      const alert = {
        title: "Błąd",
        message: message,
        type: "error"
      };
      dispatch(setNotifications(alert));
    }

    const validatePasswords = () => {
      const errors = resetPassValidation(newPass, passRepeat);
      if (errors.length === 0) return true;
      errors.forEach((el) => displayLocalError(el));
      return false;
    }

    const checkIfNotEmpty = () => {
        if(newPass.length > 0 && passRepeat.length > 0) {
            return false;
        }
        return true;
    }

    if(loading) {
      return <LoadingSpinner />
    }
    else if (noAccess && !loading) {
      return (
        <NoAccess
          title="Brak dostępu strony"
          description="Token do zresetowania hasła jest nieprawidłowy lub stracił ważność."
        />
      );
    }
    else return (
      <PageWrap>
        <div className="w-full max-w-sm px-4 mx-auto pt-20 text-center">
          <h1 className="text-3xl text-darkBlue font-medium">Zresetuj hasło</h1>
          <Form
            id="passreset"
            submitBtn={"Ustaw hasło"}
            onSubmit={handleResetPassword}
            disabled={checkIfNotEmpty()}
          >
            <Input
              label="Nowe hasło"
              name="newpasswd"
              type="password"
              placeholder="Wpisz nowe hasło"
              divClassName="my-8"
              value={newPass}
              onChange={(e: any) => setNewPass(e.target.value)}
              required
            />
            <Input
              label="Powtórz hasło"
              name="rptpasswd"
              type="password"
              placeholder="Powtórz hasło"
              divClassName="my-8"
              value={passRepeat}
              onChange={(e: any) => setPassRepeat(e.target.value)}
              required
            />
          </Form>
        </div>
      </PageWrap>
    );
}

export default ResetPassword;