function getClosestFraction(number: number) {
  const maxDenominator = 1000; // Define the maximum denominator to limit precision
  let numerator = 1;
  let denominator = 1;
  let error = Math.abs(number - numerator / denominator);

  for (let d = 2; d <= maxDenominator; d++) {
    const n = Math.round(number * d);
    const e = Math.abs(number - n / d);

    if (e < error) {
      numerator = n;
      denominator = d;
      error = e;

      // If the error is less than or equal to 1e-10, exit the loop
      if (error <= 1e-10) {
        break;
      }
    }
  }
  return numerator + "/" + denominator;
}

export default getClosestFraction;