import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/common/button/Button';

function AlternativeScenariosWrap({ data }: any) {
    return (
      <>
        <h4 className="text-lg text-darkBlue font-semibold">
          Nie podoba Ci się dziedziczenie ustawowe?
        </h4>
        <p className="text-darkGrey mt-3 mb-8">
          Sprawdź, jakie mogą być scenariusze alternatywne w Twojej sytuacji.{" "}
        </p>
        <div className="flex justify-center items-center">
          <Link to={`/alternativeScenarios`} state={{ scenarioData: data }}>
            <Button textColor="darkBlue" bgColor="transparent">
              Sprawdź scenariusze
            </Button>
          </Link>
        </div>
      </>
    );
}

export default AlternativeScenariosWrap;