import React from "react";
import NextStep from "../../../components/steps/NextStep";

function LoadingStep() {
  return (
    <NextStep title={"Nowy scenariusz"}>
      <div className="flex flex-col justify-center items-center gap-3 mt-5 min-h-[300px]">
        <div
          className="relative w-10 h-10 animate-spin
                before:w-5 before:h-5 before:bg-darkBlue before:absolute before:top-1/4 before:right-3/4 before:rounded-full
                after:w-5 after:h-5 after:bg-sherpaBlue after:absolute after:top-1/4 after:left-3/4 after:rounded-full
            "
        ></div>
      </div>
    </NextStep>
  );
}

export default LoadingStep;
