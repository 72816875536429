import React from "react";
import { ReactComponent as CheckedIcon } from "../../../assets/icons/CheckGold.svg";
import { ReactComponent as XIcon } from "../../../assets/icons/X.svg";
import Button from "../../../components/common/button/Button";
import { PlansContentInterface } from "./types";


function PlansMobile({
  plansData,
  choosePlan,
  accountType,
}: PlansContentInterface) {
  const RenderIcon = ({ includes }: { includes: boolean }) => {
    if (!includes) {
      return <XIcon height={14} />;
    }
    return <CheckedIcon height={14} />;
  };

  const HeaderCell = ({ children }: any) => {
    return <td className="first:pl-5 last:pr-5 py-2">{children}</td>;
  };

  const DataCellText = ({ children }: any) => {
    return <td className="first:pl-5 last:pr-5 w-1/2 py-4 pr-4">{children}</td>;
  };

  const DataCell = ({ children }: any) => {
    return <td className=" py-4">{children}</td>;
  };

  const TableRow = ({ children }: any) => {
    return <tr className="odd:bg-light even:bg-white">{children}</tr>;
  };

  return (
    <div className="flex flex-col mt-4 mb-8">
      <table className="text-darkGrey table-fixed text-sm">
        <thead>
          <tr>
            <HeaderCell></HeaderCell>
            <HeaderCell>Pakiet Podstawowy</HeaderCell>
            <HeaderCell>Pakiet Premium</HeaderCell>
          </tr>
        </thead>
        <tbody>
          {plansData.features.map((el, key) => {
            return (
              <TableRow key={key}>
                <DataCellText>{el.name}</DataCellText>
                <DataCell>
                  {" "}
                  <RenderIcon includes={el.base} />{" "}
                </DataCell>
                <DataCell>
                  {" "}
                  <RenderIcon includes={el.base} />{" "}
                </DataCell>
              </TableRow>
            );
          })}
          <TableRow>
            <DataCellText>{plansData.price.name}</DataCellText>
            <DataCell>{plansData.price.base}</DataCell>
            <DataCell>{plansData.price.premium}</DataCell>
          </TableRow>
        </tbody>
      </table>
      <div className="flex flex-col px-5 mt-10">
        <div className="flex flex-col gap-4">
          <Button py="4" onClick={() => choosePlan("PREMIUM")}>
            Wybieram Pakiet Premium
          </Button>
          <Button
            py="4"
            textColor="darkBlue"
            bgColor="transparent"
            onClick={() => choosePlan("NORMAL")}
          >
            Wybieram Wersję Podstawową
          </Button>
        </div>
        {accountType === "PROFESSIONAL" && (
          <div className="flex flex-col gap-2 mt-8">
            <span className="text-darkBlue font-semibold text-lg">
              Zakup enterprise
            </span>
            <p className="text-darkGrey mb-4">
              Propozycje dopasowane do potrzeb Twojego biznesu, skontaktuj się z
              nami
            </p>
            <Button
              py="4"
              textColor="darkBlue"
              bgColor="transparent"
              onClick={() => choosePlan("ENTERPRISE")}
            >
              Wybieram Enterprise
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PlansMobile;
