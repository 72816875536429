import setJwt from "../../helpers/setJwt";
import postRequestNoAuth from "../../requests/postRequestNoAuth";



interface signUpInterface {
  email: string;
  password: string;
  fullname: string;
  phone: string;
  accountType: string;
  marketing: boolean;
  billingDetails?: {
    isCompany: true;
    name: string;
    nip: string;
    address: string;
    zipCode: string;
    city: string;
  };
}

export const signUp = async (body: signUpInterface) => {
    const result = await postRequestNoAuth({
      url: "/user/register",
      params: {
        email: body.email,
        password: body.password,
        fullname: body.fullname,
        phone: body.phone,
        accountType: body.accountType,
        allowMarketing: body.marketing,
        billingDetails: body?.billingDetails,
      },
    });
    if(result.status === 200) setJwt(result.data);
    return result;
}

