import postRequest from "../../requests/postRequest";


interface addClientInterface {
  fullName: string;
  additionalData: string;
  description?: string;
}

export const addClientService = async (body: addClientInterface) => {
  const result = await postRequest({
    url: "/client/add",
    params: {
      fullName: body.fullName,
      additionalData: body.additionalData,
      description: body.description,
    },
  });
  return result;
};
