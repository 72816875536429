import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  savedScenario: null,
  newScenario: {
    name: null,
    wealth: {
      total: 0,
      parts: {
        estate: 0,
        companies: 0,
        vehicles: 0,
        savings: 0,
        investments: 0,
        other: 0,
      },
      debts: {
        loans: 0,
        credits: 0,
        overduePayments: 0,
        other: 0,
      },
    },
    numericAnswers: {},
    choiceAnswers: {},
    numericListAnswers: {},
  },
};

export const scenarioEditSlice = createSlice({
  name: "scenarioEdit",
  initialState,
  reducers: {
    updateEditScenario: (state, action) => {
      state.savedScenario = action.payload;
    },
    clearEditScenario: () => initialState,
    updateEditScenarioName: (state, action) => {
      state.newScenario.name = action.payload;
    },
    updateEditScenarioWealth: (state, action) => {
      state.newScenario.wealth.total = action.payload;
    },
    substractScenarioEditWealth: (state, action) => {
      state.newScenario.wealth.total =
        state.newScenario.wealth.total - action.payload;
    },
    updateScenarioEditWealthParts: (state, action) => {
      state.newScenario.wealth.parts = action.payload;
    },
    updateScenarioEditDebts: (state, action) => {
      state.newScenario.wealth.debts = action.payload;
    },
    updateScenarioEditNumericAnswers: (state, action) => {
      state.newScenario.numericAnswers = { ...state.newScenario.numericAnswers, ...action.payload };
    },
    updateScenarioEditListAnswers: (state, action) => {
      state.newScenario.numericListAnswers = {
        ...state.newScenario.numericListAnswers,
        ...action.payload,
      };
    },
    updateScenarioEditChoiceAnswers: (state, action) => {
      state.newScenario.choiceAnswers = { ...state.newScenario.choiceAnswers, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearEditScenario,
  updateEditScenario,
  updateScenarioEditChoiceAnswers,
  updateScenarioEditListAnswers,
  updateScenarioEditNumericAnswers,
  updateScenarioEditDebts,
  updateScenarioEditWealthParts,
  substractScenarioEditWealth,
  updateEditScenarioWealth,
  updateEditScenarioName,
} = scenarioEditSlice.actions;
export const getEditScenario = (state: RootState) => state.scenarioEdit.savedScenario;
export const getNewEditScenario = (state: RootState) => state.scenarioEdit.newScenario;
export default scenarioEditSlice.reducer;
