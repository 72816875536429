import React from "react";
import { useDispatch } from "react-redux";
import { getUserProfile, updateTwoFactor } from "../../client/AppClient";
import { setUser } from "../../redux/reducers/userSlice";
import displayError from "../../utils/handling-errors/displayError";
import displaySuccess from "../../utils/handling-success/displaySuccess";
import Button from "../common/button/Button";
import Popup from "./Popup";

function TwoFactorPopup({ isTwoFactor, triggerPopup, setTriggerPopup }: any) {
  const dispatch = useDispatch();

  const handleUpdateTwoFactor = async (turnOn: boolean) => {
    // api call
    const response = await updateTwoFactor(turnOn);
    if (response.status !== 200) {
      displayError(dispatch, response);
      setTriggerPopup(false);
      return;
    }
    displaySuccess(
      dispatch,
      "Zapisano",
      `${turnOn ? "Włączono" : "Wyłączono"} weryfikacje dwuetapową `
    );
    setTriggerPopup(false);
    const userDetails = await getUserProfile();
    if (userDetails.status !== 200) return displayError(dispatch, userDetails);
    dispatch(setUser(userDetails.data));
  };

  return (
    <Popup
      title="Weryfikacja dwuetapowa"
      trigger={triggerPopup}
      setTrigger={setTriggerPopup}
    >
      {isTwoFactor && (
        <div className="flex flex-col">
          <p className="text-darkGrey text-center">
            Czy na pewno chcesz wyłączyć weryfikację dwuetapową? Zmniejszy to
            bezpieczeństwo twojego konta Successio.
          </p>
          <div className="max-w-xl flex flex-col gap-3 sm:gap-0 sm:flex-row justify-around mt-8">
            <Button onClick={() => handleUpdateTwoFactor(false)}>Tak</Button>
            <Button onClick={() => setTriggerPopup(false)}>Nie</Button>
          </div>
        </div>
      )}
      {!isTwoFactor && (
        <div className="flex flex-col">
          <p className="text-darkGrey text-center">
            Czy chcesz uruchomić weryfikację dwuetapową? Przy kolejnej próbie
            logowania zostanie przesłany kod weryfikacyjny na Twój adres e-mail.
          </p>
          <div className="max-w-xl flex flex-col gap-3 sm:gap-0 sm:flex-row justify-around mt-8">
            <Button onClick={() => handleUpdateTwoFactor(true)}>Tak</Button>
            <Button onClick={() => setTriggerPopup(false)}>Nie</Button>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default TwoFactorPopup;
