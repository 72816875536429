import getRequest from "../../requests/getRequest";

export const nextQuestionService = async (questionId?: string, answerId?: string) => {
  const result = await getRequest({
    url: "/scenario/nextQuestion",
    params: {
      questionId: questionId,
      answerId: answerId,
    },
  });
  return result;
};
