import React from 'react';

interface PageWrapProps {
children: React.ReactNode;
}

export default function PageWrap({ children }: PageWrapProps) {
    return (
        <div className="h-full">
            <div className={`container h-full mx-auto px-5 pb-10 my-2 md:pb-0 md:px-0 flex flex-col justify-center items-center`}>
                <div className="flex flex-col flex-wrap w-full">
                    <div className="flex flex-col w-full relative">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}