import React from 'react';

interface TextInterface {
  children: React.ReactNode;
}

function Text({ children }: TextInterface) {
  return <p className=" mt-5 mb-14 text-darkGrey whitespace-pre-wrap">{children}</p>;
}

export default Text;