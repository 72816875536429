import React from "react";
import ScenarioEditName from "./static-steps/ScenarioEditName";
import ScenarioEditWealthStep1 from "./static-steps/ScenarioEditWealthStep1";
import ScenarioEditWealthStep2 from "./static-steps/ScenarioEditWealthStep2";
import ScenarioSuccess from "./final-step/ScenarioEditSuccess";
import ScenarioEditQuestion from "./api-step/ScenarioEditQuestion";
import { useSelector } from "react-redux";
import { getEditScenario } from "../../../redux/reducers/scenarioEditSlice";
import ScenarioEditWealth from "./static-steps/ScenarioEditWealth";

interface RenderStepsInterface {
  index: number;
  handleNextCaseStep: Function;
  setPopupWealthInfo?: Function;
}

const EditSteps = ({
  index,
  handleNextCaseStep,
  setPopupWealthInfo,
}: RenderStepsInterface) => {
  const editScenarioData: any = useSelector(getEditScenario);
  const answers = {
    choiceAnswers: editScenarioData.choiceAnswers,
    numericAnswers: editScenarioData.numericAnswers,
    numericListAnswers: editScenarioData.numericListAnswers
  }

  switch (index) {
    case 1:
      return <ScenarioEditName handleNextCaseStep={handleNextCaseStep} savedName={editScenarioData.name}/>;
    case 2:
      return (
        <ScenarioEditWealth
          handleNextCaseStep={handleNextCaseStep}
          totalWealth={editScenarioData.wealth.total}
        />
      );
    case 3:
      return (
        <ScenarioEditWealthStep1
          handleNextCaseStep={handleNextCaseStep}
          setPopupWealthInfo={setPopupWealthInfo}
          wealthParts={editScenarioData.wealth.parts}
        />
      );
    case 4:
      return (
        <ScenarioEditWealthStep2
          handleNextCaseStep={handleNextCaseStep}
          setPopupWealthInfo={setPopupWealthInfo}
          wealthDebts={editScenarioData.wealth.debts}
        />
      );
    case 5:
      return (
        <ScenarioEditQuestion
          handleNextCaseStep={handleNextCaseStep}
          answers={answers}
        />
      );
    case 6:
      return <ScenarioSuccess id={editScenarioData.id} />;
    default:
      return <></>;
  }
};

export default EditSteps;
