import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../common/button/Button';
import PageWrap from './PageWrap';
import {ReactComponent as ErrorSvg} from "../../assets/images/error.svg";

interface NoAccessInterface {
    title: string;
    description: string;
}

function NoAccess({ title, description }: NoAccessInterface) {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <PageWrap>
      <div className="w-full max-w-7xl mx-auto flex flex-col-reverse md:flex-row justify-between items-center md:gap-10 pt-16 md:px-10">
        <div className='md:w-1/3 text-center md:text-left'>
          <h1 className="text-darkBlue text-2xl font-semibold">{title}</h1>
          <p className="text-darkGrey my-10">{description}</p>
          <div className="flex flex-col gap-5 max-w-xs mx-auto md:mx-0">
            <Link to="/home">
              <Button size="full">Strona główna</Button>
            </Link>
            <Button
              onClick={handleBack}
              bgColor="transparent"
              textColor="darkBlue"
              size="full"
            >
              Powrót
            </Button>
          </div>
        </div>
        <ErrorSvg className='md:w-1/2 max-w-xs md:max-w-none h-auto'/>
      </div>
    </PageWrap>
  );
}

export default NoAccess;