import isApiDateOlderThanToday from "../../../utils/dates/isApiDateOlderThanToday";

export const getActiveStatus = (planDetails: any) => {
  if (!planDetails.expirationDate) return true;
  return isApiDateOlderThanToday(planDetails.expirationDate);
};

export const getActiveStatusText = (planDetails: any) => {
  if (!planDetails.expirationDate) return "AKTYWNY";
  const isNotExpired = isApiDateOlderThanToday(planDetails.expirationDate);
  if (isNotExpired) return "AKTYWNY";
  return "NIEAKTYWNY";
};