import getRequest from "../../requests/getRequest";



export const userProfile = async () => {
  const result = await getRequest({
    url: "/user/profile",
  });
  return result;
};
