import React, { useState, useEffect } from 'react';
import Header from '../../components/common/text/Header';
import PageWrap from '../../components/layout/PageWrap';
import ContactNotFound from './components/ContactNotFound';
import ContactSearch from './components/ContactSearch';
import { useSelector } from 'react-redux';
import { getUserSubscription } from '../../redux/reducers/userSlice';


function Contact() {
    const [failedConnection, setFailedConnection] = useState(false);
    const [selectedConsultant, setSelectedConsultant] = useState("");
    const subscription = useSelector(getUserSubscription);
    
    const handleConnect = () => {
        window.location.href = "/videoCall"
    }

      useEffect(() => {
        if (subscription.subscriptionType === "NORMAL") {
          setSelectedConsultant("INSURANCE_AGENT");
        } else {
          setSelectedConsultant("CUSTOMER_ADVISOR");
        }
      }, [subscription]);

      return (
        <PageWrap>
          <div className="w-full max-w-xl sm:px-4 mx-auto ">
            <div className="sm:bg-white sm:shadow-container rounded-lg sm:p-12 mt-5">
              <div className="flex flex-col">
                <Header>Specjalista</Header>
                <div className="mt-10">
                  {!failedConnection ? (
                    <ContactSearch
                      handleConnect={handleConnect}
                      setFailedConnection={setFailedConnection}
                      selectedConsultant={selectedConsultant}
                    />
                  ) : (
                    <ContactNotFound
                      setFailedConnection={setFailedConnection}
                      selectedConsultant={selectedConsultant}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </PageWrap>
      );
}

export default Contact;