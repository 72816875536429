import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAlternativeScenarios } from "../../client/AppClient";
import displayError from "../../utils/handling-errors/displayError";

import PageWrap from "../../components/layout/PageWrap";
import PopupScenarioDetails from "../../components/specific/PopupScenarioDetails";
import LoadingSpinner from "../../components/specific/LoadingSpinner";
import NoAccess from "../../components/layout/NoAccess";
import LegalPortionWrap from "./components/LegalPortionWrap";
import AlternativeScenariosCategory from "./components/AlternativeScenariosCategory";



function AlternativeScenarios() {
  const [data, setData] = useState<any>([]);
  const [scenarioData, setScenarioData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [popupData, setPopupData] = useState<any>({
    opened: false,
    title: "",
    message: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const disptach = useDispatch();

  const filterAlternativeScenarios = (data: any[]) => {
    const filteredArray: any[] = [{}, {}, {}];
    // ids of specific questions answered in scenario, used for filtering alternative scenarios
    const isEntrepreneurQuestionId = "1595299b-b3e7-4171-ac65-dd384e2b5559";
    const isJDG = "e0e61f05-7656-46dd-933e-eb8111abe018";
    const isBusinessman = "8b6611c9-289e-4381-8ba9-7bce5810ccc2";

    let choiceAnswers = location.state.scenarioData.choiceAnswers;
    if (Array.isArray(choiceAnswers) === false) choiceAnswers = Object.entries(choiceAnswers);
    const entrepreneurAnswer = choiceAnswers.find(
      (el: string[]) => el[0] === isEntrepreneurQuestionId
    );
    // alternative scenarios must be in this order and need to be filtered based on ids of answers above
    data.forEach((element) => {
      if (element.name === "Testament") {
        filteredArray.splice(0, 1, element);
      }
      if (element.name === "Darowizny") {
        filteredArray.splice(1, 1, element);
      }
      if (element.name === "Umowy dot. spadku") {
        filteredArray.splice(2, 1, element);
      }
      if (entrepreneurAnswer[1] === isJDG && element.name === "Zarząd sukcesyjny") filteredArray.push(element)
      if (entrepreneurAnswer[1] === isBusinessman && element.name === "Fundacja rodzinna") filteredArray.push(element)
    })
    return filteredArray;
  };

  const getData = async () => {
    const response: any = await getAlternativeScenarios();
    setLoading(false);
    if (response.status !== 200) return displayError(disptach, response);
    if (response.data.length === 0) return;
    const filteredData = filterAlternativeScenarios(response.data);
    setData(filteredData);
  };

  useEffect(() => {
    if (location.state !== null) {
      setScenarioData(location.state.scenarioData);
      getData();
    } else setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigateToPortion = () => {
    navigate("/legalportion", { state: { scenarioData } });
  };

  if (loading) return <LoadingSpinner />;

  if (!loading && location.state === null) {
    return (
      <NoAccess
        title="Brak dostępu strony"
        description="Aby wyświetlić alternatywne scenariusze, należy zrobić to przez stronę wyniku scenariusza"
      />
    );
  }

  return (
    <>
      <PopupScenarioDetails popupData={popupData} setPopupData={setPopupData} />
      <PageWrap>
        <div className="w-full max-w-6xl mx-auto sm:my-10">
          <div className="flex flex-col sm:mt-20">
            <div className="relative flex flex-col gap-10 bg-gradient-to-b from-sherpaBlue to-darkBlue px-6 sm:px-14 py-12 text-white rounded-t-lg sm:after:absolute sm:after:bg-darkBlue sm:after:left-0 sm:after:top-full sm:after:w-full sm:after:h-1/4 sm:after:-z-10">
              <div className="flex flex-row justify-between items-center">
                <h1 className="font-semibold text-2xl md:text-4xl">
                  Scenariusze alternatywne
                </h1>
              </div>
              <p className="text-white font-semibold text-lg">
                Poniżej znajdziesz scenariusze alternatywne adekwatne do swojego
                przypadku.
              </p>
            </div>

            <AlternativeScenariosCategory
              data={data}
              setPopupData={setPopupData}
            />
          </div>

          <LegalPortionWrap handleNavigateToPortion={handleNavigateToPortion} />
        </div>
      </PageWrap>
    </>
  );
}

export default AlternativeScenarios;
