import postRequest from "../../requests/postRequest";

export const editEnterpriseUserService = async (id: string, body: any) => {
  const result = await postRequest({
    url: `/enterprise/user/${id}/edit`,
    params: {
      fullname: body.fullname,
      address: body.address,
      email: body.email,
      phoneNumber: body.phoneNumber,
    },
  });
  return result;
};
