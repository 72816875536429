import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import NavbarNotLogged from './NavbarNotLogged';


function Nav({ user }: any) {
     const location = useLocation();
     if (location.pathname === "/" || location.pathname === "/videoCall" || location.pathname.includes("/scenario/pdf")) {
       return null;
     }
     if (user) {
       return <Navbar />;
     }
     return <NavbarNotLogged />;
}

export default Nav;