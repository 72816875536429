import React from "react";
import classnames from "classnames";

interface ButtonInterface {
  textColor?: string;
  bgColor?: string;
  py?: string;
  size?: "sm" | "md" | "lg" | "full";
  font?: "normal" | "semibold" | "bold";
  children: any;
  disabled?: boolean;
  type?: any;
  onSubmit?: any;
  onClick?: any;
}

function Button({ textColor, bgColor, size, py, font, disabled, ...rest }: ButtonInterface) {
  return (
    <button
      disabled={disabled}
      className={classnames(
        `text-${textColor} bg-${bgColor} py-${py} flex items-center justify-center gap-1 rounded-lg font-${font} border border-darkBlue`,
        {
          "px-5": size === "sm",
          "px-10": size === "md",
          "px-16": size === "lg",
          "w-full": size === "full",
          "!bg-mediumGrey": disabled === true,
          "!border-mediumGrey": disabled === true,
          "!text-white": disabled === true,
          "pointer-events-none": disabled === true,
          "cursor-not-allowed": disabled === true,
          "bg-darkBlue": (disabled === false) || (disabled === undefined),
          "pointer-events-auto": (disabled === false) || (disabled === undefined),
        }
      )}
      {...rest}
    />
  );
}

Button.defaultProps = {
  textColor: "white",
  bgColor: "darkBlue",
  py: "3",
  size: "lg",
  font: "semibold"
};

export default Button;
