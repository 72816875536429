import React, { useState, useEffect } from "react";
import Header from "../../components/common/text/SubHeader";
import PageWrap from "../../components/layout/PageWrap";
import PlansDesktop from "./components/PlansDesktop";
import PlansMobile from "./components/PlansMobile";
import plansData from "./data/plansData";
import { getUserProfile, renewSubscription } from "../../client/AppClient";
import displayError from "../../utils/handling-errors/displayError";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate /*useNavigate*/ } from "react-router-dom";
import { getUserAccountType, setUser } from "../../redux/reducers/userSlice";
import { getScenarioResult } from "../../redux/reducers/scenarioResultSlice";
import displaySuccess from "../../utils/handling-success/displaySuccess";
import TransactionStatus from "../../components/payments/TransactionStatus";
import PlansPopup from "./components/PlansPopup";

const mobileBreakpoint = 768;
const transactionUrl = process.env.REACT_APP_TRANSACTION_URL;

function Plans() {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= mobileBreakpoint
  );
  const [popupTrigger, setPopupTrigger] = useState({
    opened: false,
    title: "Pakiet premium",
    type: "statement"
  });
  const [isTransactionStarted, setIsTransactionStarted] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scenarioDetails = useSelector(getScenarioResult);
  const accountType = useSelector(getUserAccountType);

  const saveScenarioDetails = () => {
    // used when navigated from scenario-page to save redux content to localStorage
    if (
      location?.state?.navigatedFrom !== "/scenario/result" ||
      Object.keys(scenarioDetails).length === 0
    )
      return;
    localStorage.setItem("scenarioDetails", JSON.stringify(scenarioDetails));
  };

  const saveRedirectLink = () => {
    const path = location.state?.navigatedFrom;
    if (path === undefined) return;
    localStorage.setItem("redirectPath", path);
  };

  const checkIfFactureError = (response: any) => {
    const errorMessage = response?.data?.errors[""][0];
    if (errorMessage && errorMessage === "error.missingBilingDetails") return true;
    return false;
  }

  const handleWindowSizeChange = () => {
    if (window.innerWidth <= mobileBreakpoint) {
      setIsMobile(true);
    } else if (window.innerWidth > mobileBreakpoint) {
      setIsMobile(false);
    }
  };

  const handleCancelSubscription = async () => {
    const response: any = await renewSubscription("NORMAL");
    if (response.status !== 200) return displayError(dispatch, response);
    updateUser();
    displaySuccess(dispatch, "Sukces", "Zmieniono subskrypcje na podstawową");
  };

  const handleStartPremiumTransaction = async () => {
    const response = await renewSubscription("PREMIUM");
    if (response.status !== 200) {
      const isFactureError = checkIfFactureError(response);
      if (isFactureError) return setPopupTrigger({
        opened: true,
        title: "Dane do faktur",
        type: "facture",
      });
      return displayError(dispatch, response);
    }
    saveScenarioDetails();
    saveRedirectLink();
    setIsTransactionStarted(true);
    const token = response.data.transactionToken;
    window.location.href = transactionUrl + token;
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handlePlanChoose = (type: string) => {
    if (type === "PREMIUM") {
      if (accountType === "PRIVATE") setPopupTrigger({
        opened: true,
        title: "Pakiet premium",
        type: "statement",
      });
      else handleStartPremiumTransaction();
    } else if (type === "NORMAL") handleCancelSubscription();
    else if (type === "ENTERPRISE")
      navigate("/plans/enterprise", {
        state: { navigatedFrom: location.state?.navigatedFrom },
      });
  };

  const updateUser = async () => {
    const userDetails = await getUserProfile();
    if (userDetails.status !== 200) return;
    dispatch(setUser(userDetails.data));
  };

  if (isTransactionStarted) return <TransactionStatus />;

  if (isMobile) {
    return (
      <>
        <PlansPopup
          popupTrigger={popupTrigger}
          setPopupTrigger={setPopupTrigger}
          accountType={accountType}
          handleStartPremiumTransaction={handleStartPremiumTransaction}
        />
        <div className="max-w-2xl w-full mx-auto flex flex-col">
          <div className={`text-left items-center mt-5 px-5`}>
            <Header>Którą wersję z aplikacji wybierasz?</Header>
          </div>
          <PlansMobile
            plansData={plansData}
            choosePlan={handlePlanChoose}
            accountType={accountType}
          />
          <div className="px-5"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <PlansPopup
        popupTrigger={popupTrigger}
        setPopupTrigger={setPopupTrigger}
        accountType={accountType}
        handleStartPremiumTransaction={handleStartPremiumTransaction}
      />
      <PageWrap>
        <div className="max-w-7xl w-full mx-auto flex flex-col mb-10">
          <div className={`text-center items-center mt-5`}>
            <Header>Którą wersję z aplikacji wybierasz?</Header>
          </div>
          <PlansDesktop
            plansData={plansData}
            choosePlan={handlePlanChoose}
            accountType={accountType}
          />
        </div>
      </PageWrap>
    </>
  );
}

export default Plans;
