import React, { useState, useEffect } from "react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg";

interface SearchBarInterface {
  data?: any;
  handleSearchChange: Function;
}

function SearchBar({
  data,
  handleSearchChange,
}: SearchBarInterface) {
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    handleSearchChange(searchInput);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput])

  return (
    <div className="flex flex-row gap-5 border border-[#E9EBF2] rounded-md p-3 w-full">
      <SearchIcon />
      <input
        type="text"
        placeholder="Szukaj Osoby..."
        onChange={(e) => setSearchInput(e.target.value)}
        className="w-full outline-none"
        value={searchInput}
      />
    </div>
  );
}

export default SearchBar;
