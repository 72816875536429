import classNames from "classnames";
import React from "react";

import checkboxIcon from "../../../assets/icons/GoldenCheck.svg"


interface CheckboxInterface {
  children: React.ReactNode;
  required?: boolean;
  id: string;
  value?: string | number;
  selected?: string | number;
  group: string;
  disabled?: boolean;
  onChange?: any;
  onClick?: any;
}

function CheckboxGroup({
  children,
  required,
  id,
  value,
  selected,
  group,
  disabled,
  ...rest
}: CheckboxInterface) {
  return (
    <div className="flex align-center items-start mt-4">
      <div className="flex flex-col relative">
        <label
          htmlFor={id}
          className={classNames("relative", {
            "cursor-pointer": disabled === false || disabled === undefined,
            "cursor-not-allowed": disabled === true,
          })}
        >
          <input
            id={id}
            type="radio"
            className={`checkBox-input bg-transparent transition-all appearance-none h-4 w-4 border-2 border-gold cursor-pointer p-2 rounded-sm check-1 z-10
              ${selected === value ? "shadow-checkbox" : "shadow-none"}
              
              `}
            style={
              selected === value
                ? {
                    backgroundImage: `url("${checkboxIcon}")`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }
                : {}
            }
            name={group}
            value={value}
            checked={selected === value}
            readOnly={true}
            {...rest}
          />
        </label>
      </div>

      <label
        htmlFor={id}
        className={classNames(
          "ml-2  text-sm  text-left text-gold cursor-pointer"
        )}
      >
        {children}
      </label>
    </div>
  );
}

export default CheckboxGroup;
