import React from "react";
import Popup from "./Popup";

interface PopupScenarioDetailsInterface {
  popupData: any;
  setPopupData: Function;
}

function PopupScenarioDetails({
  popupData,
  setPopupData,
}: PopupScenarioDetailsInterface) {
  const handleRemovePopup = (close: boolean) => {
    setPopupData({
      opened: close,
      title: "",
      message: "",
    });
  };
  return (
    <Popup
      title={popupData.title}
      trigger={popupData.opened}
      setTrigger={handleRemovePopup}
      size="max-w-5xl"
    >
      <div className="flex flex-col">
        <div className="popupScenario text-darkGrey text-left whitespace-pre-line">{popupData.message}</div>
      </div>
    </Popup>
  );
}

export default PopupScenarioDetails;
