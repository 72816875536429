import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import removeJwt from "../../client/helpers/removeJwt";
import { setNotifications } from "../../redux/reducers/notificationsSlice";
import { getErrorResponse } from "./ errorCodes";
import { logout } from "../../redux/reducers/userSlice";

const displayBadRequest = (errors: any, dispatch: Dispatch<AnyAction>) => {
  const formErrors: any = {};
  const keys = Object.keys(errors);
  keys.forEach((key: string) => {
    errors[key].forEach((error: any) => {
      if (key.length > 0) formErrors[key] = getErrorResponse(error, key);
      else {
        const message = getErrorResponse(error);
        const alert: any = {
          title: "Błąd",
          message: message,
          type: "error",
        };
        dispatch(setNotifications(alert));
      }
    });
  });
  return formErrors;
};

const displayBadStatus = (status: number, dispatch: Dispatch<AnyAction>) => {
  const message = getErrorResponse(status);
  const alert: any = {
    title: "Błąd",
    message: message,
    type: "error",
  };
  dispatch(setNotifications(alert));
};

export const displayCustomError = (message: string, dispatch: Dispatch<AnyAction>) => {
  const alert: any = {
    title: "Błąd",
    message: message,
    type: "error",
  };
  dispatch(setNotifications(alert));
};

export const handleLogout = (dispatch: Dispatch<AnyAction>) => {
  removeJwt();
  dispatch(logout());
  const alert: any = {
    title: "Wylogowano",
    message: "Sesja wygasła",
    type: "warning",
  };
  dispatch(setNotifications(alert));
};

const displayError = (dispatch: Dispatch<AnyAction>, response: any) => {
  if (response.status === 400)
    return displayBadRequest(response.data.errors, dispatch);
  if (response.status === 401) return handleLogout(dispatch);
  if (response.status !== 400 && response.status !== 401)
    return displayBadStatus(response.status, dispatch);
};

export default displayError;
