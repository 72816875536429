import { FirebaseApp, initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import Cookies from "js-cookie";
// ------ DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyBq1bU_BYPwWyMbnhzDFWM1fO0MugaXMYY",
//   authDomain: "successio-167b1.firebaseapp.com",
//   projectId: "successio-167b1",
//   storageBucket: "successio-167b1.appspot.com",
//   messagingSenderId: "194619525267",
//   appId: "1:194619525267:web:a183bc8a948c2f28f4e566",
//   measurementId: "G-LC63KHWPRC"
// };
// ------ PROD
const firebaseConfig = {
  apiKey: "AIzaSyCOv5-hPCxrMqToqVbHXKM6TV-MwEg8T8w",
  authDomain: "successio-mobile.firebaseapp.com",
  projectId: "successio-mobile",
  storageBucket: "successio-mobile.appspot.com",
  messagingSenderId: "721113592147",
  appId: "1:721113592147:web:114a2b481b591d4391b1a6",
  measurementId: "G-2WRBV4VNQX",
};

// Cookies agreement: 1 - only for auth, 2 - auth and analitycs 
const cookieAgreement = Cookies.get("cookies-accept");

export const startCollectingAnalitycs = (app: FirebaseApp) => {
  const analytics = getAnalytics(app);
  logEvent(analytics, "page_view");
  logEvent(analytics, "screen_view");
  logEvent(analytics, "user_engagement");
  logEvent(analytics, "session_start");
  logEvent(analytics, "session_end");
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
if (cookieAgreement === "2") {
  startCollectingAnalitycs(app);
}
const auth = getAuth(app);
auth.useDeviceLanguage();
export { auth };
export default app;
