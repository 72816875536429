import setJwt from "../../helpers/setJwt";
import postRequestNoAuth from "../../requests/postRequestNoAuth";

interface twoFactorLoginInterface {
  email: string;
  password: string;
  token: string;
}

export const twoFactorLoginService = async (body: twoFactorLoginInterface) => {
  const result = await postRequestNoAuth({
    url: "/twoFactor/login",
    params: {
      email: body.email,
      password: body.password,
      token: body.token
    },
  });
  if (result.status === 200 && result.data.twoFactor !== "true") setJwt(result.data);
  return result;
};
