import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";


const initialState: any = {
  user: null,
  userAfterRegister: null,
  redirectAfterLoginUrl: null,
};

export const userSlice = createSlice({
  initialState,
  name: "user",
  reducers: {
    logout: () => initialState,
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserAfterRegister: (state, action) => {
      state.userAfterRegister = action.payload;
    },
    setRedirectAfterLoginUrl: (state, action) => {
      state.redirectAfterLoginUrl = action.payload;
    }, 
  },
});

export const getUser = (state: RootState) => state.user.user;
export const getTwoFactor = (state: RootState) => state.user.user.twoFactorAuth;
export const getUserRole = (state: RootState) => state.user.user.role;
export const getEnterpriseRole = (state: RootState) => state.user.user.subscription?.enterpriseRole;
export const getUserSubscription = (state: RootState) => state.user.user?.subscription;
export const isFreshUser = (state: RootState) => state.user.userAfterRegister;
export const getUserAccountType = (state: RootState) => state.user.user.accountType;
export const getRedirectAfterLoginUrl = (state: RootState) => state.user.redirectAfterLoginUrl;
export const {
  logout,
  setUser,
  setUserAfterRegister,
  setRedirectAfterLoginUrl,
} = userSlice.actions;
export default userSlice.reducer;