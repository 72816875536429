import getRequest from "../../requests/getRequest";

export const transactionListService = async (
  currentPage?: number,
  itemsPerPage?: number,
) => {
  const result = await getRequest({
    url: "/user/transactions",
    params: {
      p: currentPage,
      ps: itemsPerPage,
    },
  });
  return result;
};
