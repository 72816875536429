import React, { useState, useEffect, useRef } from "react";


import { useDispatch, useSelector } from "react-redux";
import {
  updateBeneficiaryValues,
  getValuesInfo,
} from "../../../redux/reducers/partionSlice";

import Accordion from "../../../components/common/controls/Accordion";
import RadioSelect from "../../../components/common/forms/RadioSelect";
import NextStep from "../../../components/steps/NextStep";
import ExpandableInputGroup from "../components/ExpandableInputGroup";
import inputCurrencyToNumber from "../../../utils/inputCurrencyToNumber";

const texts = [
    {
        title: "Czy na rzecz uprawnionego do zachowku były poczynione w przeszłości darowizny i/lub jest ustanowiony zapis windykacyjny?",
        placeholder: "wpisz sumę darowizn i zapisów windykacyjnch na rzecz uprawnionego",
        descriptionTitle: "Zapisy windykacyjne i darowizny - dodatkowe informacje",
        description: "Tekst o sumie darowizn na rzecz uprawnionego",
    },
    {
        title: "Czy na rzecz uprawnionego do zachowku były poczynione w przeszłości darowizny?",
        placeholder: "wpisz sumę darowizn na rzecz uprawnionego",
        descriptionTitle: "Darowizny - dodatkowe informacje",
        description: "Tekst o sumie darowizn na rzecz uprawnionego",
    },
    {
        title: "Czy na rzecz uprawnionego do zachowku jest ustanowiony zapis windykacyjny?",
        placeholder: "wpisz sumę zapisów windykacyjnch na rzecz uprawnionego",
        descriptionTitle: "Zapisy windykacyjne - dodatkowe informacje",
        description: "Tekst o sumie zapisów windykacyjnych na rzecz uprawnionego",
    },
]

function LegalPortionBeneficiary({
  onSubmit,
}: any) {
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const effectCalled = useRef<boolean>(false);

  const dispatch = useDispatch();
  const portionData = useSelector(getValuesInfo);

  const bequestsCondition = portionData.isBequests;
  const pastInheritanceCondition = portionData.isPastInheritances;

  useEffect(() => {
    // skip rendering component when pastInheritances and Bequests were not provided
    if (!bequestsCondition && !pastInheritanceCondition) {
      if (effectCalled.current) return;
      onSubmit();
      effectCalled.current = true;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    if (value !== undefined || value === "") {
      const valueNumber = inputCurrencyToNumber(value);
      dispatch(updateBeneficiaryValues(valueNumber));
      onSubmit();
    }
  };

  const returnTexts = () => {
    if(bequestsCondition && pastInheritanceCondition) {
        return texts[0];
    } else if (!bequestsCondition && pastInheritanceCondition) {
        return texts[1];
    }
    return texts[2];
  }
  const textValues = returnTexts();
    
  

  return (
    <NextStep
      title={"Zachowek"}
      onSubmit={handleSubmit}
      disabled={value === undefined || value === ""}
    >
      <h2 className="text-lg font-semibold text-darkBlue">
        {textValues.title}
      </h2>
      <div className="flex flex-col gap-3 mt-5">
        <ExpandableInputGroup
          group="legalportionbeneficiary"
          name="Tak"
          value={value}
          setValue={setValue}
          selectValue="yes"
          setSelected={setSelectedValue}
          selected={selectedValue}
        />
        <RadioSelect
          id="legalportionbeneficiaryno"
          group="legalportionbeneficiary"
          selected={selectedValue}
          value="0"
          onClick={(e: any) => {
            setValue(e.target.value)
            setSelectedValue(e.target.value);
          }}
        >
          Nie
        </RadioSelect>
      </div>
      <p className="text-darkGrey mt-5 mb-7">
        Jeśli tak, {textValues.placeholder}
      </p>
      <Accordion title={textValues.descriptionTitle}>
        <p className="text-darkGrey">{textValues.description}</p>
      </Accordion>
    </NextStep>
  );
}

export default LegalPortionBeneficiary;
