import React from "react";
import Carousel from './Carousel3';
import img1 from "../../../assets/images/carousel/img1.svg";
import img2 from "../../../assets/images/carousel/img2.svg";
import img3 from "../../../assets/images/carousel/img3.svg";

function CarouselStartPage() {
    const slide1 = (
      <div>
        <div className="flex h-[50vh] max-h-[350px] justify-center">
          <img
            src={img1}
            className="block object-contain"
            height="50"
            alt="..."
          />
        </div>
        <div className="block justify-center text-center mb-0 mt-16">
          <div className="text-3xl text-sherpaBlue mb-3">
            Analizuj linię sukcesji
          </div>
          <p className="text-darkGrey">
            Successio pomoże Ci sprawdzić Twoje dziedziczenie w bezproblemowy
            sposób.
          </p>
        </div>
      </div>
    );

    const slide2 = (
      <div>
        <div className="flex h-[50vh] max-h-[350px] justify-center">
          <img
            src={img2}
            className="block object-contain"
            height="50"
            alt="..."
          />
        </div>
        <div className="block justify-center text-center mb-0 mt-16">
          <h5 className="text-3xl text-sherpaBlue mb-3">Przygotuj Bliskich</h5>
          <p className="text-darkGrey">
            Bądź pewny, że zabezpieczysz na przyszłość najblisze Ci osoby.
          </p>
        </div>
      </div>
    );

    const slide3 = (
      <div>
        <div className="flex h-[50vh] max-h-[350px] justify-center">
          <img
            src={img3}
            className="block object-contain"
            height="50"
            alt="..."
          />
        </div>
        <div className="block justify-center text-center mb-0 mt-16">
          <h5 className="text-3xl text-sherpaBlue mb-3">
            Bądź spokojny o dane
          </h5>
          <p className="text-darkGrey">
            Twoje dane zawsze będą bezpieczne dzięki podwójnej weryfikacji.
          </p>
        </div>
      </div>
    );

    return (
        <Carousel slide1={slide1} slide2={slide2} slide3={slide3}></Carousel>
    );
}

export default CarouselStartPage;
