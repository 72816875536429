import React from 'react';
import { useSelector } from 'react-redux';
import { getWealthSum } from '../../../redux/reducers/partionSlice';
import stringToCurrency from '../../../utils/stringToCurrency';



function LegalPortionResult() {
  const sumWealth = useSelector(getWealthSum);
  const currencySumWealth = stringToCurrency(sumWealth);
    return (
        <div className="w-full max-w-6xl mx-auto">
          <div className="flex flex-col mt-20">
            <div className="relative flex flex-col gap-10 bg-gradient-to-b from-sherpaBlue to-darkBlue px-14 py-12 text-white rounded-t-lg sm:after:absolute sm:after:bg-darkBlue sm:after:left-0 sm:after:top-full sm:after:w-full sm:after:h-1/4 sm:after:-z-10">
              <h1 className="font-semibold text-4xl">Zachowek</h1>
              <h2 className="font-semibold text-5xl">{currencySumWealth}</h2>
            </div>
            <div className="flex flex-col gap-5 sm:bg-white sm:px-14 py-10 rounded-lg">
              <p className="text-darkGrey">
                Tyle wyniesie zachowek dla wybranej Osoby, która byłaby powołana do
                spadku z mocy ustawy, a zostanie pominięta w testamencie. W
                związku z tym Osoba będzie miała roszczenie o zapłatę tej kwoty
                do spadkobierców testamentowych.{" "}
              </p>
              <span className="text-darkBlue">
                Aby zabezpieczyć swoich bliskich na przyszłość, pomyśl o polisie
                na życie!
              </span>
            </div>
          </div>
        </div>
    );
}

export default LegalPortionResult;