import setJwt from "../../helpers/setJwt";
import postRequestNoAuth from "../../requests/postRequestNoAuth";

export const socialAuthService = async (provider: string, token: string) => {
  const result = await postRequestNoAuth({
    url: "/login/social",
    params: {
      provider: provider,
      token: token,
    },
  });
  if (result.status === 200) setJwt(result.data);
  return result;
};
