import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getEnterpriseRole, getTwoFactor, logout } from "../../redux/reducers/userSlice";

import Toggle from "../common/controls/Toggle";
import TwoFactorPopup from "../specific/TwoFactorPopup";

import { ReactComponent as CloseIcon } from "../../assets/icons/Close.svg";
import { ReactComponent as AnchorIcon } from "../../assets/icons/Anchor.svg";

import removeJwt from "../../client/helpers/removeJwt";
import { logoutRequest } from "../../client/AppClient";
import displayError from "../../utils/handling-errors/displayError";



interface SidebarInterface {
  isOpened: boolean;
  setIsOpened: Function;
}

const SettingsTitle = ({ children }: any) => {
  return (
    <div className="px-12 mb-6 mt-16 ">
      <span className="text-sherpaBlue font-semibold text-lg">{children}</span>
    </div>
  );
};

const SettingsWrapper = ({ children, className, ...rest }: any) => {
  return (
    <div
      className={`px-12 py-4 flex flex-row justify-between items-center bg-white  border-b-2 border-light last:border-b-0 ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

const SettingsLinkWrapper = ({
  children,
  goToSettings,
  target,
  className,
}: any) => {
  return (
    <div
      className={`px-12 py-4 flex flex-row justify-between items-center bg-white  border-b-2 border-light last:border-b-0 cursor-pointer ${className}`}
      onClick={() => goToSettings(target)}
    >
      {children}
      <AnchorIcon className="greyPath" />
    </div>
  );
};

function Sidebar({ isOpened, setIsOpened }: SidebarInterface) {
  const [triggerPopup, setTriggerPopup] = useState(false);
  const isTwoFactor =  useSelector(getTwoFactor);
  const enterpriseRole = useSelector(getEnterpriseRole);
  

  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    if(triggerPopup) {
      setIsOpened(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerPopup, setTriggerPopup])

  const goToSettings = (target: string) => {
    setIsOpened(false);
    navigate(target);
  };

  const handleLogout = async () => {
    const response = await logoutRequest();
    const status = response.status;
    if (status !== 200 && status !== 401) return displayError(dispatch, response);
    removeJwt();
    dispatch(logout());
    navigate("/login");
  };
  

  return (
    <>
      <TwoFactorPopup
        isTwoFactor={isTwoFactor}
        triggerPopup={triggerPopup}
        setTriggerPopup={setTriggerPopup}
      />
      <div
        style={{
          backgroundImage:
            "linear-gradient(180deg, #FFFFFF 21.92%, #F5F6F8 67.98%)",
          backgroundAttachment: "fixed",
        }}
        className={`fixed w-full  max-w-lg right-0 top-0 bottom-0 transition-all will-change-transform duration-200 overflow-y-auto z-30 ${
          isOpened ? "translate-x-0" : "translate-x-full "
        }`}
      >
        <div className="px-4 flex flex-col justify-end items-end mt-8">
          <CloseIcon
            onClick={() => setIsOpened(false)}
            className="cursor-pointer"
          />
        </div>
        <SettingsTitle>Ustawienia Bezpieczeństwa</SettingsTitle>
        <div className="shadow-small">
          <SettingsWrapper>
            <span className="text-greyBlue">Weryfikacja dwuetapowa</span>
            <Toggle
              value={isTwoFactor}
              checked={isTwoFactor === true}
              onClick={() => setTriggerPopup(true)}
            />
          </SettingsWrapper>
        </div>

        <SettingsTitle>Moje konto</SettingsTitle>
        <div className="shadow-small">
          <SettingsLinkWrapper
            goToSettings={goToSettings}
            target={"/myaccount"}
          >
            <span className="text-greyBlue">Moje dane osobowe</span>
          </SettingsLinkWrapper>
          <SettingsLinkWrapper
            goToSettings={goToSettings}
            target={"/myaccount/changePassword"}
          >
            <span className="text-greyBlue">Zmień hasło</span>
          </SettingsLinkWrapper>
          <SettingsLinkWrapper
            goToSettings={goToSettings}
            target={"/myaccount/plan"}
          >
            <span className="text-greyBlue">Twój pakiet</span>
          </SettingsLinkWrapper>
          {enterpriseRole === "ADMIN" && (
            <SettingsLinkWrapper
              goToSettings={goToSettings}
              target={"/enterprise/users"}
            >
              <span className="text-greyBlue">Zarządzanie Użytkownikami</span>
            </SettingsLinkWrapper>
          )}
        </div>

        <SettingsTitle>Ogólne</SettingsTitle>
        <div className="shadow-small">
          <SettingsLinkWrapper>
            <a
              href="https://successio.pl/polityka-prywatnosci-successio/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="text-greyBlue">Polityka Prywatności</span>
            </a>
          </SettingsLinkWrapper>
          <SettingsLinkWrapper>
            <a
              href="https://successio.pl/regulamin-successio/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="text-greyBlue">Regulamin</span>
            </a>
          </SettingsLinkWrapper>
          <SettingsWrapper onClick={handleLogout} className="cursor-pointer">
            <span className="text-greyBlue">Wyloguj się</span>
          </SettingsWrapper>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
