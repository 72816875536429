import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { ReactComponent as SuccessIcon } from "../../assets/icons/Success.svg";
import { ReactComponent as WarningIcon } from "../../assets/icons/Warning.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/Error.svg";
import { ReactComponent as XIcon } from "../../assets/icons/X.svg";

interface NotificationInterface {
  handleRemoveNotification: Function;
  duration: number;
  message: string;
  type: "success" | "warning" | "error";
  title: string;
}

const Notification = ({
  handleRemoveNotification,
  duration,
  message,
  type,
  title,
}: NotificationInterface) => {
  const NotificationRef = useRef<any>();
  const [show, toggleShow] = useState(true);
  const [mounted, setMounted] = useState(show);
  const [bottom, setBottom] = useState<any>();

  useEffect(() => {
    if (show) setMounted(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) {
      setMounted(false);
      handleRemoveNotification();
    }
  };

  const setPosition = () => {
    const { current: node } = NotificationRef;
    if (document) {
      const nodeList: any = document.getElementsByClassName(
        "pop-up-notification"
      );
      const elements = [...nodeList];
      const currentIndex = elements.findIndex((element) => {
        return element.isSameNode(node);
      });

      const gaps = 24 * (currentIndex + 1);
      let bottom = 0;
      for (let i = 0; i < currentIndex; i++) {
        bottom = bottom + elements[i].clientHeight;
      }
      setBottom(`${gaps + bottom}px`);
    }
  };

  useLayoutEffect(() => {
    setPosition();
  }, []);
  useEffect(() => {
    setPosition();
  });

  useEffect(() => {
    if (duration) {
      setTimeout(() => {
        toggleShow(false);
      }, duration);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Icon = () => {
    if (type === "success") return <SuccessIcon />;
    else if (type === "warning") return <WarningIcon />;
    else if (type === "error") return <ErrorIcon />;
    return <></>;
  };

  return mounted ? (
    <div
      className={`pop-up-notification fixed w-full max-w-sm left-0 pl-4 pr-5 py-4 bg-white rounded-r-lg shadow-container z-50  ${
        show ? "animate-notificationStart" : "animate-notificationEnd"
      }
      border-l-4 
      ${type === "success" && "border-success"}
      ${type === "error" && "border-error"}
      ${type === "warning" && "border-warning"}
      `}
      style={bottom ? { bottom: bottom } : { bottom: "24px" }}
      onAnimationEnd={onAnimationEnd}
      ref={NotificationRef}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-5">
          <Icon />
          <div className="flex flex-col gap-1">
            <h6 className="text-darkBlue">{title}</h6>
            <p className="text-sm text-darkGrey">{message}</p>
          </div>
        </div>
        <XIcon width={11} className="cursor-pointer" onClick={() => toggleShow(false)} />
      </div>
    </div>
  ) : null;
};

export default Notification;
