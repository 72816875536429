import React, { useState } from 'react';
import { useDispatch } from "react-redux";

import ExpandableInput from '../../../../components/steps/ExpandableInput';
import WealthStep from '../../../../components/steps/WealthStep';
import Button from '../../../../components/common/button/Button';
import inputCurrencyToPennies from '../../../../utils/inputCurrencyToPennies';
import { updateEditScenarioWealth, updateScenarioEditWealthParts } from '../../../../redux/reducers/scenarioEditSlice';




function ScenarioEditWealthStep1({ handleNextCaseStep, setPopupWealthInfo, wealthParts }: any) {
  const [estateValue, setEstateValue] = useState<any>(wealthParts?.estate !== 0 &&(wealthParts?.estate / 100));
  const [companiesValue, setCompaniesValue] = useState<any>(wealthParts?.companies !== 0 && (wealthParts?.companies / 100));
  const [vehiclesValue, setVehiclesValue] = useState<any>(wealthParts?.vehicles !== 0 && (wealthParts?.vehicles / 100));
  const [savingsValue, setSavingsValue] = useState<any>(wealthParts?.savings !== 0 && (wealthParts?.savings / 100));
  const [investmensValue, setInvestmentsValue] = useState<any>(wealthParts?.investments !== 0 && (wealthParts?.investments / 100));
  const [othersValue, setOthersValue] = useState<any>(wealthParts?.other !== 0 && (wealthParts?.other / 100));

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const wealthCombined = {
      estate: inputCurrencyToPennies(estateValue.toString()),
      companies: companiesValue ? inputCurrencyToPennies(companiesValue.toString()) : 0,
      vehicles: vehiclesValue ? inputCurrencyToPennies(vehiclesValue.toString()) : 0,
      savings: savingsValue ? inputCurrencyToPennies(savingsValue.toString()) : 0,
      investments: investmensValue ?  inputCurrencyToPennies(investmensValue.toString()): 0,
      other: othersValue ? inputCurrencyToPennies(othersValue.toString()) : 0,
    };
    const total = Object.values(wealthCombined).reduce(function (s, v) {
      return s + v;
    }, 0);
    dispatch(updateEditScenarioWealth(total));
    dispatch(updateScenarioEditWealthParts(wealthCombined));
    handleNextCaseStep();
  };

  const checkIfDisabled = () => {
    const wealthCombined = [
      estateValue,
      companiesValue,
      vehiclesValue,
      savingsValue,
      investmensValue,
      othersValue,
    ];
    const notDisabled = wealthCombined.some(
      (el) => el !== undefined && el !== ""
    );
    return !notDisabled;
  };

  const disabled = checkIfDisabled();

  return (
    <WealthStep
      title="Edycja majątku"
      step={1}
      onSubmit={handleSubmit}
      disabled={disabled}
    >
      <h2 className="text-lg font-semibold text-darkBlue">
        Co wchodzi w skład majątku osoby, której dziedziczenie sprawdzasz?
      </h2>

      <div className="flex justify-center items-center mb-6 mt-3">
        <Button type="button" onClick={() => setPopupWealthInfo(true)}>
          Jak wypełnić?
        </Button>
      </div>

      <div className="flex flex-col gap-5">
        <ExpandableInput
          value={estateValue}
          setValue={setEstateValue}
          name="Nieruchomości"
          id="realestate"
        />
        <ExpandableInput
          value={companiesValue}
          setValue={setCompaniesValue}
          name="Przedsiębiorstwa"
          id="companies"
        />
        <ExpandableInput
          value={vehiclesValue}
          setValue={setVehiclesValue}
          name="Pojazdy"
          id="vehicles"
        />
        <ExpandableInput
          value={savingsValue}
          setValue={setSavingsValue}
          name="Oszczędności"
          id="savings"
        />
        <ExpandableInput
          value={investmensValue}
          setValue={setInvestmentsValue}
          name="Inwestycje"
          id="investmens"
        />
        <ExpandableInput
          value={othersValue}
          setValue={setOthersValue}
          name="Inne (np. biżuteria, sprzęt elektroniczny)"
          id="others"
        />
      </div>
    </WealthStep>
  );
}

export default ScenarioEditWealthStep1;