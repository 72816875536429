import React, { useState } from "react";

import PageWrap from "../../components/layout/PageWrap";

import { SmsCodeGroup } from "./components/SmsCodeGroup";
import VerificationForm from "./components/VerificationForm";

function LoginVerification({ email, submit, sendAgain }: any) {
  const numberOfInputs = 6;
  const [code, setCode] = useState("");

  return (
    <PageWrap>
      <div className="w-full max-w-sm px-4 mx-auto pt-20 text-center">
        <h1 className="text-4xl text-darkBlue font-medium">Kod weryfikacji</h1>
        <p className=" mt-5 mb-14 text-darkGrey">
          Wprowadź kod weryfikacyjny, który wysłaliśmy na adres e-mail
          <span className="text-gold font-bold"> {email}</span>
        </p>
        <div>
          <VerificationForm
            id="smsValidation"
            submitBtn="Potwierdź kod"
            valid={numberOfInputs === code.length}
            onSubmit={() => submit(code)}
          >
            <SmsCodeGroup
              numberOfInputs={numberOfInputs}
              code={code}
              setCode={setCode}
            />
          </VerificationForm>
          <div id="recaptcha-container"></div>
        </div>
        <div className="flex flex-row justify-between text-sm">
          <span className="text-darkGrey  ">Kod weryfikacyjny nie dotarł?</span>
          <span className="text-darkBlue cursor-pointer" onClick={sendAgain}>
            Wyślij ponownie
          </span>
        </div>
      </div>
    </PageWrap>
  );
}

export default LoginVerification;
