import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  scenarioValues: {
    wealth: 0,
  },
  targetDisabled: 0.5,
  inheritanceShare: 0,
  additionalValues: 0,
  beneficiaryValues: 0,
  additionalValuesInfo: {
    isPastInheritances: false,
    isBequests: false,
  },
  portionValue: 0,
  sumWealth: "0",
};

export const partionSlice = createSlice({
  name: "partion",
  initialState,
  reducers: {
    updatePortionSumWealth: (state, action) => {
      state.sumWealth = action.payload;
    },
    updatePortionDisabled: (state, action) => {
      state.targetDisabled = action.payload;
    },
    updateInheritanceShare: (state, action) => {
      state.inheritanceShare = action.payload;
    },
    updateAdditionalValues: (state, action) => {
      state.additionalValues = state.additionalValues += action.payload;
    },
    updateBeneficiaryValues: (state, action) => {
      state.beneficiaryValues = state.beneficiaryValues += action.payload;
    },
    updateAdditionalValuesInfo: (state, action) => {
      state.additionalValuesInfo = {
        ...state.additionalValuesInfo,
        ...action.payload,
      };
    },
    updateScenarioValues: (state, action) => {
      state.scenarioValues.wealth = action.payload.wealth;
    },
    resetPortion: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  updatePortionDisabled,
  updateInheritanceShare,
  updateAdditionalValues,
  updateBeneficiaryValues,
  updateAdditionalValuesInfo,
  updatePortionSumWealth,
  resetPortion,
  updateScenarioValues,
} = partionSlice.actions;
export const getValuesInfo = (state: RootState) =>
  state.partion.additionalValuesInfo;
export const getWealthSum = (state: RootState) => state.partion.sumWealth;
export const getScenarioValues = (state: RootState) => state.partion.scenarioValues;
export const getInheritanceShare = (state: RootState) => state.partion.inheritanceShare;
export const getPortion = (state: RootState) => state.partion;
export default partionSlice.reducer;
