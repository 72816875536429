import classNames from "classnames";
import React from "react";
import { ReactComponent as CheckIcon } from "../../../assets/icons/Check.svg";

function FilterPopup({ handleChangeFilter, selected }: any) {
  const filters = [
    {
      name: "Wszyscy Użytkownicy",
      index: "",
    },
    {
      name: "Zablokowani Użytkownicy",
      index: "ARCHIVED",
    },
    {
      name: "Aktywni Użytkownicy",
      index: "ACTIVE",
    },
  ];

  return (
    <div className="shadow-container rounded-lg bg-white w-80">
      <h4 className="text-darkBlue text-center px-20 p-4 border-b-2 border-light ">
        Filtrowanie
      </h4>
      <div className="flex flex-col px-4">
        {filters.map((el, index) => {
          return (
            <div
              className={classNames(
                "flex flex-row justify-between whitespace-nowrap border-b-2 border-light py-3 text-sm cursor-pointer",
                {
                  "text-darkGrey": selected !== el?.index,
                  "text-darkBlue": selected === el?.index,
                }
              )}
              onClick={() => handleChangeFilter(el?.index)}
              key={index}
            >
              {el?.name}
              {selected === el?.index && <CheckIcon />}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FilterPopup;
