import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import refreshToken from "../../client/helpers/refreshToken";
import { getUserSubscription, setRedirectAfterLoginUrl } from "../../redux/reducers/userSlice";
import { useSelector, useDispatch } from "react-redux";

interface ProtectedRouteInterface {
  children?: JSX.Element;
  accessBy:
    | "non-authenticated"
    | "authenticated"
    | "consultant"
    | "premium"
    | "enterprise"
    | "professional";
  user?: any;
}

const ProtectedRoute = ({
  children,
  accessBy,
  user,
}: ProtectedRouteInterface) => {
  const subscription = useSelector(getUserSubscription);
  const isAuthenticated = Boolean(user);
  const isPremium =
    subscription?.subscriptionType === "PREMIUM" ||
    subscription?.subscriptionType === "ENTERPRISE";
  const isEnterprise = subscription?.enterpriseRole === "ADMIN";
  const isConsultant = user?.role === "CONSULTANT";
  const isProfessional = user?.accountType === "PROFESSIONAL";
  
  const location = useLocation();
  const dispatch = useDispatch();
  refreshToken();

  if(accessBy === "non-authenticated") {
    if(!isAuthenticated) return children ? children : <Outlet />;
    return <Navigate to="/home" />;
  }

  if(accessBy === "authenticated" && isAuthenticated) {
    return children ? children : <Outlet />;
  }

  if (accessBy === "premium" && isPremium) {
    return children ? children : <Outlet />;
  }

  if (accessBy === "enterprise" && isEnterprise) {
    return children ? children : <Outlet />;
  }

  if(accessBy === "consultant" && isConsultant) {
    return children ? children : <Outlet />;
  }

  if (accessBy === "professional" && isProfessional) {
    return children ? children : <Outlet />;
  }

  if (location.pathname === "/plans" ) dispatch(setRedirectAfterLoginUrl(location.pathname));
  return <Navigate to="/login" replace />;
};
export default ProtectedRoute;
