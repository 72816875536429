import React from "react";
import { ReactComponent as SuccessImg } from "../../../../../assets/images/success.svg";

import Header from "../../../../../components/common/text/Header";
import Button from "../../../../../components/common/button/Button";

function ResultSuccess({ handleNavigate }: any) {
  return (
    <div className="w-full my-16">
      <div className="flex flex-col text-center items-center">
        <SuccessImg className="w-full h-auto max-w-lg" />
        <div className="mt-14">
          <Header>Sukces</Header>
          <p className="text-darkGrey text-lg my-5">
            <span>
              Analiza linii sukcesji powiodła się! Możesz teraz przejść do
              wyniku. <br /> Pomyślnie zaktualizowano scenariusz.
            </span>
          </p>
        </div>
        <Button onClick={handleNavigate}>Przejdź do wyniku</Button>
      </div>
    </div>
  );
}

export default ResultSuccess;
