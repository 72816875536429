import React, { useState } from 'react';
import { useDispatch } from "react-redux";

import ExpandableInput from '../../../../components/steps/ExpandableInput';
import WealthStep from '../../../../components/steps/WealthStep';
import Button from '../../../../components/common/button/Button';
import {
  updateScenarioWealth,
  updateScenarioWealthParts,
} from "../../../../redux/reducers/scenarioSlice";
import inputCurrencyToPennies from '../../../../utils/inputCurrencyToPennies';




function ScenarioWealthStep1({ handleNextCaseStep, setPopupWealthInfo }: any) {
  const [estateValue, setEstateValue] = useState<any>();
  const [companiesValue, setCompaniesValue] = useState<any>();
  const [vehiclesValue, setVehiclesValue] = useState<any>();
  const [savingsValue, setSavingsValue] = useState<any>();
  const [investmensValue, setInvestmentsValue] = useState<any>();
  const [othersValue, setOthersValue] = useState<any>();

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const wealthCombined = {
      estate: inputCurrencyToPennies(estateValue),
      companies: inputCurrencyToPennies(companiesValue),
      vehicles: inputCurrencyToPennies(vehiclesValue),
      savings: inputCurrencyToPennies(savingsValue),
      investments: inputCurrencyToPennies(investmensValue),
      other: inputCurrencyToPennies(othersValue),
    };
    const total = Object.values(wealthCombined).reduce(function (s, v) {
      return s + v;
    }, 0);
    dispatch(updateScenarioWealth(total));
    dispatch(updateScenarioWealthParts(wealthCombined));
    handleNextCaseStep();
  };

  const checkIfDisabled = () => {
    const wealthCombined = [
      estateValue,
      companiesValue,
      vehiclesValue,
      savingsValue,
      investmensValue,
      othersValue,
    ];
    const notDisabled = wealthCombined.some(
      (el) => el !== undefined && el !== ""
    );
    return !notDisabled;
  };

  const disabled = checkIfDisabled();

  return (
    <WealthStep
      title="Majątek"
      step={1}
      onSubmit={handleSubmit}
      disabled={disabled}
    >
      <h2 className="text-lg font-semibold text-darkBlue">
        Co wchodzi w skład majątku osoby, której dziedziczenie sprawdzasz?
      </h2>

      <div className="flex justify-center items-center mb-6 mt-3">
        <Button type="button" onClick={() => setPopupWealthInfo(true)}>
          Jak wypełnić?
        </Button>
      </div>

      <div className="flex flex-col gap-5">
        <ExpandableInput
          value={estateValue}
          setValue={setEstateValue}
          name="Nieruchomości"
          id="realestate"
        />
        <ExpandableInput
          value={companiesValue}
          setValue={setCompaniesValue}
          name="Przedsiębiorstwa"
          id="companies"
        />
        <ExpandableInput
          value={vehiclesValue}
          setValue={setVehiclesValue}
          name="Pojazdy"
          id="vehicles"
        />
        <ExpandableInput
          value={savingsValue}
          setValue={setSavingsValue}
          name="Oszczędności"
          id="savings"
        />
        <ExpandableInput
          value={investmensValue}
          setValue={setInvestmentsValue}
          name="Inwestycje"
          id="investmens"
        />
        <ExpandableInput
          value={othersValue}
          setValue={setOthersValue}
          name="Inne (np. biżuteria, sprzęt elektroniczny)"
          id="others"
        />
      </div>
    </WealthStep>
  );
}

export default ScenarioWealthStep1;