import classNames from 'classnames';
import React from 'react';
import { ReactComponent as CheckIcon } from "../../../assets/icons/Check.svg";

function FilterPopup( { handleChangeFilter, selected }: any ) {
    const filters = [
      {
        name: "Sortuj według nazwy - Rosnąco",
        index: 1,
      },
      {
        name: "Sortuj według nazwy - Malejąco",
        index: -1,
      },
      // {
      //   name: "Sortuj według emaila - Rosnąco",
      //   index: 2,
      // },
      // {
      //   name: "Sortuj według emaila - Malejąco",
      //   index: -2,
      // },
      // {
      //   name: "Sortuj według nr. telefonu - Rosnąco",
      //   index: 3,
      // },
      // {
      //   name: "Sortuj według nr. telefonu - Malejąco",
      //   index: -3,
      // },
    ];

    return (
      <div className="shadow-container rounded-lg bg-white w-80">
        <h4 className="text-darkBlue text-center px-20 p-4 border-b-2 border-light ">
          Sortowanie
        </h4>
        <div className="flex flex-col px-4">
            {filters.map((el, index) => {
                return (
                  <div
                    className={classNames(
                      "flex flex-row justify-between whitespace-nowrap border-b-2 border-light py-3 text-sm cursor-pointer",
                      {
                        "text-darkGrey": selected !== el?.index,
                        "text-darkBlue": selected === el?.index,
                      }
                    )}
                    onClick={() => handleChangeFilter(el?.index)}
                    key={index}
                  >
                    {el?.name}
                    {selected === el?.index && <CheckIcon />}
                  </div>
                );
            })}
        </div>
      </div>
    );
}

export default FilterPopup;