import Cookies from "js-cookie";
import getDays from "../../utils/dates/getDays";


const setJwt = (data: any) => {
    const { token, expiresOn, refreshToken, refreshExpiresOn } = data;
    const tokenDays = getDays(expiresOn);
    const refreshTokenDays = getDays(refreshExpiresOn);
    Cookies.set("jwt", token, { expires: tokenDays});
    Cookies.set("jwtRefresh", refreshToken, { expires: refreshTokenDays });
    Cookies.set("jwtExpire", `${expiresOn}`, { expires: tokenDays });
};

export default setJwt;