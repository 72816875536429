import React, { useState, useEffect, useRef } from "react";

import SubHeader from "../../components/common/text/SubHeader";
import PageWrap from "../../components/layout/PageWrap";
import SearchBar from "./components/SearchBar";
import ClientsTable from "./components/ClientsTable";
import Pagination from "../../components/specific/Pagination";
import FilterPopup from "./components/FilterPopup";
import Button from "../../components/common/button/Button";
import CreateClient from "./components/CreateClient";
import ShadowContentWrap from "../../components/layout/ShadowContentWrap";
import LoadingSpinner from "../../components/specific/LoadingSpinner";

import { ClickOutside } from "../../components/specific/ClickOutside";

import { ReactComponent as FiltersIcon } from "../../assets/icons/Filters.svg";

import { getClientBase } from "../../client/AppClient";
import displayError from "../../utils/handling-errors/displayError";
import { useDispatch } from "react-redux";


function ClientBase() {
  const [loading, setLoading] = useState(true);
  const [filterVisibility, setFilterVisibility] = useState(false);
  const [popupOpened, setPopupOpened] = useState(false);

  const [currentFilter, setCurrentFilter] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [fetchedData, setFetchedData] = useState([]);

  const [totalClients, setTotalClients] = useState(0);

  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const effectCalled = useRef<boolean>(false);

  useEffect(() => {
    const fetchClientBase = async () => {
      const response = await getClientBase(
        currentPage,
        itemsPerPage,
        currentFilter,
        searchValue
      );
      if (response.status !== 200) return displayError(dispatch, response);
      
       setFetchedData(response.data.items);
       setTotalClients(response.data.total);
    };
    fetchClientBase();
      if (!effectCalled.current) {
        // call only on first render
        setLoading(false);
      }
      effectCalled.current = true; 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter, searchValue, currentPage, popupOpened]);

  const handleFilterVisibility = () => {
    setFilterVisibility((prev) => !prev);
  };
  const handleClose = () => {
    setTimeout(() => {
      setFilterVisibility(false);
    }, 1);
  };

  const handleSearchChange = (searchValue: string) => {
    setCurrentPage(1);
    setSearchValue(searchValue);
  };

  const handleChangeFilter = (filter: number) => {
    if (filter === currentFilter) return;
    setCurrentFilter(filter);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <CreateClient popupOpened={popupOpened} setPopupOpened={setPopupOpened} />
      <PageWrap>
        <div className="w-full max-w-6xl mx-auto ">
          <ShadowContentWrap>
            <div className="flex flex-col md:flex-row justify-between mb-10 gap-5 md:gap-0">
              <div className="w-full md:w-1/2">
                <SubHeader>Baza scenariuszy</SubHeader>
              </div>
              <div className="flex flex-row items-center gap-3 w-full md:w-2/5 relative">
                <SearchBar handleSearchChange={handleSearchChange} />
                <FiltersIcon
                  className="cursor-pointer"
                  onClick={handleFilterVisibility}
                />
                <ClickOutside
                  onClickOutside={handleClose}
                  showInfo={filterVisibility}
                >
                  <div className="absolute mt-2 z-10 right-0 top-full">
                    <FilterPopup
                      handleChangeFilter={handleChangeFilter}
                      selected={currentFilter}
                    />
                  </div>
                </ClickOutside>
              </div>
            </div>
            {searchValue.length > 0 && (
              <h3 className="text-xl font-semibold text-darkBlue mb-6">
                Wyniki wyszukiwania
              </h3>
            )}
            <ClientsTable data={fetchedData} />
            <Pagination
              total={totalClients}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={(page: any) => setCurrentPage(page)}
            />
            <div className="flex justify-center mt-10">
              <Button onClick={() => setPopupOpened(true)}>
                Utwórz profil Osoby
              </Button>
            </div>
          </ShadowContentWrap>
        </div>
      </PageWrap>
    </>
  );
}

export default ClientBase;
