import { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import React, { useState, useEffect } from 'react';
import { getRtcFromLocalStorage } from '../helpers/agoraLocalStorage';

interface CallInfoInterface {
    client: IAgoraRTCClient
}
const consultantName: { [type: string]: string } = {
  "CUSTOMER_ADVISOR": "Doradca Klienta",
  "SUCCESSION_ADVISOR": "Doradca sukcesyjny",
  "INSURANCE_AGENT": "Agent ubezpieczeniowy"
}; 

function CallInfo({ client }: CallInfoInterface) {
    const [callDuration, setCallDuration] = useState<any>(null);
    const [fullName, setFullName] = useState<any>(null);
    const [consultantType, setConsultantType] = useState<any>(null);
    
    useEffect(() => {
        const agoraData = getRtcFromLocalStorage();
        if (agoraData === null) return setCallDuration(null); 
        setFullName(agoraData.fullname);
        setConsultantType(agoraData.consultantType);
        const startDate = new Date(agoraData.startDate);
        const calculateDuration = setInterval(() => {
            const timeDiff = new Date().getTime() - startDate.getTime();
            const hours = Math.floor(timeDiff / 3600000);
            const minutes = Math.floor((timeDiff % 3600000) / 60000);
            const seconds = Math.floor((timeDiff % 60000) / 1000);
            setCallDuration(
              `${hours.toString().padStart(2, "0")}:${minutes
                .toString()
                .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
            );
        }, 1000);
        
        return () => clearInterval(calculateDuration);
    }, [])

  if (callDuration === null && fullName === null && consultantType === null) return null;
    
  return (
    <div className="absolute bottom-[13%] md:bottom-10 left-5 md:left-10 text-left z-10">
      {fullName !== null && (
        <h3 className="text-3xl font-bold text-white mb-1">{fullName}</h3>
      )}

      {consultantType !== null && (
        <p className="text-white mb-3">{consultantName[consultantType]}</p>
      )}

      {callDuration !== null && (
        <div className="controls p-3 max-w-fit flex justify-center bg-white bg-opacity-20 rounded-lg text-white z-20">
          {callDuration}
        </div>
      )}
    </div>
  );
}

export default CallInfo;