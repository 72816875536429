import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import RadioSelect from "../../../../components/common/forms/RadioSelect";
import ExpandableInput from "../../../../components/steps/ExpandableInput";
import WealthStep from "../../../../components/steps/WealthStep";
import Button from "../../../../components/common/button/Button";
import inputCurrencyToPennies from "../../../../utils/inputCurrencyToPennies";
import { substractScenarioEditWealth, updateScenarioEditDebts } from "../../../../redux/reducers/scenarioEditSlice";

function ScenarioEditWealthStep2({
  handleNextCaseStep,
  setPopupWealthInfo,
  wealthDebts,
}: any) {
  const [loansValue, setLoansValue] = useState<any>(wealthDebts?.loans !== 0 && (wealthDebts?.loans / 100));
  const [creditsValue, setCreditsValue] = useState<any>(wealthDebts?.credits !== 0 && (wealthDebts?.credits / 100));
  const [overduePaymentsValue, setOverduePaymentsValue] = useState<any>(wealthDebts?.overduePayments !== 0 && (wealthDebts?.overduePayments / 100));
  const [otherDebtsValue, setOtherDebtsValue] = useState<any>(wealthDebts?.otherDebts !== 0 && (wealthDebts?.otherDebts / 100));
  const [noDebts, setNoDebts] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const debtsCombined = [
      loansValue,
      creditsValue,
      overduePaymentsValue,
      otherDebtsValue,
    ];
    const checker = debtsCombined.some((el) => el !== undefined);
    if (noDebts && checker) {
      setNoDebts(false);
    }
  }, [
    loansValue,
    creditsValue,
    overduePaymentsValue,
    otherDebtsValue,
    noDebts,
  ]);

  const handleSubmit = () => {
    const debtsCombined = {
      loans: loansValue ? inputCurrencyToPennies(loansValue.toString()) : 0,
      credits: creditsValue ? inputCurrencyToPennies(creditsValue.toString()) : 0,
      overduePayments: overduePaymentsValue ? inputCurrencyToPennies(overduePaymentsValue.toString()) : 0,
      other: otherDebtsValue ? inputCurrencyToPennies(otherDebtsValue.toString()) : 0,
    };
    const total = Object.values(debtsCombined).reduce(function (s, v) {
      return s + v;
    }, 0);
    dispatch(substractScenarioEditWealth(total));
    if (!noDebts) dispatch(updateScenarioEditDebts(debtsCombined));
    handleNextCaseStep();
  };

  const checkIfDisabled = () => {
    const debtsCombined = [
      loansValue,
      creditsValue,
      overduePaymentsValue,
      otherDebtsValue,
      noDebts,
    ];
    const notDisabled = debtsCombined.some(
      (el) => el !== undefined && el !== "" && el !== false
    );
    return !notDisabled;
  };

  const disabled = checkIfDisabled();

  return (
    <WealthStep
      title="Majątek"
      step={2}
      onSubmit={handleSubmit}
      disabled={disabled}
    >
      <h2 className="text-lg font-semibold text-darkBlue mb-4">
        Jakie zobowiązania posiada Osoba, której dziedziczenie sprawdzasz?
      </h2>

      <div className="flex justify-center items-center mb-6 mt-3">
        <Button type="button" onClick={() => setPopupWealthInfo(true)}>
          Jak wypełnić?
        </Button>
      </div>

      <div className="flex flex-col gap-5">
        <ExpandableInput
          value={loansValue}
          setValue={setLoansValue}
          name="Kredyty"
          id="credits"
          closeHelper={noDebts}
        />
        <ExpandableInput
          value={creditsValue}
          setValue={setCreditsValue}
          name="Pożyczki"
          id="loans"
          closeHelper={noDebts}
        />
        <ExpandableInput
          value={overduePaymentsValue}
          setValue={setOverduePaymentsValue}
          name="Zaległe płatności"
          id="overduepayments"
          closeHelper={noDebts}
        />
        <ExpandableInput
          value={otherDebtsValue}
          setValue={setOtherDebtsValue}
          name="Inne długi"
          id="otherdebts"
          closeHelper={noDebts}
        />
        <RadioSelect
          value={noDebts.toString()}
          id="nodebts"
          group="nodebts"
          selected={"true"}
          onClick={() => setNoDebts((prev) => !prev)}
        >
          Brak
        </RadioSelect>
      </div>
    </WealthStep>
  );
}

export default ScenarioEditWealthStep2;
