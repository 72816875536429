import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateScenarioEditChoiceAnswers } from "../../../redux/reducers/scenarioEditSlice";

import RadioSelect from "../../../components/common/forms/RadioSelect";
import Text from "../../../components/common/text/Text";
import NextStep from "../../../components/steps/NextStep";

interface IScenarioEditSelect {
  onSubmit: (value: string) => void;
  data: any;
  choiceAnswers: any[];
}

function ScenarioEditSelect({
  onSubmit,
  data,
  choiceAnswers,
}: IScenarioEditSelect) {
  const [value, setValue] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    choiceAnswers.forEach((answer: string[]) => {
      if (answer[0] === data.id) setValue(answer[1]);
    });
  }, [choiceAnswers, data.id]);

  const handleSubmit = () => {
    if (value.length > 0) {
      dispatch(updateScenarioEditChoiceAnswers({ [data.id]: value }));
      onSubmit(value);
    }
  };

  return (
    <NextStep
      title={"Edycja scenariusza"}
      onSubmit={handleSubmit}
      disabled={value.length === 0}
    >
      <h2 className="text-lg font-semibold text-darkBlue">
        {data?.questionText}
      </h2>
      <div className="flex flex-col gap-3 mt-5">
        {data?.answers.map((el: any, key: number) => {
          return (
            <RadioSelect
              id={el.id}
              group={data?.id}
              selected={value}
              value={el.id}
              onClick={(e: any) => setValue(e.target.value)}
              key={key}
            >
              {el.text}
            </RadioSelect>
          );
        })}
      </div>
      <Text>{data?.explains}</Text>
    </NextStep>
  );
}

export default ScenarioEditSelect;
