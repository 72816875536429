import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { editClient } from '../../../client/AppClient';
import Form from '../../../components/common/forms/Form';
import InputBordered from '../../../components/common/forms/InputBordered';
import TextAreaBordered from '../../../components/common/forms/TextAreaBordered';
import Popup from '../../../components/specific/Popup';
import displayError from '../../../utils/handling-errors/displayError';
import displaySuccess from '../../../utils/handling-success/displaySuccess';
import { inputOnlyLetters } from '../../../validations/inputValidations';

interface EditProfileInterface {
  data: any;
  popupOpened: boolean;
  setPopupOpened: Function;
  effectCalled?: any;
};

function EditProfile({
  data,
  popupOpened,
  setPopupOpened,
  effectCalled,
}: EditProfileInterface) {
  const [fullname, setFullname] = useState("");
  const [additionalData, setAdditionalData] = useState("");
  const [note, setNote] = useState("");
  const [errors, setErrors] = useState<any>(null);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setErrors(null);
    const clientData = {
      id: data.id,
      userId: data.userId,
      fullName: fullname,
      additionalData: additionalData,
      description: note,
    };
    const response = await editClient(clientData);
    if (response.status !== 200) {
      const errorResponse = displayError(dispatch, response);
      if (errorResponse !== undefined) setErrors(errorResponse);
      return;
    }
    effectCalled.current = false;
    displaySuccess(dispatch, "Sukces", "Zapisano zmiany");
    setPopupOpened(false);
  };

  useEffect(() => {
    if (data.fullName) setFullname(data.fullName);
    if (data.additionalData) setAdditionalData(data.additionalData);
    if (data.description) setNote(data.description);
  }, [data]);

  return (
    <Popup
      title={"Edytuj profil Osoby"}
      trigger={popupOpened}
      setTrigger={setPopupOpened}
    >
      <div className="flex flex-col">
        <Form
          id="editProfile"
          submitBtn={"Zapisz"}
          onSubmit={handleSubmit}
          buttonSize="md"
        >
          <InputBordered
            label="Nazwa"
            name="name"
            type="text"
            placeholder="Oznaczenie członka rodziny lub dane Klienta"
            divClassName="my-8"
            value={fullname}
            onChange={(e: any) => setFullname(e.target.value)}
            onKeyPress={inputOnlyLetters}
            error={errors?.fullName}
            required
          />
          <InputBordered
            label="Dane dodatkowe"
            name="additionalInfo"
            type="text"
            placeholder="Np. rozwinięcie nazwy scenariusza lub datę utworzenia"
            divClassName="my-8"
            value={additionalData}
            onChange={(e: any) => setAdditionalData(e.target.value)}
            error={errors?.additionalData}
          />
          <TextAreaBordered
            label="Notatka"
            name="pesel"
            placeholder="Wpisz notatkę..."
            divClassName="my-8"
            value={note}
            onChange={(e: any) => setNote(e.target.value)}
          />
        </Form>
      </div>
    </Popup>
  );
}

export default EditProfile;