import getRequest from "../../requests/getRequest";

export const requestContactService = async (role: string) => {
  const result = await getRequest({
    url: "/consultant/requestContact",
    params: {
      role: role,
    },
  });
  return result;
};
