// import React, { useState, useEffect } from 'react';
import { View, Text } from '@react-pdf/renderer';
import styles from '../styles/PdfStyles';

function PdfRegulations({ regulations }: any) {
  // const [article, setArticle] = useState("");
  // const [texts, setTexts] = useState<string[]>([]);

  // useEffect(() => {
  //   const splittedRegulations = regulations.split("\r\n");
  //   if (splittedRegulations[0].includes("art")) {
  //       setArticle(splittedRegulations[0]);
  //       splittedRegulations.shift();
  //   }
  //   setTexts(splittedRegulations);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  const splittedRegulations = regulations.split("\r\n");

  let article = "";
  let texts: any = [];


  if (splittedRegulations[0].includes("art")) {
    article = splittedRegulations[0];
    splittedRegulations.shift();
  }
  texts = splittedRegulations;

  if (!regulations || texts.length === 0) return null;

  return (
    <View>
      <Text style={styles.subheader}>Przepis prawa</Text>
      <Text style={styles.article}>{article}</Text>
      {texts?.map((el: any, index: number) => {
        return <Text style={styles.regulations} key={index}>{el}</Text>;
      })}
    </View>
  );
}

export default PdfRegulations;