import React, { useState } from "react";

import { useDispatch } from "react-redux";
import {
  updateAdditionalValues,
  updateAdditionalValuesInfo,
} from "../../../redux/reducers/partionSlice";

import Accordion from "../../../components/common/controls/Accordion";
import RadioSelect from "../../../components/common/forms/RadioSelect";
import NextStep from "../../../components/steps/NextStep";
import ExpandableInputGroup from "../components/ExpandableInputGroup";
import inputCurrencyToNumber from "../../../utils/inputCurrencyToNumber";

function LegalPortionBequests({ onSubmit }: any) {
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (value !== undefined) {
      const valueNumber = inputCurrencyToNumber(value);
      if (selectedValue === "yes") {
        dispatch(updateAdditionalValuesInfo({ isBequests: true }));
      }
      dispatch(updateAdditionalValues(valueNumber));
      onSubmit();
    }
  };

  return (
    <NextStep
      title={"Zachowek"}
      onSubmit={handleSubmit}
      disabled={value === undefined || value === ""}
    >
      <h2 className="text-lg font-semibold text-darkBlue">
        Czy w testamencie są/będą ustanowione zapisy windykacyjne?
      </h2>
      <div className="flex flex-col gap-3 mt-5">
        <ExpandableInputGroup
          group="legalportionbequests"
          name="Tak"
          value={value}
          setValue={setValue}
          selectValue="yes"
          setSelected={setSelectedValue}
          selected={selectedValue}
        />
        <RadioSelect
          id="legalportionbequestssno"
          group="legalportionbequests"
          selected={selectedValue}
          value="0"
          onClick={(e: any) => {
            setValue(e.target.value);
            setSelectedValue(e.target.value);
          }}
        >
          Nie
        </RadioSelect>
      </div>
      <p className="text-darkGrey mt-5 mb-7">
        Jeśli tak, wpisz ile wynoszą zapisy windykacyjne.
      </p>
      <Accordion title="Darowizny - dodatkowe informacje">
        <p className="text-darkGrey">Tekst o zapisach windykacyjnych</p>
      </Accordion>
    </NextStep>
  );
}

export default LegalPortionBequests;
