import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Text from '../../../../components/common/text/Text';
import NextStep from '../../../../components/steps/NextStep';
import ScenarioInput from '../../../../components/steps/StepsInput';
import { updateScenarioName } from '../../../../redux/reducers/scenarioSlice';




function ScenarioName({ handleNextCaseStep }: any) {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(updateScenarioName(value));
    handleNextCaseStep();
  };

  return (
    <NextStep
      title={"Nowy scenariusz"}
      onSubmit={handleSubmit}
      disabled={value.length === 0}
    >
      <h2 className="text-lg font-semibold text-darkBlue">Nazwa scenariusza</h2>
      <ScenarioInput
        name="name"
        placeholder="np. Śmierć partnera/ki"
        divClassName="my-8"
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
        required
      />
      <Text>
        Pamiętaj, aby nazwa scenariusza odzwierciedlała sytuację Osoby. Pozwoli
        to na swobodne poruszanie się po aplikacji w późniejszych etapach.
      </Text>
      <Text>
        Nazwę zawsze możesz zmienić klikając na edycję scenariusza w szczegółach
        wygenerowanego wyniku.
      </Text>
    </NextStep>
  );
}

export default ScenarioName;