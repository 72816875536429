import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications, removeNotifications } from "../../redux/reducers/notificationsSlice";
import Notification from "../specific/Notification";


function NotificationsGlobal() {
  const notifications = useSelector(getNotifications);
  const dispatch = useDispatch();
  return (
    <>
      {notifications?.map((el: any, index: any) => {
        return (
          <Notification
            key={index}
            duration={5000}
            title={el.title}
            message={el.message}
            type={el.type}
            handleRemoveNotification={() => {
              dispatch(removeNotifications([...notifications].splice(index, 0)));
            }}
          />
        );
      })}
    </>
  );
}

export default NotificationsGlobal;
