import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { setNotifications } from "../../redux/reducers/notificationsSlice";

const displaySuccess = (
  dispatch: Dispatch<AnyAction>,
  title: string,
  message: string
) => {
  const alert: any = {
    title: title,
    message: message,
    type: "success",
  };
  dispatch(setNotifications(alert));
};

export default displaySuccess;
