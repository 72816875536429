import React from "react";

interface Carousel3Props {
  slide1: any;
  slide2: any;
  slide3: any;
  arrows?: boolean;
}

function Carousel3({ slide1, slide2, slide3, arrows }: Carousel3Props) {
  return (
    <div id="carouselExampleCaptions" className="carousel slide relative" data-bs-ride="carousel">
      
      <div className="carousel-inner relative w-full overflow-hidden my-8">
        <div className="carousel-item active relative float-left w-full">
          {slide1}
        </div>
        <div className="carousel-item relative float-left w-full">
          {slide2}
        </div>
        <div className="carousel-item relative float-left w-full">
          {slide3}
        </div>
      </div>

      <div className="carousel-indicators right-0 bottom-0 left-0 flex justify-center p-0 mb-8">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>

      {arrows && <>
        <button
          className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </>
      }
    </div>
  );
}

Carousel3.defaultProps = {
  arrows: false
};

export default Carousel3;
