import React from 'react';
import { ReactComponent as AnchorIcon } from "../../../assets/icons/Anchor.svg"
import parse from "html-react-parser";

function InfoTabs({ currentAdnotation, setPopupData }: any) {
  const handleRenderAndotationPopup = (title: string, wysiwyg: string) => {
    setPopupData({
      opened: true,
      title: title,
      message: (
        <div className="adnotation w-full max-h-[400px] overflow-auto">
          {parse(wysiwyg)}
        </div>
      ),
    });
  };

  if (currentAdnotation.index.length > 0) {
    return (
      <div className="flex flex-col shadow-small mb-8 rounded cursor-pointer">
        {currentAdnotation.data?.map((tab: any, index: number) => {
          return (
            <div
              key={index}
              onClick={() => handleRenderAndotationPopup(tab.title, tab.answer)}
              className="flex flex-row justify-between items-center px-4 py-6 border-b-2 border-light last:border-b-0"
            >
              <span className="text-darkBlue">{tab.title}</span>
              <AnchorIcon />
            </div>
          );
        })}
      </div>
    );
  } else return null;
}

export default InfoTabs;