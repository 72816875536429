import React, { useEffect } from 'react';
import LoadingSpinner from '../../components/specific/LoadingSpinner';
import PageWrap from '../../components/layout/PageWrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { googleTokenRequest } from '../../client/services/auth/googleTokenRequest';
import displayError, { displayCustomError } from '../../utils/handling-errors/displayError';
import { socialAuthService } from '../../client/services/auth/socialAuthService';
import { getUserProfile } from '../../client/AppClient';
import { setUser } from '../../redux/reducers/userSlice';

function SocialAuthPage() {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");
    // const token = urlParams.get("token");
    // const expireDate = urlParams.get("expires");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleGoogleAuth = async () => {
      try {
        const googleResponse = await googleTokenRequest(code!);
        if (googleResponse.status !== 200) {
          displayCustomError("Wystąpił błąd przy logowaniu", dispatch);
          return navigate("/login");
        }
        const idToken = googleResponse.data.id_token;
        const response = await socialAuthService("GOOGLE", idToken);
        if (response.status !== 200) {
          displayError(dispatch, response);
          return navigate("/login");
        }
        const userDetails = await getUserProfile();
        if (userDetails.status !== 200) {
            navigate("/login")
            return displayError(dispatch, userDetails);
        }
        dispatch(setUser(userDetails.data));
        navigate("/");
      } catch (error) {
        displayCustomError("Wystąpił błąd przy logowaniu", dispatch);
        return navigate("/login");
      }
    };

    useEffect(() => {
      if (location.pathname.includes("google")) handleGoogleAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <PageWrap>
        <div className="w-full max-w-sm px-4 mx-auto pt-20 text-center">
            <LoadingSpinner />
        </div>
      </PageWrap>
    );
}

export default SocialAuthPage;