import postRequest from "../../requests/postRequest";

interface addClientInterface {
  fullName: string;
  address: string;
}

export const editAccountService = async (body: addClientInterface) => {
  const result = await postRequest({
    url: "/user/profile/update",
    params: {
      fullname: body.fullName,
      address: body.address,
    },
  });
  return result;
};
