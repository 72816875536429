import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  scenarioResult: {},
};

export const scenarioResultSlice = createSlice({
  name: "scenarioResult",
  initialState,
  reducers: {
    setScenarioResult: (state, action) => {
      state.scenarioResult = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setScenarioResult } = scenarioResultSlice.actions;
export const getScenarioResult = (state: RootState) =>
  state.scenarioResult.scenarioResult;
export default scenarioResultSlice.reducer;
