import React from "react";
import { useDispatch } from "react-redux";
import AddClientForm from "../../../components/final-forms/AddClientForm";
import Popup from "../../../components/specific/Popup";
import displaySuccess from "../../../utils/handling-success/displaySuccess";

interface CreateClientInterface {
  popupOpened: boolean;
  setPopupOpened: Function;
}

function CreateClient({
  popupOpened,
  setPopupOpened,
}: CreateClientInterface) {
  const dispatch = useDispatch();

  const handleOnSuccess = () => {
    displaySuccess(dispatch, "Sukces", "Dodano Osobę");
    setPopupOpened(false);
  }

  return (
    <Popup
      title={"Nowa Osoba"}
      trigger={popupOpened}
      setTrigger={setPopupOpened}
    >
      <AddClientForm 
        handleSuccessSubmit={handleOnSuccess} 
        submitBtn="Utwórz profil Osoby"
      />
    </Popup>
  );
}

export default CreateClient;
