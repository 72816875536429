import logo from "../../assets/images/logo.png";

import classNames from "classnames";
import React from "react";

type Props = {
  className?: string;
  id?: string;
  width?: string;
};

const Logo = (props: Props): JSX.Element => (
  <div className="relative p-2" id={props.id}>
    <img
      className={classNames("", props.className)}
      src={logo}
      alt="Successio"
      width={props.width}
    />
  </div>
);

Logo.defaultProps = {
  className: "",
};

export default Logo;
