import postRequest from "../../requests/postRequest";

export const saveScenarioService = async (body: any, clientId: string, scenarioId?: string) => {
  const result = await postRequest({
    url: `/scenario/${clientId}/scenario/save`,
    params: {
      name: body.name,
      divisionOfAssets: body.divisionOfAssets,
      resultId: body.result.id,
      wealth: body.wealth,
      choiceAnswers: body.choiceAnswers,
      numericAnswers: body.numericAnswers,
      numericListAnswers: body.numericListAnswers,
      id: scenarioId,
    },
  });
  return result;
};
