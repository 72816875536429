import { configureStore } from '@reduxjs/toolkit'
import userSlice from "./reducers/userSlice";
import scenarioSlice from './reducers/scenarioSlice'
import partionSlice from './reducers/partionSlice'
import notificationsSlice from "./reducers/notificationsSlice";
import scenarioResultSlice from './reducers/scenarioResultSlice';
import scenarioEditSlice from './reducers/scenarioEditSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    scenario: scenarioSlice,
    scenarioEdit: scenarioEditSlice,
    scenarioResult: scenarioResultSlice,
    partion: partionSlice,
    notifications: notificationsSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch