import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateEditScenarioWealth } from '../../../../redux/reducers/scenarioEditSlice';

import Text from '../../../../components/common/text/Text';
import NextStep from '../../../../components/steps/NextStep';
import WealthInput from "../../../../components/steps/WealthInput";

import inputCurrencyToPennies from '../../../../utils/inputCurrencyToPennies';

function ScenarioEditWealth({ handleNextCaseStep, totalWealth }: any) {
  const [value, setValue] = useState<any>((totalWealth / 100 ).toString());

  const dispatch = useDispatch();


  const handleSubmit = () => {
    const formattedCurrency = inputCurrencyToPennies(value);

    dispatch(updateEditScenarioWealth(formattedCurrency));
    handleNextCaseStep(3);
  };

  const openWealthForm = () => {
    handleNextCaseStep();
  };

  return (
    <NextStep
      title={"Edycja scenariusza"}
      onSubmit={handleSubmit}
      disabled={value.length === 0}
    >
      <h2 className="text-lg font-semibold text-darkBlue">
        Majątek pomniejszony o długi
      </h2>
      <WealthInput
        name="wealth"
        placeholder="Wpisz wartość..."
        divClassName="my-8"
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
        required
      />
      <div className="flex flex-row justify-between items-center gap-2 mb-7">
        <span className="font-semibold text-darkGrey text-left">
          Nie wiesz co tu wpisać?
        </span>
        <span
          className="font-semibold text-darkBlue text-right cursor-pointer"
          onClick={openWealthForm}
        >
          Wypełnij formularz
        </span>
      </div>

      <Text>
        W przypadku finansów osobistych wartość netto majątku, to wartość
        rynkowa posiadanego majątku, pomniejszona o kwotę wszystkich zobowiązań
        (długów), którymi ten majątek finansujemy.
      </Text>
    </NextStep>
  );
}

export default ScenarioEditWealth;