import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import {ReactComponent as AnchorIcon} from '../../../assets/icons/AnchorVertical.svg';

type AccordionProps = {
  title: string;
  children: any;
};

const Chevron = ({ rotate }: any) => {
  return (
    <AnchorIcon
      className={classnames("transition-all duration-200", {
        "rotate-180": rotate === true,
        "rotate-0": rotate === false,
      })}
    />
  );
};

const Accordion = ({ title, children }: AccordionProps) => {
  const [height, setHeight] = useState<string>("0px"); 
  const [isOpened, setIsOpened] = useState(false);
  const contentElement = useRef<HTMLDivElement>(null);
  

  useEffect(() => {
    if (contentElement.current) {
      setHeight(isOpened ? `${contentElement.current.scrollHeight}px` : "0px");
    }
  }, [isOpened]);

  const HandleOpening = () => {
    setIsOpened((prev) => !prev);
  };

  return (
    <div className="shadow-small bg-white p-6">
      <div
        onClick={HandleOpening}
        className={
          "flex justify-between items-center w-full cursor-pointer"
        }
      >
        <span className="text-darkBlue">{title}</span>
        {isOpened ? <Chevron rotate={true} /> : <Chevron rotate={false} />}
      </div>
      <div
        ref={contentElement}
        style={{ height: height }}
        className="overflow-hidden transition-all duration-200"
      >
        <div className="mt-4 text-darkGrey">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
