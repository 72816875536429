const specificFields: { [field: string]: string } = {
  NO_OF_CHILDREN: "",
  NO_OF_DEAD_CHILDREN: "",
  NO_OF_DEAD_CHILDRENS_CHILDREN: "Wpisz wartość dla dziecka ",
  NO_OF_DEAD_GRANDPARENTS: "",
  NO_OF_DEAD_GRANDPARENTS_CHILDREN: "Wpisz wartość dla dziadków  ",
  NO_OF_DEAD_GRANDPARENTS_DEAD_CHILDREN: "Wpisz wartość dla dziadków  ",
  NO_OF_DEAD_GRANDPARENTS_DEAD_CHILDRENS_CHILDREN: "Wpisz wartość dla zmarłego dziecka  ",
  NO_OF_DEAD_GRANDCHILDREN: "Wpisz wartość dla zmarłego dziecka  ",
  NO_OF_DEAD_SIBLINGS: "",
  NO_OF_DEAD_SIBLINGS_CHILDREN: "Wpisz wartość dla brata/siostry ",
  NO_OF_DEAD_STEPCHILDREN: "Wpisz wartość dla pasierba ",
  NO_OF_GRANDPARENTS: "",
  NO_OF_SIBLINGS: "",
  NO_OF_STEPCHILDREN: "",
};

const getSpecificFieldPlaceholder = (field: string) => {
    return specificFields[field]
}

export default getSpecificFieldPlaceholder;