import React, { useState } from "react";
import PageWrap from "../../components/layout/PageWrap";
import Form from "../../components/common/forms/Form";
import Input from "../../components/common/forms/Input";
import { Link } from "react-router-dom";
import LoginVerification from "../verification/LoginVerification";
import { setUser } from "../../redux/reducers/userSlice";
import { useDispatch } from "react-redux";
import { loginRequest, twoFactorLoginRequest } from "../../client/AppClient";
import { getUserProfile } from "../../client/AppClient";
import displayError from "../../utils/handling-errors/displayError";
import SocialAuth from "../../components/auth/SocialAuth";

export default function Login() {
  const [email, setEmail] = useState("");
  const [passwd, setPasswd] = useState("");
  const [isTwoFactor, setIsTwoFactor] = useState(false);
  const [errors, setErrors] = useState<any>(null);

  const dispatch = useDispatch();

  const handleLogin = async () => {
    const loginResponse = await loginRequest({ email, password: passwd });
    if (loginResponse.status !== 200) {
      const errorResponse = displayError(dispatch, loginResponse);
      if (errorResponse !== undefined) setErrors(errorResponse);
      return;
    }
    const isTwoFactor = loginResponse.data.twoFactor;
    if(isTwoFactor === "true") return setIsTwoFactor(true);
    const userDetails = await getUserProfile();
    if (userDetails.status !== 200) return displayError(dispatch, userDetails);
    dispatch(setUser(userDetails.data));
  };

  const handleTwoFactorLogin = async (token: string) => {
    const twoFactorLoginResponse = await twoFactorLoginRequest({
      email,
      password: passwd,
      token,
    });
    if (twoFactorLoginResponse.status !== 200) return displayError(dispatch, twoFactorLoginResponse);
    const userDetails = await getUserProfile();
    if (userDetails.status !== 200) return displayError(dispatch, userDetails);
    dispatch(setUser(userDetails.data));
  }

  if (isTwoFactor) {
    return (
      <LoginVerification
        email={email}
        submit={handleTwoFactorLogin}
        sendAgain={handleLogin}
      />
    );
  }

  return (
    <PageWrap>
      <div className="w-full max-w-sm px-4 mx-auto pt-20 text-center">
        <Form id="login" submitBtn="Zaloguj się" onSubmit={handleLogin}>
          <Input
            label="E-mail"
            name="E-mail"
            type="text"
            placeholder="Wpisz e-mail"
            divClassName="my-8"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            error={errors?.email}
            required
          />
          <Input
            label="Hasło"
            name="passwd"
            type="password"
            placeholder="Wpisz swoje hasło"
            divClassName="my-8"
            value={passwd}
            onChange={(e: any) => setPasswd(e.target.value)}
            error={errors?.passwd}
            required
          />
          <div className="text-right">
            <Link to="/login/passwordForget" className="text-darkBlue text-sm">
              Zapomniales hasla?
            </Link>
          </div>
          <SocialAuth />
        </Form>
        <div className="text-center text-darkGrey">
          Nie masz jeszcze konta?{" "}
          <Link to="/register" className="text-darkBlue font-semibold">
            Zarejestruj się
          </Link>
        </div>
      </div>
    </PageWrap>
  );
}
