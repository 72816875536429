import React from 'react';
import Checkbox from '../../../components/common/forms/Checkbox';

interface IRegisterCheckboxes {
  accountType: string;
  setTerms: (checked: boolean) => void;
  setMarketing: (checked: boolean) => void;
}

function RegisterCheckboxes({
  accountType,
  setTerms,
  setMarketing,
}: IRegisterCheckboxes) {
  return (
    <>
      {accountType === "PROFESSIONAL" && (
        <Checkbox
          id="copmanyrights"
          required
        >
          Oświadczam że jestem uprawniony do działania w imieniu firmy
        </Checkbox>
      )}
      <Checkbox
        id="terms"
        onChange={(e: any) => setTerms(e.target.checked)}
        required
      >
        Akceptuje{" "}
        <a
          href="https://successio.pl/regulamin-successio/"
          target="_blank"
          rel="noreferrer"
          className="text-gold"
        >
          regulamin{" "}
        </a>
        oraz potwierdzam zapoznanie się z{" "}
        <a
          href="https://successio.pl/polityka-prywatnosci-successio/"
          target="_blank"
          rel="noreferrer"
          className="text-gold"
        >
          Polityką prywatności{" "}
        </a>
      </Checkbox>
      <Checkbox
        id="marketing"
        onChange={(e: any) => setMarketing(e.target.checked)}
      >
        Zgoda na komunikację marketingową przez e-mail [pełna treść zgody
        <a
          href="https://successio.pl/zgoda-marketingowa-successio/"
          target="_blank"
          rel="noreferrer"
          className="text-gold"
        >
          {" "}
          TUTAJ
        </a>
        ]
      </Checkbox>
    </>
  );
}

export default RegisterCheckboxes;