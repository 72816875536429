import postRequest from "../../requests/postRequest";

export const accountType = async (type: string) => {
  const result = await postRequest({
    url: "/user/update/accType",
    params: {
      accountType: type,
    },
  });
  return result;
};
