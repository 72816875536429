import React from "react";
import PageWrap from "../layout/PageWrap";
import { ReactComponent as ImageSvg } from "../../assets/images/Transaction.svg";
import { ReactComponent as SpinSvg } from "../../assets/icons/LoadingSpin.svg";

function TransactionStatus() {
  return (
    <PageWrap>
      <div className="w-full max-w-xl sm:px-4 mx-auto">
        <div className="flex flex-col items-center ">
          <ImageSvg className="h-auto w-full max-w-sm" width={400} />
          <p className="text-darkBlue font-semibold text-lg sm:text-2xl md:text-3xl text-center mt-5">
            Przekierowywanie do płatności
          </p>
          <p className="text-sm max-w-xs text-darkGrey text-center">
            Zaraz nastąpi przekierowanie do płatności,
            <br />
            po zakończeniu zostaniesz automatycznie przekierowany z powrotem
          </p>
          <span>
            <div className="mt-10">
              <SpinSvg className="animate-spin spinGold h-5 w-5" />
            </div>
          </span>
        </div>
      </div>
    </PageWrap>
  );
}

export default TransactionStatus;
