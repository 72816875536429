import React from 'react';
import { inputOnlyLetters, inputZipCode } from '../../../validations/inputValidations';
import Input from '../../../components/common/forms/Input';

interface IProfessionalInputs {
  accountType: string;
  companyName: string;
  setCompanyName: (value: string) => void;
  nip: string;
  setNip: (value: string) => void;
  postalCode: string;
  setPostalCode: (value: string) => void;
  city: string;
  setCity: (value: string) => void;
  address: string;
  setAddress: (value: string) => void;
}

function ProfessionalInputs({
  accountType,
  companyName,
  setCompanyName,
  nip,
  setNip,
  postalCode,
  setPostalCode,
  city,
  setCity,
  address,
  setAddress
}: IProfessionalInputs) {
  if (accountType !== "PROFESSIONAL") return null;
  return (
    <>
      <Input
        label="Nazwa firmy"
        name="companyname"
        type="text"
        placeholder="Wpisz nazwę firmy"
        divClassName="my-8"
        value={companyName}
        onChange={(e: any) => setCompanyName(e.target.value)}
        required
      />
      <Input
        label="NIP"
        name="fullnames"
        type="text"
        placeholder="Wpisz NIP firmy"
        divClassName="my-8"
        value={nip}
        onChange={(e: any) => setNip(e.target.value)}
        required
      />
      <Input
        label="Kod pocztowy"
        name="postalcode"
        type="text"
        placeholder="Wpisz kod pocztowy"
        divClassName="my-8"
        value={postalCode}
        onChange={(e: any) => setPostalCode(e.target.value)}
        onKeyPress={inputZipCode}
        required
      />
      <Input
        label="Miasto"
        name="city"
        type="text"
        placeholder="Wpisz miasto"
        divClassName="my-8"
        value={city}
        onChange={(e: any) => setCity(e.target.value)}
        onKeyPress={inputOnlyLetters}
        required
      />
      <Input
        label="Ulica i numer domu"
        name="fullnames"
        type="text"
        placeholder="Wpisz ulicę i numer domu"
        divClassName="my-8"
        value={address}
        onChange={(e: any) => setAddress(e.target.value)}
        required
      />
    </>
  );
}

export default ProfessionalInputs;