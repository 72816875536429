import React from 'react';
import ShadowContentWrap from '../../../components/layout/ShadowContentWrap';
import Button from '../../../components/common/button/Button';

function ExportPdfWrap({ scenarioId }: { scenarioId: string }) {
    if (!scenarioId) return null;
    return (
      <ShadowContentWrap>
        <h4 className="text-lg text-darkBlue font-semibold">
          Chcesz zapisać wynik scenariusza?
        </h4>
        <p className="text-darkGrey mt-3 mb-8">
          Pobierz dokument w formacie PDF
        </p>
        <div className="flex justify-center items-center">
          <a
            href={`/scenario/pdf/${scenarioId}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button>Pobierz PDF</Button>
          </a>
        </div>
      </ShadowContentWrap>
    );
}

export default ExportPdfWrap;