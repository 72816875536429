import React, { useState } from "react";

import PageWrap from "../../components/layout/PageWrap";
import Form from "../../components/common/forms/Form";
import Input from "../../components/common/forms/Input";
import ProfessionalInputs from "./components/ProfessionalInputs";
import RegisterDisclaimer from "./components/RegisterDisclaimer";
import RegisterCheckboxes from "./components/RegisterCheckboxes";
import AccountType from "./components/AccountType";
import RegisterInputs from "./components/RegisterInputs";

import Verification from "../verification/Verification";

import { formatPhoneNumberIntl } from "react-phone-number-input";

import { Link } from "react-router-dom";
import { validateRegisterForm } from "../../validations/registerValidation";
import {
  emailUniqueRequest,
  getUserProfile,
  registerRequest,
} from "../../client/AppClient";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/reducers/userSlice";
import displayApiError from "../../utils/handling-errors/displayError";
import SocialAuth from "../../components/auth/SocialAuth";



export default function Register() {
  const [accountType, setAccountType] = useState("");
  const [redirectToValidation, setRedirectToValidation] = useState(false);

  const [email, setEmail] = useState("");
  const [passwd, setPasswd] = useState("");
  const [passwdRepeat, setPasswdRepeat] = useState("");
  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [nip, setNip] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [terms, setTerms] = useState(false);
  const [marketing, setMarketing] = useState(false);

  const [errors, setErrors] = useState<any>(null);

  const dispatch = useDispatch();

  const validateForm = () => {
    const errorsData = validateRegisterForm(
      email,
      passwd,
      passwdRepeat,
      phone,
      terms
    );
    if (Object.keys(errorsData).length === 0) return true;
    setErrors(errorsData);
    return false;
  };

  const getUserData = () => {
    if (accountType === "PRIVATE") return {
      email: email,
      password: passwd,
      fullname: fullName,
      phone: formatPhoneNumberIntl(phone),
      marketing: marketing,
      terms: terms,
      accountType: accountType,
    };
    else if (accountType === "PROFESSIONAL") return {
      email: email,
      password: passwd,
      fullname: fullName,
      phone: formatPhoneNumberIntl(phone),
      marketing: marketing,
      terms: terms,
      accountType: accountType,
      billingDetails: {
        isCompany: true,
        name: companyName,
        nip: nip,
        address: address,
        zipCode: postalCode,
        city: city,
      },
    };
  };

  const handleSubmit = async () => {
    const isFormValid = validateForm();
    if (!isFormValid) return;
    const emailUnique = await emailUniqueRequest(email);
    if (emailUnique.status !== 200)
      return displayApiError(dispatch, emailUnique);
    setRedirectToValidation(true);
  };

  const setUserDetails = async (userData: any) => {
    const registerResposnse = await registerRequest(userData);
    if (registerResposnse.status !== 200)
      return displayApiError(dispatch, registerResposnse);
    // dispatch(setUserAfterRegister(true));
    const userDetails = await getUserProfile();
    if (userDetails.status !== 200)
      return displayApiError(dispatch, userDetails);
    dispatch(setUser(userDetails.data));
  };

  const handleRegister = () => {
    const userData = getUserData();
    setUserDetails(userData);
  };

  if (accountType.length === 0) {
    return <AccountType setAccountType={setAccountType} />;
  }

  if (redirectToValidation) {
    return (
      <Verification
        number={formatPhoneNumberIntl(phone)}
        submitRegister={handleRegister}
      />
    );
  }

  return (
    <>
      <PageWrap>
        <div className="w-full max-w-sm px-4 mx-auto pt-20 text-center">
          <Form
            id="register"
            submitBtn="Zarejestruj się"
            onSubmit={handleSubmit}
          >
            <RegisterInputs
              email={email}
              setEmail={setEmail}
              fullName={fullName}
              setFullName={setFullName}
              phone={phone}
              setPhone={setPhone}
              errors={errors}
            />
            <ProfessionalInputs
              accountType={accountType}
              companyName={companyName}
              setCompanyName={setCompanyName}
              nip={nip}
              setNip={setNip}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              city={city}
              setCity={setCity}
              address={address}
              setAddress={setAddress}
            />
            <Input
              label="Hasło"
              name="passwd"
              type="password"
              placeholder="Wpisz swoje hasło"
              divClassName="my-8"
              value={passwd}
              onChange={(e: any) => setPasswd(e.target.value)}
              error={errors?.passwd}
              required
            />
            <Input
              label="Powtórz hasło"
              name="repeatPasswd"
              type="password"
              placeholder="Powtórz swoje hasło"
              divClassName="my-8"
              value={passwdRepeat}
              onChange={(e: any) => setPasswdRepeat(e.target.value)}
              error={errors?.passwdRepeat}
              required
            />
            <RegisterCheckboxes
              accountType={accountType}
              setTerms={setTerms}
              setMarketing={setMarketing}
            />
            <SocialAuth />
          </Form>
          <div className="text-center text-darkGrey">
            Posiadasz juz konto?{" "}
            <Link to="/login" className="text-darkBlue font-semibold">
              Zaloguj się
            </Link>
          </div>
        </div>
        <RegisterDisclaimer />
      </PageWrap>
    </>
  );
}
