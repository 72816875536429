import getRequest from "../../requests/getRequest";

export const enterpriseUserListService = async (
  currentPage?: number,
  itemsPerPage?: number,
  sort?: number,
  status?: string,
  searchValue?: string
) => {
  const result = await getRequest({
    url: "/enterprise/user/list",
    params: {
      p: currentPage,
      ps: itemsPerPage,
      s: sort,
      status: status,
      f: searchValue,
    },
  });
  return result;
};
