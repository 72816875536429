import React from "react";
import { useSelector } from "react-redux";

import Text from "../../../components/common/text/Text";
import NextStep from "../../../components/steps/NextStep";
import StepsInput from "../../../components/steps/StepsInput";
import { getInheritanceShare } from "../../../redux/reducers/partionSlice";
import getClosestFraction from "../../../utils/getClosestFraction";

function LegalPortionInheritanceShare({ onSubmit }: any) {
  const currentShareValue = useSelector(getInheritanceShare);
  const value = getClosestFraction(currentShareValue);
  
  const handleSubmit = () => {
    onSubmit();
  };
  return (
    <NextStep
      title={"Zachowek"}
      onSubmit={handleSubmit}
      disabled={currentShareValue === 0}
    >
      <h2 className="text-lg font-semibold text-darkBlue">Udział w spadku</h2>
      <StepsInput
        name="inheritanceShare"
        placeholder="Wpisz udział w spadku np. 1/2..."
        divClassName="my-8"
        value={value}
        required
        disabled
      />
      <Text>
        Udział w spadku należy się usprawnionemu do zachowku przy dziedziczeniu
        ustawowym.
      </Text>
    </NextStep>
  );
}

export default LegalPortionInheritanceShare;