import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { updatePortionDisabled } from "../../../redux/reducers/partionSlice";

import RadioSelect from "../../../components/common/forms/RadioSelect";
import NextStep from "../../../components/steps/NextStep";

function LegalPortionTarget({ onSubmit }: any) {
  const [value, setValue] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (value.length > 0) {
      // eslint-disable-next-line no-eval
      dispatch(updatePortionDisabled(eval(value)));
      onSubmit();
    }
  };

  return (
    <NextStep
      title={"Zachowek"}
      onSubmit={handleSubmit}
      disabled={value.length === 0}
    >
      <h2 className="text-lg font-semibold text-darkBlue">
        Czy Osoba usprawniona do zachowku jest osobą trwale niezdolną do pracy
        lub małoletnią?
      </h2>
      <div className="flex flex-col gap-3 mt-5">
        <RadioSelect
          id="legalportiontargetdisabledyes"
          group="legalportiondisabled"
          selected={value}
          value="2/3"
          onClick={(e: any) => setValue(e.target.value)}
        >
          Tak
        </RadioSelect>

        <RadioSelect
          id="legalportiontargetdisabledno"
          group="legalportiondisabled"
          selected={value}
          value="1/2"
          onClick={(e: any) => setValue(e.target.value)}
        >
          Nie
        </RadioSelect>
      </div>
    </NextStep>
  );
}

export default LegalPortionTarget;
