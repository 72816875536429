import postRequest from "../../requests/postRequest";

export const subscriptionRenewService = async (subscriptionType: string, numberOfUsers?: number) => {
  let params;
  if(!Boolean(numberOfUsers)) {
    params = {
      subscriptionType: subscriptionType,
      numberOfUsers: 0,
    };
  } else {
    params = {
       subscriptionType: subscriptionType,
       numberOfUsers: numberOfUsers,
     };
  }
   const result = await postRequest({
     url: "/subscription/renew",
     params: params
   });
   return result;
};
