import React, { useState } from 'react';
import Button from '../../../components/common/button/Button';
import Popup from '../../../components/specific/Popup';
import ExistingClient from './popup-save/ExistingClient';
import NewClient from './popup-save/NewClient';

interface PopupScenarioDetailsInterface {
  popupOpened: any;
  setPopupOpened: Function;
  handleSave: Function;
}

function PopupSaveScenario({
  popupOpened,
  setPopupOpened,
  handleSave,
}: PopupScenarioDetailsInterface) {
  const [target, setTarget] = useState("");
 
  
  const handleRemovePopup = () => {
    setTarget("");
    setPopupOpened(false);
  };

  const Selection = () => {
    return (
      <div>
        <div className="flex flex-col gap-4 justify-center items-center max-w-xs mx-auto">
          <Button size="full" onClick={() => setTarget("existing")}>
            Istniejąca Osoba
          </Button>
          <Button size="full" onClick={() => setTarget("new")}>
            Utwórz nową Osobę
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Popup
      title={"Zapisz scenariusz spadkowy"}
      trigger={popupOpened}
      setTrigger={handleRemovePopup}
      size="max-w-xl"
    >
      {target === "" && <Selection />}
      {target === "existing" && <ExistingClient handleSave={handleSave} />}
      {target === "new" && <NewClient handleSave={handleSave} />}
    </Popup>
  );
}

export default PopupSaveScenario;