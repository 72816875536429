import React, { useState } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { createEnterpriseUser } from "../../../client/AppClient";
import Form from "../../../components/common/forms/Form";
import InputBordered from "../../../components/common/forms/InputBordered";
import PhoneInput from "../../../components/common/forms/PhoneInput";
import Popup from "../../../components/specific/Popup";
import displayError from "../../../utils/handling-errors/displayError";
import displaySuccess from "../../../utils/handling-success/displaySuccess";
import { inputOnlyLetters } from "../../../validations/inputValidations";


interface CreateClientInterface {
  popupOpened: boolean;
  setPopupOpened: Function;
}

function CreateUser({ popupOpened, setPopupOpened }: CreateClientInterface) {
  const [fullname, setFullname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<any>(null);

  const dispatch = useDispatch();

  const clearForm = () => {
    setFullname("");
    setPhoneNumber("");
    setEmail("");
  };

  const handleSubmit = async () => {
    setErrors(null);
    const data = {
      fullname: fullname,
      phone: formatPhoneNumberIntl(phoneNumber),
      email: email,
    };
    const response = await createEnterpriseUser(data);
    if (response.status !== 200) {
      const errorResponse = displayError(dispatch, response);
      if (errorResponse !== undefined) setErrors(errorResponse);
      return;
    }
    clearForm();
    displaySuccess(dispatch, "Sukces", "Dodano Użytkownika");
    setPopupOpened(false);
  };

  return (
    <Popup
      title={"Nowy Użytkownik"}
      trigger={popupOpened}
      setTrigger={setPopupOpened}
    >
      <div className="flex flex-col">
        <Form
          id="newClient"
          submitBtn={"Utwórz profil Użytkownika"}
          onSubmit={handleSubmit}
          buttonSize="md"
        >
          <InputBordered
            label="Imię i Nazwisko"
            name="name"
            type="text"
            placeholder="Wpisz imię i nazwisko..."
            divClassName="my-8"
            value={fullname}
            onChange={(e: any) => setFullname(e.target.value)}
            onKeyPress={inputOnlyLetters}
            error={errors?.fullName}
            required
          />
          <PhoneInput
            label="Numer telefonu"
            name="phoneNumber"
            placeholder="Np. +48 555 444 333"
            divClassName="my-8 border-b-0"
            inputClassName={`mt-4 border rounded !pb-3 py-3 px-4 ${
              errors?.phoneNumber ? "border-error" : "border-lightGrey"
            }`}
            value={phoneNumber}
            setValue={setPhoneNumber}
            onChange={setPhoneNumber}
            error={errors?.phoneNumber}
            required
          />
          <InputBordered
            label="Adres e-mail"
            name="email"
            type="text"
            placeholder="Wpisz e-mail..."
            divClassName="my-8"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            error={errors?.email}
            required
          />
        </Form>
      </div>
    </Popup>
  );
}

export default CreateUser;
