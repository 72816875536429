import getRequest from "../../requests/getRequest";

export const clientBaseRequest = async (currentPage?: number, itemsPerPage?: number, sort?: number, filter?: string) => {
  const result = await getRequest({
    url: "/client/list",
    params: {
      p: currentPage,
      ps: itemsPerPage,
      s: sort,
      f: filter,
    },
  });
  return result;
};
