import React, { useState, useEffect, useRef } from "react";

// components
import PageWrap from "../../components/layout/PageWrap";
import EditSteps from "./steps/EditSteps";
import PopupWealth from "../new-scenario/components/PopupWealth";
import { useDispatch } from "react-redux";
import { clearEditScenario, updateEditScenario } from "../../redux/reducers/scenarioEditSlice";
import { useParams } from "react-router-dom";
import { getSavedScenario } from "../../client/AppClient";
import LoadingSpinner from "../../components/specific/LoadingSpinner";
import NoAccess from "../../components/layout/NoAccess";

function EditScenario() {
  const [formIndex, setFormIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [popupWealthInfo, setPopupWealthInfo] = useState(false);
  const effectCalled = useRef<any>(null);
  const dispatch = useDispatch();
  const { scenarioId } = useParams();

  const fetchScenarioData = async () => {
    if (!scenarioId) return;
    const response = await getSavedScenario(scenarioId);
    if (response.status !== 200) return [setLoading(false), setError(true)];
    dispatch(updateEditScenario(response.data));
    setLoading(false);
  };

  useEffect(() => {
    const handleClearRedux = () => {
      effectCalled.current = true;
      dispatch(clearEditScenario());
    };
    if (!effectCalled.current) {
        handleClearRedux();
        fetchScenarioData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNextCaseStep = (target: number = 1) => {
    setFormIndex((prev) => prev + target);
  };

  if (loading) return <LoadingSpinner />

  if (!scenarioId) return (
    <NoAccess
      title="Nieprawidłowy adres strony"
      description="Aby przejść do edycji scenariusza, należy zrobić to poprzez zapisany scenariusz."
    />
  );

  if (!loading && error)
    return (
      <NoAccess
        title="Nie udało się pobrać danych scenariusza"
        description="Wystąpił błąd przy pobieraniu danych scenariusza."
      />
    );

  return (
    <>
      <PopupWealth
        popupOpened={popupWealthInfo}
        setPopupOpened={setPopupWealthInfo}
      />
      <PageWrap>
        {formIndex === 6 ? (
          <EditSteps
            index={formIndex}
            handleNextCaseStep={handleNextCaseStep}
          />
        ) : (
          <div className="w-full max-w-xl px-4 mx-auto ">
            <EditSteps
              index={formIndex}
              handleNextCaseStep={handleNextCaseStep}
              setPopupWealthInfo={setPopupWealthInfo}
            />
          </div>
        )}
      </PageWrap>
    </>
  );
}

export default EditScenario;
