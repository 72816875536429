import React from 'react';

function RegisterDisclaimer() {
    return (
      <div className="w-full max-w-2xl px-4 mx-auto text-center">
        <div className="mt-5 text-left text-xs italic text-darkBlue text-opacity-30">
          <p>
            Administratorem danych osobowych podanych w formularzu
            rejestracyjnym i przetwarzanych w związku z korzystaniem z Aplikacji
            jest Successio sp. z o.o. z siedzibą w Białymstoku, ul. Adama
            Mickiewicza 48/205, 15-232 Białystok (SUCCESSIO). Dane osobowe będą
            przetwarzane w celu prowadzenia Konta na podstawie zawartej między
            SUCCESSIO a Użytkownikiem umowy, świadczenia Usługi wirtualnego
            asystenta spadkowego oraz w celu zabezpieczenia ewentualnych
            roszczeń, co stanowi prawnie uzasadniony interes SUCCESSIO. Jeśli
            została wyrażona zgoda marketingowa dane są przetwarzane dodatkowo w
            celu prowadzenia komunikacji marketingowej.
          </p>
          <p>
            Osoba, której dane są przetwarzane przez SUCCESSIO ma prawo do
            żądania dostępu do swoich danych osobowych, ich sprostowania,
            usunięcia lub ograniczenia przetwarzania, a także prawo wniesienia
            sprzeciwu wobec przetwarzania oraz wniesienia skargi do organu
            nadzorczego. Szczegółowe informacje dotyczące przetwarzania danych
            osobowych znajdują się{" "}
            <a
              href="https://successio.pl/polityka-prywatnosci-successio/"
              target="_blank"
              rel="noreferrer"
              className="text-gold"
            >
              TUTAJ
            </a>
          </p>
        </div>
      </div>
    );
}

export default RegisterDisclaimer;