import React, { useState, useEffect } from 'react';
import SearchBar from "../../../scenarios-base/components/SearchBar";
import Pagination from '../../../../components/specific/Pagination';
import ExistingClientsTable from './ExistingClientsTable';
import { getClientBase } from '../../../../client/AppClient';
import displayError from '../../../../utils/handling-errors/displayError';
import { useDispatch } from 'react-redux';

function ExistingClient({ handleSave }: any) {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [fetchedData, setFetchedData] = useState([]);

  const [totalClients, setTotalClients] = useState(0);

  const itemsPerPage = 10;
  const dispatch = useDispatch();

  const handleSearchChange = (searchValue: string) => {
    setCurrentPage(1);
    setSearchValue(searchValue);
  };

  useEffect(() => {
    const fetchClientBase = async () => {
      const response = await getClientBase(
        currentPage,
        itemsPerPage,
        1,
        searchValue
      );
      if (response.status !== 200) return displayError(dispatch, response);

      setFetchedData(response.data.items);
      setTotalClients(response.data.total);
    };
    fetchClientBase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, currentPage]);

  return (
    <div>
      <div className="flex items-center w-full mb-4">
        <SearchBar handleSearchChange={handleSearchChange} />
      </div>
      {searchValue.length > 0 && (
        <h3 className="text-xl font-semibold text-darkBlue mb-3">
          Wyniki wyszukiwania
        </h3>
      )}
      <ExistingClientsTable data={fetchedData} handleSave={handleSave} />
      <Pagination
        total={totalClients}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={(page: any) => setCurrentPage(page)}
      />
    </div>
  );
}

export default ExistingClient;