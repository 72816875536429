import React, { useState } from "react";
import Button from "../../../../components/common/button/Button";
import Header from "../../../../components/common/text/Header";
import PageWrap from "../../../../components/layout/PageWrap";
import ShadowContentWrap from "../../../../components/layout/ShadowContentWrap";
import TransactionStatus from "../../../../components/payments/TransactionStatus";
import {
  getActiveStatus,
  getActiveStatusText,
} from "../../helpers/planStatusHelpers";
import ExpandAccount from "../enterprise/ExpandAccount";
import ExtendAccount from "../enterprise/ExtendAccount";
import UsersCount from "../enterprise/UsersCount";
import ExpirationDate from "../ExpirationDate";
import SubscriptionCost from "../SubscriptionCost";
import TransactionTable from "../TransactionTable";

function PlanEnterprise({ planDetails }: any) {
  const [enterpriseTab, setEnterpriseTab] = useState(1);
  const [isTransactionStarted, setIsTransactionStarted] = useState(false);
  const RenderEnterpriseSections = () => {
    if (enterpriseTab === 1)
      return <TransactionTable />;
    if (enterpriseTab === 2) return (
      <ExtendAccount
        pricePerUser={planDetails.pricePerUser}
        numberOfUsers={planDetails.numberOfUsers}
        setIsTransactionStarted={setIsTransactionStarted}
      />
    );
    if (enterpriseTab === 3) return (
      <ExpandAccount
        pricePerUser={planDetails.pricePerUser}
        numberOfUsers={planDetails.numberOfUsers}
        setIsTransactionStarted={setIsTransactionStarted}
      />
    );
    return <></>;
  };

  if (isTransactionStarted) return <TransactionStatus />;
  
  return (
    <div>
      <PageWrap>
        <div className="w-full max-w-6xl mx-auto">
          <ShadowContentWrap>
            <div className="flex flex-row justify-between items-center mb-8">
              <Header>Pakiet Enterprise</Header>
              <span
                className={`border rounded-md  text-sm tracking-wider py-2 px-3 ${
                  getActiveStatus(planDetails)
                    ? "border-green text-green"
                    : "border-red text-red"
                }`}
              >
                {getActiveStatusText(planDetails)}
              </span>
            </div>

            <ExpirationDate planDetails={planDetails} />
            <SubscriptionCost planDetails={planDetails} />
            <UsersCount planDetails={planDetails} />

            <div className="flex justify-center items-center mb-2">
              <div className="flex flex-col md:flex-row justify-center gap-3 w-full md:w-3/4">
                <Button
                  size="full"
                  onClick={() => setEnterpriseTab(1)}
                  bgColor={`${
                    enterpriseTab === 1 ? "darkBlue" : "transparent"
                  }`}
                  textColor={`${enterpriseTab === 1 ? "white" : "darkBlue"}`}
                >
                  Lista transakcji
                </Button>
                <Button
                  size="full"
                  onClick={() => setEnterpriseTab(3)}
                  bgColor={`${
                    enterpriseTab === 3 ? "darkBlue" : "transparent"
                  }`}
                  textColor={`${enterpriseTab === 3 ? "white" : "darkBlue"}`}
                >
                  Przedłuż konto
                </Button>
                <Button
                  size="full"
                  onClick={() => setEnterpriseTab(2)}
                  bgColor={`${
                    enterpriseTab === 2 ? "darkBlue" : "transparent"
                  }`}
                  textColor={`${enterpriseTab === 2 ? "white" : "darkBlue"}`}
                >
                  Rozszerz pakiet
                </Button>
              </div>
            </div>
          </ShadowContentWrap>

          <RenderEnterpriseSections />
        </div>
      </PageWrap>
    </div>
  );
}

export default PlanEnterprise;
