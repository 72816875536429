import postRequestNoAuth from "../../requests/postRequestNoAuth";

export const verifyResetTokenService = async (token: string) => {
  const result = await postRequestNoAuth({
    url: "/user/verify/resetPasswordToken",
    params: {
      resetPasswordToken: token,
    },
  });
  return result;
};