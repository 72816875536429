import React, { useState } from 'react';
import Form from '../../../components/common/forms/Form';
import {ReactComponent as SpinSvg} from "../../../assets/icons/LoadingSpin.svg";
import { requestCall } from '../../../client/AppClient';
import { saveRtcToLocalStorage } from '../../video-call/helpers/agoraLocalStorage';

const formButton = (isSearching: boolean) => {
  if(isSearching) {
    return <> <SpinSvg className='animate-spin -ml-1 mr-3 h-5 w-5'/> Nawiązywanie połączenia...</>;
  }
  return "Połącz mnie z doradcą";
}

function ContactSearch({
  handleConnect,
  setFailedConnection,
  selectedConsultant
}: any) {
  const [isSearching, setIsSearching] = useState(false);

  const requestConnection = async () => {
    const response: any = await requestCall(selectedConsultant);
    if (response.status !== 200) return setFailedConnection(true);
    const dataToSave = {
      channelId: response.data.rtcToken.channelId,
      token: response.data.rtcToken.token,
      uid: Number(response.data.rtcToken.uid),
      startDate: new Date(),
      fullname: response.data.fullname,
      consultantType: response.data.consultantType,
    };
    saveRtcToLocalStorage(dataToSave);
    setTimeout(() => {
      handleConnect(selectedConsultant);
    }, 1000);
  };

  const handleSubmit = () => {
    setIsSearching(true);
    requestConnection();
  };

  return (
    <div>
      <h2 className="text-lg font-semibold text-darkBlue mb-10">
        Zapraszamy do kontaktu z jednym z naszych specjalistów!
      </h2>
      <p className="text-darkGrey my-4 mb-32">
        Jeśli masz uwagi związane z funkcjonowaniem aplikacji,<br/>
        jeden z naszych specjalistów na pewno Ci pomoże.
      </p>
      <Form
        id="contactSpecialist"
        submitBtn={formButton(isSearching)}
        onSubmit={handleSubmit}
        disabled={selectedConsultant.length === 0 || isSearching}
      >
      </Form>
    </div>
  );
}

export default ContactSearch;