import postRequest from "../../requests/postRequest";

const url =
  window.location.protocol +
  "//" +
  window.location.host +
  "/login/resetPassword";

export const createUserService = async (body: any) => {
  const result = await postRequest({
    url: "/enterprise/user/add",
    params: {
      email: body.email,
      fullname: body.fullname,
      phone: body.phone,
      resetPasswordUrl: url,
    },
  });
  return result;
};