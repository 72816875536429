import React from "react";
import classNames from "classnames";

interface InputInterface {
  label: string;
  name: string;
  placeholder?: any;
  value?: string | number;
  required?: boolean;
  onChange?: any;
  inputClassName?: string;
  divClassName?: string;
  maxLength?: any;
  onKeyPress?: any;
}

const TextAreaBordered = ({
  label,
  name,
  placeholder,
  value,
  inputClassName,
  divClassName,
  ...rest
}: InputInterface) => {
  return (
    <div className={classNames("relative flex flex-col", divClassName)}>
      <label htmlFor={name} className="text-sm text-darkGrey font-medium">
        {label}
      </label>
      <textarea
        className={classNames(
          "bg-transparent border border-lightGrey rounded text-sm font-medium placeholder-lightGrey text-sherpaBlue block w-full py-3 px-4 focus-visible:outline-none",
          inputClassName
        )}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

TextAreaBordered.defaultProps = {
  inputClassName: "mt-4",
};

export default TextAreaBordered;
