import React, { useState, useEffect } from "react";
import {
  AgoraVideoPlayer,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from "agora-rtc-react";
import getVideoDimensions from "../helpers/getVideoDimensions";
import waitForResolutionHeight from "../helpers/waitForResolution";

interface VideoInterface {
  remoteUser: IAgoraRTCRemoteUser;
  localTracks: {
    audioTrack: IMicrophoneAudioTrack;
    videoTrack: ICameraVideoTrack;
  };
  remoteUserAudio: boolean;
  userLeft: boolean;
}

function isRemoteUser(remoteUser: IAgoraRTCRemoteUser) {
  return Object.keys(remoteUser).length > 0;
}

const getMiniatureVideoDimensions = (videoTrack: any) => {
  const browserWidth = window.innerWidth;
  let baseHeight;
  if (browserWidth >= 640) {
    baseHeight = 200;
  } else {
    baseHeight = 90;
  }
  const videoHeight = videoTrack._videoHeight;
  const videoWidth = videoTrack._videoWidth;

  const ratio = baseHeight / videoHeight;

  const displayWidth = videoWidth * ratio;
  const displayHeight = videoHeight * ratio;

  return {
    height: displayHeight,
    width: displayWidth,
  };
};

const Videos = ({
  remoteUser,
  localTracks,
  remoteUserAudio,
  userLeft
}: VideoInterface) => {
  const [localDimensions, setLocalDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [remoteDimensions, setRemoteDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setLocalDimensions(getMiniatureVideoDimensions(localTracks.videoTrack));
    if (isRemoteUser(remoteUser) && remoteUser.videoTrack) {
      waitForResolutionHeight(remoteUser).then(() => {
        setRemoteDimensions(getVideoDimensions(remoteUser.videoTrack));
      });
    }
  }, [remoteUser, localTracks]);

  return (
    <div
      id="videos"
      className="w-full h-[calc(100vh_-_83px)] sm:h-screen flex justify-center bg-black"
    >
      <AgoraVideoPlayer
        id="localVideoPlayer"
        className={`vid localVideo absolute sm:top-10 top-3 sm:right-10 right-3 z-10 rounded-lg [&>*]:rounded-lg border border-lightBlue`}
        style={{
          width: `${localDimensions.width}px`,
          height: `${localDimensions.height}px`,
        }}
        videoTrack={localTracks.videoTrack}
      />
      {isRemoteUser(remoteUser) && remoteUser.videoTrack && (
        <AgoraVideoPlayer
          id={`remotePlayer-${remoteUser.uid}`}
          className="vid"
          style={{
            width: `${remoteDimensions.width}px`,
            height: `${remoteDimensions.height}px`,
            zIndex: 1,
          }}
          videoTrack={remoteUser.videoTrack}
          key={remoteUser.uid}
        />
      )}
      {!isRemoteUser(remoteUser) && remoteUserAudio && (
        <div>
          <p className="text-white font-semibold h-full flex justify-center items-center">
            Wideo wyłączone
          </p>
        </div>
      )}
      {!isRemoteUser(remoteUser) && !remoteUserAudio && (
        <div className="w-full h-full flex items-center justify-center">
          <p className="text-xl text-lightBlue text-center font-semibold">
            {userLeft
              ? "Użytkownik rozłączył się"
              : "Oczekiwanie na połączenie..."}
          </p>
        </div>
      )}
    </div>
  );
};

export default Videos;
