import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store';


const initialState = {
  name: null,
  wealth: {
    total: 0,
    parts: {
      estate: 0,
      companies: 0,
      vehicles: 0,
      savings: 0,
      investments: 0,
      other: 0,
    },
    debts: {
      loans: 0,
      credits: 0,
      overduePayments: 0,
      other: 0,
    },
  },
  numericAnswers: {},
  choiceAnswers: {},
  numericListAnswers: {},
};

export const scenarioSlice = createSlice({
  name: "scenario",
  initialState,
  reducers: {
    updateScenarioName: (state, action) => {
      state.name = action.payload;
    },
    updateScenarioWealth: (state, action) => {
      state.wealth.total = action.payload;
    },
    substractScenarioWealth: (state, action) => {
      state.wealth.total = state.wealth.total - action.payload;
    },
    updateScenarioWealthParts: (state, action) => {
      state.wealth.parts = action.payload;
    },
    updateScenarioDebts: (state, action) => {
      state.wealth.debts = action.payload;
    },
    updateScenarioNumericAnswers: (state, action) => {
      state.numericAnswers = { ...state.numericAnswers, ...action.payload };
    },
    updateScenarioListAnswers: (state, action) => {
      state.numericListAnswers = {
        ...state.numericListAnswers,
        ...action.payload,
      };
    },
    updateScenarioChoiceAnswers: (state, action) => {
      state.choiceAnswers = { ...state.choiceAnswers, ...action.payload };
    },
    clearScenario: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  clearScenario,
  updateScenarioName,
  updateScenarioWealth,
  substractScenarioWealth,
  updateScenarioWealthParts,
  updateScenarioDebts,
  updateScenarioNumericAnswers,
  updateScenarioListAnswers,
  updateScenarioChoiceAnswers,
} = scenarioSlice.actions;
export const getScenario = (state: RootState) => state.scenario;
export default scenarioSlice.reducer;