import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/Close.svg";

interface PopupInterface {
  title: string;
  trigger: boolean;
  setTrigger: Function;
  children: React.ReactNode;
  size?: "max-w-xl" | "max-w-3xl" | "max-w-5xl";
}

function Popup({ title, trigger, setTrigger, size, children }: PopupInterface) {
  const [isMobilePopupTooBig, setIsMobilePopupTooBig] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  const handleClose = (e: any) => {
    if (e.target.id === title) setTrigger(false);
  };

  useEffect(() => {
    if(trigger) {
      document.body.classList.add("hide-overflow");
    } else {
      document.body.classList.remove("hide-overflow");
    }
    if (popupRef.current) {
      if (popupRef.current.offsetHeight >= window.innerHeight) {
        setIsMobilePopupTooBig(true);
      } else {
        setIsMobilePopupTooBig(false);
      }
    }
    return () => document.body.classList.remove("hide-overflow");
  }, [trigger])

  return trigger ? (
    <div
      className={`fixed top-0 left-0 right-0 ${
        isMobilePopupTooBig ? "sm:bottom-0" : "bottom-0"
      } flex justify-center items-center  bg-black bg-opacity-40 z-50`}
      id={title}
      onPointerDown={handleClose}
    >
      <div
        className={`relative p-8 md:p-5  w-full bg-white ${size} max-h-screen overflow-y-auto md:overflow-y-hidden`}
        ref={popupRef}
      >
        <div className="flex flex-row justify-end">
          <CloseIcon
            className="cursor-pointer"
            onClick={() => setTrigger(false)}
          />
        </div>
        <h3 className="text-darkBlue text-lg font-semibold text-center">
          {title}
        </h3>
        <div className="p-4 sm:p-10">{children}</div>
      </div>
    </div>
  ) : (
    <></>
  );
}

Popup.defaultProps = {
  size: "max-w-xl"
}

export default Popup;
