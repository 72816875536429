const inputCurrencyToPennies = (price: string) => {
  // get value from input -> split to integer and decimal -> format to number -> sum up
  if (price === undefined) return 0;
  let numberString = "",
      integerString = "0",
      decimalString = "0";
  const splitted = price.split(",");
  if (splitted[0] !== undefined) {
    integerString = splitted[0].replace(/[zł. ]+/g, "");
  }
  if (splitted[1] !== undefined) {
    decimalString = splitted[1].replace(/[zł. ]+/g, "");
  }
  numberString = integerString + "." + decimalString;
  const result = Number(numberString) * 100;
  return result;
};

export default inputCurrencyToPennies;
