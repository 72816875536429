import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../../components/common/button/Button';
import Header from '../../../../components/common/text/Header';
import PageWrap from '../../../../components/layout/PageWrap';
import ShadowContentWrap from '../../../../components/layout/ShadowContentWrap';
import TransactionTable from '../TransactionTable';

function PlanNormal() {
    const navigate = useNavigate();
    const location = useLocation();
    const handleNavigate = () => {
        navigate("/plans", { state: { navigatedFrom: location.pathname } });
    }
    return (
      <div>
        <PageWrap>
          <div className="w-full max-w-6xl mx-auto">
            <ShadowContentWrap>
              <div className="flex flex-row justify-between items-center mb-8">
                <Header>Pakiet Podstawowy</Header>
                <span
                  className={`border rounded-md  text-sm tracking-wider py-2 px-3 border-green text-green`}
                >
                  AKTYWNY
                </span>
              </div>
              <div className="flex justify-center items-center mb-2">
                <div className="flex flex-col md:flex-row justify-center gap-3 w-full md:w-3/4">
                  <Button size="full" onClick={handleNavigate}>
                    Zmień pakiet
                  </Button>
                </div>
              </div>
            </ShadowContentWrap>

            <TransactionTable />
          </div>
        </PageWrap>
      </div>
    );
}

export default PlanNormal;