import React from "react";
import { useSelector } from "react-redux";

import Text from "../../../components/common/text/Text";
import NextStep from "../../../components/steps/NextStep";
import WealthInput from "../../../components/steps/WealthInput";
import { getScenarioValues } from "../../../redux/reducers/partionSlice";



function LegalPortionInheritanceValue({ onSubmit }: any) {
    const value = useSelector(getScenarioValues).wealth;
    const stringValue = value.toString().replace(".", ",");
    

    const handleSubmit = () => {
      onSubmit();      
    };

    return (
      <NextStep
        title={"Zachowek"}
        onSubmit={handleSubmit}
        disabled={value === undefined}
      >
        <h2 className="text-lg font-semibold text-darkBlue">
          Czysta wartość spadku
        </h2>
        <WealthInput
          name="inheritanceValue"
          placeholder="Wpisz wartość spadku..."
          divClassName="my-8"
          value={stringValue}
          allowNegative
          required
          disabled
        />
        <Text>
          Czysta wartość spadku jest to wartość aktywów spadku (takie jak
          nieruchomości, ruchomości, wierzytelności, środki pieniężne)
          pomniejszona o wartość długów spadkowych (np. długi spadkodawcy,
          koszty utrzymania spadkodawcy przed śmiercią, koszty pogrzebu
          spadkodawcy).
        </Text>
      </NextStep>
    );
}

export default LegalPortionInheritanceValue;