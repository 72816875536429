import postRequest from "../../requests/postRequest";

interface factureInterface {
  isCompany: boolean;
  name: string;
  nip?: string;
  address: string;
  zipCode: string;
  city: string;
}

export const updateFactureService = async (body: factureInterface) => {
  const result = await postRequest({
    url: "/user/facture/update",
    params: {
      isCompany: body.isCompany,
      name: body.name,
      nip: body.nip,
      address: body.address,
      zipCode: body.zipCode,
      city: body.city,
    },
  });
  return result;
};
